import styled from 'styled-components';
import CloudFilled from '@ant-design/icons/lib/icons/CloudFilled';
import { white, green, red } from '~/styles/colours';

const ConnectionIcon = styled(CloudFilled)`
  color: ${white};
  transition: color 4s;
  margin-right: 16px;

  @keyframes color_change_green {
    0% {
      color: ${white};
    }
    50% {
      color: ${green};
    }
    100% {
      color: ${white};
    }
  }
  @keyframes color_change_red {
    0% {
      color: ${white};
    }
    50% {
      color: ${red};
    }
    100% {
      color: ${white};
    }
  }
  &.connected {
    animation: color_change_green 4s infinite;
  }
  &.disconnected {
    animation: color_change_red 4s infinite;
  }
`;

export default ConnectionIcon;
