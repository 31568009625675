import { GpsSensorType } from '~/types';
import i18n from '~/locales/i18n';
import EnvironmentIcon from '~/components/atoms/icon/EnvironmentIcon';
import Space from '~/components/atoms/space/Space';
import CarrierDetailsPopupHeadSensorText from './CarrierDetailsPopupHeadSensorText';

interface CarrierDetailsPopupAddressAndLocationProps {
  gps: GpsSensorType | undefined;
  address: string | undefined;
}

function CarrierDetailsPopupAddressAndLocation({
  address,
  gps,
}: CarrierDetailsPopupAddressAndLocationProps) {
  return address && gps ? (
    <Space align="start">
      <EnvironmentIcon width={24} />
      <CarrierDetailsPopupHeadSensorText
        labelText={i18n.t('carrierDetailsPopup.location')}
        valueText={`(${gps.lat}, ${gps.lng}) ${address}`}
      />
    </Space>
  ) : (
    <Space>{i18n.t('carrierDetailsPopup.locationNotFound')}</Space>
  );
}

export default CarrierDetailsPopupAddressAndLocation;
