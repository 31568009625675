import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { GpsSensorType } from '~/types/sensor';
import { DETAILS_POPUP_TYPE } from '~/types/popup';
import routes from '~/navigation/config/routes';
import {
  DEFAULT_GPS_POSITION,
  DEFAULT_MAP_ZOOM_LEVEL,
  CARRIER_MAP_ZOOM_LEVEL,
} from '~/config/defaults';
import { newAlert } from '~/services/api/reactiveVariables/newAlert';
import useAgentsContext from '~/context/AgentsContext';
import useQueryParams from '~/hooks/useQueryParams';
import useSubsidiary from '~/hooks/useSubsidiary';
import Map from '~/components/Map';
import DashboardTopLeftOverlay from '~/components/DashboardTopLeftOverlay';

export default function DashboardPage() {
  const { id, zoom, type } = useQueryParams();
  const navigate = useNavigate();
  const { getAgent, getVehicle } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();
  const { carrierId } = useReactiveVar(newAlert);
  const maybeVehicleOrAgentPosition =
    type === DETAILS_POPUP_TYPE.VEHICLE
      ? getVehicle(id ?? '')?.location
      : getAgent(id ?? '')?.sensors?.gps;
  const centerPosition: GpsSensorType =
    maybeVehicleOrAgentPosition || currentSubsidiary?.location || DEFAULT_GPS_POSITION;

  useEffect(() => {
    if (carrierId) navigate(routes.default({ id: carrierId, zoom: `${CARRIER_MAP_ZOOM_LEVEL}` }));
  }, [navigate, carrierId]);

  return (
    <>
      <Map center={centerPosition} zoom={Number(zoom) || DEFAULT_MAP_ZOOM_LEVEL} />
      <DashboardTopLeftOverlay />
    </>
  );
}
