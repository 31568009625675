import { AgentType } from '~/types/agent';
import PhoneIcon from '~/components/atoms/icon/PhoneIcon';
import Space from '~/components/atoms/space/Space';
import CarrierDetailsPopupHeadSensorText from './CarrierDetailsPopupHeadSensorText';

export interface CarrierDetailsPopupPhoneNumberProps {
  agent: AgentType | undefined;
}

function CarrierDetailsPopupPhoneNumber({ agent }: CarrierDetailsPopupPhoneNumberProps) {
  return agent?.attributes?.phone_number ? (
    <Space>
      <PhoneIcon />
      <CarrierDetailsPopupHeadSensorText valueText={agent.attributes.phone_number} />
    </Space>
  ) : null;
}

export default CarrierDetailsPopupPhoneNumber;
