import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import DownOutlined from '@ant-design/icons/DownOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { DataNode } from 'antd/es/tree';
import { Button, Select, Tree, Typography } from 'antd';
import uniqueId from 'lodash/uniqueId';
import { Z_INDEX } from '~/styles/layers';
import { thinGrey, white } from '~/styles/colours';
import { formatTime } from '~/utils/dateTime';
import useAuthenticationContext from '~/context/AuthenticationContext';
import useCurrentUserContext from '~/context/CurrentUserContext';
import useAgentsContext from '~/context/AgentsContext';
import useAgentsStatistics from '~/hooks/useAgentsStatistics';
import useConnectionStatus from '~/hooks/useConnectionStatus';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useSubsidiary from '~/hooks/useSubsidiary';
import useTeams from '~/hooks/useTeams';
import { currentCompanyIdentifierVar } from '~/services/api/reactiveVariables/currentCompanyIdentifierVar';
import { currentSubsidiaryIdentifierVar } from '~/services/api/reactiveVariables/currentSubsidiaryIdentifierVar';
import { selectedTeamsVar } from '~/services/api/reactiveVariables/selectedTeamsVar';
import { reconnectTimestamp } from '~/services/api/reactiveVariables/reconnectTimestamp';
import { alertSoundMuted } from '~/services/api/reactiveVariables/alertSoundMuted';
import { newAlert } from '~/services/api/reactiveVariables/newAlert';

const GUTTER = '12px';

const WrapperDiv = styled('div')`
  position: fixed;
  bottom: ${GUTTER};
  left: ${GUTTER};
  height: 400px;
  width: 600px;
  max-width: calc(100vw - ${GUTTER} - ${GUTTER});
  background: ${white};
  z-index: ${Z_INDEX.DEVELOPER_CONSOLE};
  border: 1px solid ${thinGrey};
  border-radius: 5px;
  overflow-y: scroll;
`;

const TopDiv = styled('div')`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.DEVELOPER_CONSOLE + 1};
  background: ${white};
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 100px) minmax(0, 25px);
  grid-gap: 8px;
  padding: ${GUTTER} ${GUTTER} calc(${GUTTER} / 2);
`;

const BottomDiv = styled('div')`
  padding: 0 ${GUTTER} ${GUTTER};
`;

function createTreeNodesRecursively(values: unknown, prevKey: string = uniqueId()): DataNode[] {
  return Object.entries(values || {}).map(([key, value]) => {
    switch (typeof value) {
      case 'function': {
        return {
          title: `${key}: function`,
          key: `${prevKey}-${key}-function`,
        };
      }
      case 'object': {
        const curatedTitle = Number.isFinite(parseInt(key, 10)) ? `item ${key}` : `${key}`;
        const curatedKey = `${prevKey}-${curatedTitle}-${value}`;
        return {
          title: Array.isArray(value) ? `${curatedTitle}: [${value.length}]` : curatedTitle,
          key: curatedKey,
          children: createTreeNodesRecursively(value, curatedKey),
        };
      }
      default: {
        return {
          title: `${key}: ${value}`,
          key: `${prevKey}-${key}-${value}`,
        };
      }
    }
  });
}

function DeveloperConsoleApp({ onClose }: { onClose: () => void }) {
  const reactiveVars = {
    currentCompanyIdentifierVar: useReactiveVar(currentCompanyIdentifierVar),
    currentSubsidiaryIdentifierVar: useReactiveVar(currentSubsidiaryIdentifierVar),
    selectedTeamsVar: useReactiveVar(selectedTeamsVar),
    reconnectTimestamp: useReactiveVar(reconnectTimestamp),
    newAlert: useReactiveVar(newAlert),
    alertSoundMuted: useReactiveVar(alertSoundMuted),
  };

  const values = {
    apolloStoreCache: window.getApolloStoreCache?.(),
    reactiveVars,
    useAuthenticationContext: useAuthenticationContext(),
    useCurrentUserContext: useCurrentUserContext(),
    useAgentsContext: useAgentsContext(),
    useAgentsStatistics: useAgentsStatistics(),
    useConnectionStatus: useConnectionStatus(),
    useCompany: useCompany(),
    useCompanyFeatures: useCompanyFeatures(),
    useSubsidiary: useSubsidiary(),
    useTeams: useTeams(),
  };

  const [selectedValue, setSelectedValue] = useState<keyof typeof values | null>(null);
  const [treeData, setTreeData] = useState<{
    timestamp: string;
    nodes: DataNode[];
  }>({
    timestamp: new Date().toISOString(),
    nodes: [],
  });

  const handleChangeValue = (key: keyof typeof values) => {
    setTreeData({
      timestamp: new Date().toISOString(),
      nodes: createTreeNodesRecursively(values[key]),
    });
  };

  return (
    <WrapperDiv>
      <TopDiv>
        <Select<keyof typeof values>
          showSearch
          placeholder="Select a node"
          options={Object.keys(values).map((key) => ({ value: key, label: key }))}
          value={selectedValue}
          onChange={(key) => {
            setSelectedValue(key);
            handleChangeValue(key);
          }}
        />
        <Button
          icon={<ReloadOutlined />}
          disabled={!selectedValue}
          onClick={() => {
            if (selectedValue) {
              handleChangeValue(selectedValue);
            }
          }}
        >
          Refesh
        </Button>
        <Button type="dashed" icon={<CloseOutlined />} onClick={onClose} />
      </TopDiv>
      {treeData.nodes.length > 0 && (
        <BottomDiv>
          <Tree showLine switcherIcon={<DownOutlined />} treeData={treeData.nodes} />
          <Typography.Text>
            <b>Timestamp:</b> {formatTime(treeData.timestamp)}
          </Typography.Text>
        </BottomDiv>
      )}
    </WrapperDiv>
  );
}

export default function DeveloperConsole() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isAuthenticated } = useAuthenticationContext();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && ['o', 'O'].includes(event.key)) {
        setIsOpen(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!isOpen || !isAuthenticated) {
    return null;
  }

  return (
    <DeveloperConsoleApp
      onClose={() => {
        setIsOpen(false);
      }}
    />
  );
}
