import { useMutation } from '@apollo/client';
import MUTATION_ASSOCIATE_ALARM_ADMIN, {
  AssociateAlarmAdminMutationData,
  AssociateAlarmAdminMutationVariables,
} from '~/services/api/operations/mutations/MutationAssociateAlarmAdmin';

export function useMutationAssociateAlarmAdmin() {
  const [associateAlarmAdmin, { loading, error }] = useMutation<
    AssociateAlarmAdminMutationData,
    AssociateAlarmAdminMutationVariables
  >(MUTATION_ASSOCIATE_ALARM_ADMIN);

  return { associateAlarmAdmin, loading, error };
}
