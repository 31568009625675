import { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import QUERY_COMPANY_LIST, {
  CompanyListQueryData,
} from '~/services/api/operations/queries/QueryCompanyList';

export default function useQueryCompanyList(skip = false): {
  companyList: CompanyListQueryData['getAllCompanies']['items'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<CompanyListQueryData>(QUERY_COMPANY_LIST, { skip });

  return useMemo(
    () => ({
      companyList: data?.getAllCompanies?.items || [],
      isLoading: loading,
      error,
    }),
    [data?.getAllCompanies?.items, loading, error],
  );
}
