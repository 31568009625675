import i18n from '~/locales/i18n';
import { EQUIPMENT_STATUSES, GpsSensorType } from '~/types/sensor';

export const DEFAULT_CARRIER_NAME = i18n.t('general.defaults.carrierNameDefault');

// center of Switzerland
export const DEFAULT_GPS_POSITION: GpsSensorType = Object.freeze({
  lat: 46.897518,
  lng: 8.173022,
  br: 0,
  sp: 0,
});

// zoomed out to show all of Switzerland
export const DEFAULT_MAP_ZOOM_LEVEL = 8;

export const CARRIER_MAP_ZOOM_LEVEL = 14;

export const DEFAULT_WORKING_AGENTS_COUNTER = 0;

/** Equipment */
export const DEFAULT_EQUIPMENT = Object.freeze({
  connectionLost: { status: EQUIPMENT_STATUSES.NO_SENSOR, healthy: false },
  offline: { status: EQUIPMENT_STATUSES.NO_SENSOR, healthy: false },
  emergencyButton: { status: EQUIPMENT_STATUSES.NO_SENSOR, healthy: false },
  heartRate: { status: EQUIPMENT_STATUSES.NO_SENSOR, healthy: false },
  bodyTemperature: { status: EQUIPMENT_STATUSES.NO_SENSOR, healthy: false },
  gas: { status: EQUIPMENT_STATUSES.NO_SENSOR, healthy: false },
  traakFront: { status: EQUIPMENT_STATUSES.NO_SENSOR, healthy: false },
  traakBack: { status: EQUIPMENT_STATUSES.NO_SENSOR, healthy: false },
});

export const DEFAULT_NO_DEVICE_ASSIGNED = i18n.t('general.defaults.noDeviceAssigned');
