import DetailsPopupSpace from '~/components/atoms/space/DetailsPopupSpace';
import SosVehicleDetailsPopupFooter from './SosVehicleDetailsPopupFooter';
import SosVehicleDetailsPopupGrid from './SosVehicleDetailsPopupGrid';
import SosVehicleDetailsPopupHeader from './SosVehicleDetailsPopupHeader';

export default function SosVehicleDetailsPopup() {
  return (
    <DetailsPopupSpace data-id="sos-vehicle-details-popup">
      <SosVehicleDetailsPopupHeader />
      <SosVehicleDetailsPopupGrid />
      <SosVehicleDetailsPopupFooter />
    </DetailsPopupSpace>
  );
}
