import routes from '~/navigation/config/routes';
import i18n from '~/locales/i18n';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import SpaceBetweenSpace from '~/components/atoms/space/SpaceBetweenSpace';
import CarrierDetailsPopupFooterButton from './CarrierDetailsPopupFooterButton';

function CarrierDetailsPopupFooter() {
  const { agent } = useCarrierDetailsPopupContext();

  return (
    <SpaceBetweenSpace>
      <CarrierDetailsPopupFooterButton
        textLabel={i18n.t('carrierDetailsPopup.viewOnDashboard')}
        to={routes.default({ id: agent?.id, zoom: '14' })}
        data-id="view-on-dashboard-btn"
      />
    </SpaceBetweenSpace>
  );
}

export default CarrierDetailsPopupFooter;
