import { ReactNode } from 'react';
import styled from 'styled-components';
import { BarsOutlined, MailOutlined, MessageOutlined, SearchOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd';
import { To } from 'react-router-dom';
import { blue } from '~/styles/colours';
import { SecondarySmallButton } from '~/components/atoms/button/SecondaryButton';
import BlackLink from '~/components/atoms/link/BlackLink';
import Text from '~/components/atoms/typography/Text';
import i18n from '~/locales/i18n';

interface CarrierDetailsPopupFooterButtonProps extends ButtonProps {
  textLabel: string;
  to?: To;
}

const SecondarySmallButtonText = styled(Text)`
  font-size: 14px;
  color: ${blue};
`;

function CarrierDetailsPopupFooterButton({
  textLabel,
  to,
  ...props
}: CarrierDetailsPopupFooterButtonProps) {
  let buttonIcon: ReactNode;
  switch (textLabel) {
    case i18n.t('carrierDetailsPopup.viewOnDashboard'):
      buttonIcon = <SearchOutlined />;
      break;
    case i18n.t('carrierDetailsPopup.viewHistory'):
      buttonIcon = <BarsOutlined />;
      break;
    case i18n.t('carrierDetailsPopup.sendMessage'):
      buttonIcon = <MessageOutlined />;
      break;
    case i18n.t('carrierDetailsPopup.recapByMail'):
      buttonIcon = <MailOutlined />;
      break;
    default:
      break;
  }

  return (
    <SecondarySmallButton {...props}>
      {buttonIcon}
      {to ? (
        <BlackLink to={to}>
          <SecondarySmallButtonText>{textLabel}</SecondarySmallButtonText>
        </BlackLink>
      ) : (
        <SecondarySmallButtonText>{textLabel}</SecondarySmallButtonText>
      )}
    </SecondarySmallButton>
  );
}

export default CarrierDetailsPopupFooterButton;
