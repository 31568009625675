import { useState } from 'react';
import styled from 'styled-components';
import { Tooltip, Button, Radio } from 'antd';
import { USER_TYPES, AlarmType } from '~/types';
import i18n from '~/locales/i18n';
import { computeAlarmStartingTime } from '~/utils/alarm';
import { computeAlarmMessage } from '~/utils/alarm';
import notification from '~/utils/notification';
import useMutationRequestDismissAlarm from '~/services/api/apis/useMutationRequestDismissAlarm';
import { useMutationAssociateAlarmAdmin } from '~/services/api/apis/useMutationAssociateAlarmAdmin';
import { useMutationDisassociateAlarmAdmin } from '~/services/api/apis/useMutationDisassociateAlarmAdmin';
import useCurrentUserContext from '~/context/CurrentUserContext';
import CloseButton from '~/components/atoms/button/CloseButton';
import Text from '~/components/atoms/typography/Text';
import Modal from '~/components/atoms/Modal';
import LoadingSpin from '~/components/atoms/LoadingSpin';
import Space from '~/components/atoms/space/Space';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';

const ManageAlertModalSpace = styled(Space)`
  flex-direction: column;
  align-items: flex-start;
  gap: 24px !important;
`;

interface Props {
  visible: boolean;
  toggleVisibility: () => void;
  alarm: AlarmType;
}

enum RadioOptions {
  acknowledged = 'acknowledged',
  cancelled = 'cancelled',
  associate = 'associate',
  disassociate = 'disassociate',
}

export default function ManageAlertModal({ visible, toggleVisibility, alarm }: Props) {
  const currentUser = useCurrentUserContext();
  const { agent } = useCarrierDetailsPopupContext();
  const { requestDismissAlarm, isLoading: isDismissLoading } = useMutationRequestDismissAlarm();
  const { associateAlarmAdmin, loading: isAssociateLoading } = useMutationAssociateAlarmAdmin();
  const { disassociateAlarmAdmin, loading: isDisassociateLoading } =
    useMutationDisassociateAlarmAdmin();
  const [selectedAction, setSelectedAction] = useState<RadioOptions>();
  const isSuperAdmin = currentUser?.type === USER_TYPES.SUPER_ADMIN;

  const handleSubmit = async () => {
    try {
      if (selectedAction === RadioOptions.acknowledged) {
        await requestDismissAlarm({ variables: { alarmID: alarm.id, reason: 'Acknowledged' } });
      } else if (selectedAction === RadioOptions.cancelled) {
        await requestDismissAlarm({ variables: { alarmID: alarm.id, reason: 'Cancelled' } });
      } else if (selectedAction === RadioOptions.associate && currentUser?.id) {
        await associateAlarmAdmin({ variables: { alarmId: alarm.id, adminId: currentUser.id } });
      } else if (selectedAction === RadioOptions.disassociate && currentUser?.id) {
        await disassociateAlarmAdmin({
          variables: { alarmId: alarm.id, adminId: currentUser.id },
        });
      }

      notification.success({
        message: i18n.t(`carrierDetailsPopup.manageAlertModal.${selectedAction}SuccessTitle`),
      });
      toggleVisibility();
    } catch (error) {
      notification.error({ message: i18n.t('carrierDetailsPopup.manageAlertModal.failTitle') });
    }
  };

  const { alertTime, relativeAlertTime } = computeAlarmStartingTime(agent?.alarms);
  const alertTypeMessage = computeAlarmMessage(alarm);
  const acknowledgedOption = (
    <Radio value={RadioOptions.acknowledged} disabled={alarm.undismissable}>
      {i18n.t('carrierDetailsPopup.manageAlertModal.options.acknowledged')}
    </Radio>
  );
  const cancelledOption = (
    <Radio value={RadioOptions.cancelled} disabled={alarm.undismissable}>
      {i18n.t('carrierDetailsPopup.manageAlertModal.options.cancelled')}
    </Radio>
  );

  return (
    <Modal
      title={i18n.t('carrierDetailsPopup.manageAlertModal.title')}
      style={{ position: 'relative' }}
      width={640}
      centered
      closable={false}
      open={visible}
      destroyOnClose
      footer={[
        <Button
          key="submit"
          onClick={handleSubmit}
          disabled={
            alarm.undismissable ||
            (alarm.administrator && alarm.administrator.id !== currentUser?.id)
          }
        >
          {isDismissLoading || isAssociateLoading || isDisassociateLoading ? (
            <LoadingSpin />
          ) : (
            i18n.t('carrierDetailsPopup.manageAlertModal.submitButtonLabel')
          )}
        </Button>,
      ]}
      onCancel={toggleVisibility}
    >
      <CloseButton onClick={toggleVisibility}>
        {' '}
        {i18n.t(`carrierDetailsPopup.manageAlertModal.close`)}
      </CloseButton>
      <ManageAlertModalSpace>
        {alertTime && <Text>{`${alertTime} - ${relativeAlertTime}`}</Text>}
        <Text>
          {agent?.attributes?.first_name} {agent?.attributes?.last_name} - Type: {alertTypeMessage}
        </Text>
        <Radio.Group
          value={selectedAction}
          onChange={({ target }) => setSelectedAction(target.value)}
        >
          <Space direction="vertical">
            {/* TODO: once BE implements gas alarm as a real alarm they could be assignable */}
            {!isSuperAdmin && !alarm.undismissable && alarm.administrator && (
              <Radio
                value={RadioOptions.disassociate}
                disabled={alarm.administrator.id !== currentUser?.id}
              >
                {i18n.t('carrierDetailsPopup.manageAlertModal.options.disassociate')}
              </Radio>
            )}
            {!isSuperAdmin && !alarm.undismissable && !alarm.administrator && (
              <Radio value={RadioOptions.associate}>
                {i18n.t('carrierDetailsPopup.manageAlertModal.options.associate')}
              </Radio>
            )}
            {alarm.undismissable ? (
              <Tooltip title={i18n.t(`carrierDetailsPopup.manageAlertModal.undismissable`)}>
                {acknowledgedOption}
              </Tooltip>
            ) : (
              acknowledgedOption
            )}
            {alarm.undismissable ? (
              <Tooltip title={i18n.t(`carrierDetailsPopup.manageAlertModal.undismissable`)}>
                {cancelledOption}
              </Tooltip>
            ) : (
              cancelledOption
            )}
          </Space>
        </Radio.Group>
      </ManageAlertModalSpace>
    </Modal>
  );
}
