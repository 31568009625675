import { Input } from 'antd';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import type { PasswordProps } from 'antd/lib/input';
import { black } from '~/styles/colours';

export default function InputPassword(props: PasswordProps) {
  return (
    <Input.Password type="password" prefix={<LockOutlined style={{ color: black }} />} {...props} />
  );
}
