import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';
import {
  AgentType,
  MARKER_TYPE,
  VehicleType,
  AGENT_STATUS,
  DETAILS_POPUP_TYPE,
  GpsCoordinatesType,
} from '~/types';
import routes from '~/navigation/config/routes';
import { computeVehicleShorthandLabel, computeCarrierShorthandLabel } from '~/utils/agent';
import useAgentsContext from '~/context/AgentsContext';
import useQueryParams from '~/hooks/useQueryParams';
import useTeams from '~/hooks/useTeams';
import useAgentStatuses from '~/hooks/useAgentStatuses';
import { getMarkerZIndex } from './utils';

interface MarkerType extends google.maps.MarkerOptions {
  id: string;
  type: MARKER_TYPE;
  label: string;
  tooltip: string;
  status: AGENT_STATUS;
  position: GpsCoordinatesType;
  isHighlighted?: boolean;
  onClick?: (markerId: string) => void;
}

const getVehicleTeams = (vehicle: VehicleType) =>
  uniq(vehicle.agents?.map(({ team }) => team).filter((val) => val));

export default function useMarkers() {
  const { id: queryId } = useQueryParams();
  const navigate = useNavigate();
  const { agents, vehicles } = useAgentsContext();
  const { selectedTeams } = useTeams();
  const { selectedAgentStatuses } = useAgentStatuses();

  const handleAgentMarkerClick = useCallback(
    (id: string) => navigate(routes.status({ id })),
    [navigate],
  );

  const handleVehicleMarkerClick = useCallback(
    (id: string) => navigate(routes.status({ id, type: DETAILS_POPUP_TYPE.VEHICLE })),
    [navigate],
  );

  const agentMarkers: MarkerType[] = useMemo(
    () =>
      agents
        .filter(
          (agent) =>
            agent &&
            !agent.isOffline &&
            agent.sensors.gps &&
            !agent.attributes.plate_number && // out of vehicle
            (!selectedTeams.length || selectedTeams.includes(agent.team)),
        )
        .map(({ id, sensors, completeName, status, team }: AgentType, index) => ({
          id,
          position: sensors.gps,
          type: MARKER_TYPE.CARRIER,
          label: computeCarrierShorthandLabel(completeName),
          tooltip: team
            ? `<div style="text-align:center">
                <div>${completeName}</div>
                <div><strong>${team}</strong></div>
              </div>`
            : completeName,
          status,
          zIndex: getMarkerZIndex(status, 'agent') + index,
          onClick: handleAgentMarkerClick,
          isHighlighted: id === queryId,
        })),
    [handleAgentMarkerClick, queryId, agents, selectedTeams],
  );

  const vehicleMarkers = useMemo(
    () =>
      vehicles
        .filter(
          (vehicle) =>
            vehicle.location &&
            (!selectedTeams.length || intersection(selectedTeams, getVehicleTeams(vehicle)).length),
        )
        .map((vehicle: VehicleType, index) => {
          const { id, location, plateNumber, status, completeName } = vehicle;
          const teams = getVehicleTeams(vehicle);

          return {
            id,
            position: location,
            type: MARKER_TYPE.VEHICLE,
            label: computeVehicleShorthandLabel(plateNumber),
            tooltip: teams.length
              ? `<div style="text-align:center">
                  <div>${completeName}</div>
                  <div><strong>${teams.join(' ')}</strong></div>
                </div>`
              : completeName,
            status,
            zIndex: getMarkerZIndex(status, 'vehicle') + index,
            onClick: handleVehicleMarkerClick,
            isHighlighted: id === queryId,
          };
        }),
    [handleVehicleMarkerClick, queryId, vehicles, selectedTeams],
  );

  const markers = useMemo(() => {
    const allMarkers = [...agentMarkers, ...vehicleMarkers];

    return selectedAgentStatuses.length === 0
      ? allMarkers
      : allMarkers.filter((marker) => selectedAgentStatuses.includes(marker.status));
  }, [agentMarkers, vehicleMarkers, selectedAgentStatuses]);

  return markers;
}
