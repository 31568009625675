import { gql } from '@apollo/client';
import {
  AlarmType,
  ALARM_ITEMS_QUERY_BODY,
  ALARM_TYPES,
  EQUIPMENT_STATUSES,
  SENSOR_ITEMS_QUERY_BODY,
  SENSOR_STATUS_QUERY_TYPES,
  SENSOR_TYPES,
  SENSOR_TYPE_KEYS,
  SENSOR_TYPE_STATUSES,
} from '~/types';
import { getBeginningOfTheDayISO } from '~/utils/dateTime';

export interface SubsidiaryCarrierListQueryVariables {
  subsidiaryID: string;
}

interface MeasurementConnection<V> {
  items: {
    timestamp: string;
    value: V;
    __typename?: 'Measurement';
  }[];
  __typename?: 'MeasurementConnection';
}

interface AlarmConnection {
  items: AlarmType[];
  __typename?: 'AlarmConnection';
}

export interface CarrierItem {
  name: string;
  id: string;
  attributes: {
    name: string;
    value: string;
    __typename?: 'Attribute';
  }[];
  device: {
    name: string;
    attributes: {
      name: string;
      value: string;
      __typename?: 'Attribute';
    }[];
    __typename?: 'Device';
  } & {
    [key in ALARM_TYPES]?: AlarmConnection;
  } & {
    [key in SENSOR_TYPES]?: MeasurementConnection<string>;
  } & {
    [key in SENSOR_TYPE_STATUSES]?: MeasurementConnection<EQUIPMENT_STATUSES>;
  };
}

export interface SubsidiaryCarrierListQueryData {
  subsidiary: {
    id: string;
    carriers: {
      items: CarrierItem[];
      nextToken?: string | null;
    };
  };
}

export default gql`
  query QuerySubsidiaryCarrierList($subsidiaryID: ID!, $nextToken: String) {
    subsidiary(id: $subsidiaryID) {
      id
      carriers(limit: 1000, nextToken: $nextToken) {
        nextToken
        items {
          id
          name
          attributes {
            name
            value
          }
          device {
            name
            attributes {
              name
              value
            }
            ${SENSOR_TYPE_KEYS.CONNECTED.NAME}: measurements(type: "${SENSOR_TYPE_KEYS.CONNECTED.QUERY_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.GPS.NAME}: measurements(type: "${SENSOR_TYPE_KEYS.GPS.QUERY_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.HEART_RATE.NAME}: measurements(type: "${SENSOR_TYPE_KEYS.HEART_RATE.QUERY_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.HEART_RATE.STATUS_NAME}: measurements(type: "${SENSOR_TYPE_KEYS.HEART_RATE.QUERY_STATUS_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.BODY_TEMPERATURE.NAME}: measurements(type: "${SENSOR_TYPE_KEYS.BODY_TEMPERATURE.QUERY_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.BODY_TEMPERATURE.STATUS_NAME}: measurements(type: "${SENSOR_TYPE_KEYS.BODY_TEMPERATURE.QUERY_STATUS_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.TEMPERATURE.STATUS_NAME}: measurements(type: "${SENSOR_TYPE_KEYS.TEMPERATURE.QUERY_STATUS_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.GAS.NAME}: measurements(type: "${SENSOR_TYPE_KEYS.GAS.QUERY_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.GAS.STATUS_NAME}: measurements(type: "${SENSOR_TYPE_KEYS.GAS.QUERY_STATUS_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.BRAIN_STOP.NAME}: measurements(type: "${SENSOR_TYPE_KEYS.BRAIN_STOP.QUERY_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.CONNECTION_HISTORY.NAME}: measurements(type: "${SENSOR_TYPE_KEYS.CONNECTION_HISTORY.QUERY_NAME}", limit: 1000, createdAfter: "${getBeginningOfTheDayISO()}") ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.TRAAK_FRONT.STATUS_NAME}: measurements(type: "${SENSOR_TYPE_KEYS.TRAAK_FRONT.QUERY_STATUS_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_KEYS.TRAAK_BACK.STATUS_NAME}: measurements(type: "${SENSOR_TYPE_KEYS.TRAAK_BACK.QUERY_STATUS_NAME}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${SENSOR_TYPE_STATUSES.EMERGENCY}: measurements(type: "${SENSOR_STATUS_QUERY_TYPES.EMERGENCY}", limit: 1) ${
              SENSOR_ITEMS_QUERY_BODY
            }
            ${ALARM_TYPES.FALL}: alarms(type: "${ALARM_TYPES.FALL}", limit: 1) ${
              ALARM_ITEMS_QUERY_BODY
            }
            ${ALARM_TYPES.EMERGENCY}: alarms(type: "${ALARM_TYPES.EMERGENCY}", limit: 1) ${
              ALARM_ITEMS_QUERY_BODY
            }
            ${ALARM_TYPES.ATTACK}: alarms(type: "${ALARM_TYPES.ATTACK}", limit: 1) ${
              ALARM_ITEMS_QUERY_BODY
            }
            ${ALARM_TYPES.TRAAK_FRONT}: alarms(type: "${ALARM_TYPES.TRAAK_FRONT}", limit: 1) ${
              ALARM_ITEMS_QUERY_BODY
            }
            ${ALARM_TYPES.TRAAK_BACK}: alarms(type: "${ALARM_TYPES.TRAAK_BACK}", limit: 1) ${
              ALARM_ITEMS_QUERY_BODY
            }
          }
        }
      }
    }
  }
`;
