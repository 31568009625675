import DetailsPopupSpace from '~/components/atoms/space/DetailsPopupSpace';
import CarrierDetailsPopupHeadContent from '~/components/DetailsPopup/content/headContent/CarrierDetailsPopupHeadContent';
import CarrierDetailsPopupMidContentSpace from '~/components/atoms/space/CarrierDetailsPopupMidContentSpace';
import { INFORMATION_TYPES } from '~/components/DetailsPopup/content/midContent/components/CarrierDetailsPopupInfoType';
import CarrierDetailsPopupInfoTypeList from '~/components/DetailsPopup/content/midContent/components/CarrierDetailsPopupInfoTypeList';
import CarrierDetailsContentColumnGrid from '~/components/DetailsPopup/content/midContent/components/CarrierDetailsContentColumnGrid';

export default function SosCarrierDetailsInSVDPopup() {
  return (
    <DetailsPopupSpace data-id="sos-carrier-details-in-svd-popup">
      <CarrierDetailsContentColumnGrid>
        <CarrierDetailsPopupHeadContent count={1} />
        <CarrierDetailsPopupMidContentSpace>
          <CarrierDetailsPopupInfoTypeList
            informationTypeList={[
              INFORMATION_TYPES.SOS_ALERT,
              INFORMATION_TYPES.TRAAK,
              INFORMATION_TYPES.VIDEO_STREAMING_NO_TITLE,
              INFORMATION_TYPES.EQUIPMENT,
              INFORMATION_TYPES.PHYSIOLOGICAL_SENSORS,
              INFORMATION_TYPES.GAS_SENSOR,
            ]}
          />
        </CarrierDetailsPopupMidContentSpace>
      </CarrierDetailsContentColumnGrid>
    </DetailsPopupSpace>
  );
}
