import styled from 'styled-components';
import type { ButtonProps } from 'antd';
import { midGrey, blue, green, yellow } from '~/styles/colours';
import Button from './Button';

function CarrierDetailsPopupAlarmStatus(props: ButtonProps) {
  return <Button type="primary" size="large" {...props} />;
}

const CarrierDetailsPopupAlarmStatusStyled = styled(CarrierDetailsPopupAlarmStatus)`
  padding-left: 24px;
  padding-right: 31px;
  border: none;
  border-radius: 4px;
  display: flex;
  pointer-events: none;
`;

export function CDPAlertStatus(props: ButtonProps) {
  return <CarrierDetailsPopupAlarmStatusStyled danger {...props} />;
}

export const CDPConnectionLostStatus = styled(CarrierDetailsPopupAlarmStatusStyled)`
  background: ${midGrey};
`;

export const CDPInMissionStatus = styled(CarrierDetailsPopupAlarmStatusStyled)`
  background: ${blue};
`;

export const CDPInSafeZoneStatus = styled(CarrierDetailsPopupAlarmStatusStyled)`
  background: ${green};
`;

export const CDPWarningStatus = styled(CarrierDetailsPopupAlarmStatusStyled)`
  background: ${yellow};
`;
