import type { ReactNode } from 'react'
import { TextProps } from 'antd/lib/typography/Text';
import Text from '~/components/atoms/typography/Text';

export interface CarrierDetailsPopupHeadSensorTextProps extends TextProps {
  labelText?: ReactNode;
  valueText?: string | false;
  height?: string;
}

function CarrierDetailsPopupHeadSensorText({
  labelText,
  valueText,
  height = 'auto',
}: CarrierDetailsPopupHeadSensorTextProps) {
  return (
    <div style={{ height }} data-id="carrier-details-popup-location">
      {labelText ? <Text weight="bold">{`${labelText}: `}</Text> : null}
      {valueText && <Text weight="light">{valueText}</Text>}
    </div>
  );
}

export default CarrierDetailsPopupHeadSensorText;
