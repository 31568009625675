import CarrierDetailsPopupInfoTypeItemSpace from '~/components/atoms/space/CarrierDetailsPopupInfoTypeItemSpace';
import FullWidth from '~/components/atoms/FullWidth';
import CarrierDetailsPopupInfoTypeItemLabelSpace from '~/components/atoms/space/CarrierDetailsPopupInfoTypeItemLabelSpace';
import Text, { TextProps } from '~/components/atoms/typography/Text';

export interface CarrierDetailsPopupInfoTypeItem2 {
  labelText: string;
  valueText?: string;
  content?: React.ReactNode;
  style?: TextProps['style'];
}

export interface CarrierDetailsPopupInfoTypeItem2ListProps {
  elements: CarrierDetailsPopupInfoTypeItem2[];
}

export default function CarrierDetailsPopupInfoTypeItem2List({
  elements,
}: CarrierDetailsPopupInfoTypeItem2ListProps) {
  return (
    <CarrierDetailsPopupInfoTypeItemSpace>
      {elements
        .filter(({ labelText }) => labelText)
        .map((element) => (
          <FullWidth key={element.labelText}>
            <CarrierDetailsPopupInfoTypeItemLabelSpace>
              <Text weight="light">{element.labelText}</Text>
            </CarrierDetailsPopupInfoTypeItemLabelSpace>
            {element.valueText && (
              <Text weight="light" style={element.style}>
                {element.valueText}
              </Text>
            )}
            {element.content}
          </FullWidth>
        ))}
    </CarrierDetailsPopupInfoTypeItemSpace>
  );
}
