import { InMemoryCache } from '@apollo/client';

export const getNewCacheInstance = () =>
  new InMemoryCache({
    typePolicies: {
      Carrier_Cognito: {
        fields: {
          device: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
          attributes: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });
