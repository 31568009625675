import styled from 'styled-components';
import Space from './Space';

const SpaceBetweenSpace = styled(Space)`
  margin: 20px;
  display: flex;
  justify-content: space-between;
`;

export default SpaceBetweenSpace;
