import styled from 'styled-components';
import { NAVBAR_HEIGHT } from '~/styles/dimensions';
import * as medias from '~/styles/medias';
import TeamsFilter from '~/components/TeamsFilter';
import AgentStatusFilter from '~/components/AgentStatusFilter';

const GUTTER = '12px';

const ContainerDiv = styled('div')`
  position: absolute;
  top: calc(${NAVBAR_HEIGHT}px + ${GUTTER});
  left: ${GUTTER};
  padding-right: ${GUTTER};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${GUTTER};
  align-items: start;
  user-select: none;

  ${medias.gteMedium} {
    grid-template-columns: repeat(2, auto);
  }
`;

const StyledTeamsFilter = styled(TeamsFilter)`
  ${medias.gteMedium} {
    width: 400px;
  }
`;

export default function DashboardTopLeftOverlay() {
  return (
    <ContainerDiv>
      <AgentStatusFilter />
      <StyledTeamsFilter />
    </ContainerDiv>
  );
}
