import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import type { InputRef, TableProps } from 'antd';
import type {
  ColumnType,
  FilterConfirmProps,
  FilterDropdownProps,
  SorterResult,
  SortOrder,
} from 'antd/lib/table/interface';
import Popover from 'antd/lib/popover';
import Table from 'antd/lib/table/Table';
import { SearchOutlined } from '@ant-design/icons';
import { DataIndex, DataType } from '~/types/agent';
import i18n from '~/locales/i18n';
import browserStorage from '~/utils/browserStorage';
import useCurrentUserContext from '~/context/CurrentUserContext';
import useAgentsContext from '~/context/AgentsContext';
import Input from '~/components/atoms/input/Input';
import Button from '~/components/atoms/button/Button';
import Space from '~/components/atoms/space/Space';

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin: 16px;

  td {
    background: #ffffff;
  }
`;

type ColumnDateType = any;

type SortableSearchableTableProps = Omit<TableProps<Record<string, unknown>>, 'id'> & {
  id: string;
};

type OnInputChangeType = (
  dataIndex: DataIndex,
  value: string,
  confirm: (param?: FilterConfirmProps) => void,
  setSelectedKeys: (selectedKeys: React.Key[]) => void,
) => void;

type OnResetType = (
  confirm?: (param?: FilterConfirmProps) => void,
  clearFilters?: () => void,
) => void;

type GetColumnSearchProps = {
  column: ColumnDateType;
  filter?: string[];
  inputRef: RefObject<InputRef>;
  onInputChange: OnInputChangeType;
  onReset: OnResetType;
};

const getColumnSearchProps = ({
  column,
  filter,
  inputRef,
  onInputChange,
  onReset,
}: GetColumnSearchProps): ColumnType<DataType> => ({
  filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
    <div style={{ padding: 8 }}>
      <Space>
        <Input
          ref={inputRef}
          placeholder={`Search ${column.dataIndex}`}
          value={column.dataIndex === filter?.[0] ? filter?.[1] : ''}
          onChange={(e) =>
            onInputChange(column.dataIndex, e.target.value, confirm, setSelectedKeys)
          }
        />
        <Button onClick={() => onReset(confirm, clearFilters)} size="middle" style={{ width: 90 }}>
          {i18n.t('general.table.clear')}
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={filtered ? { color: '#1890ff' } : {}} />
  ),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) setTimeout(() => inputRef.current?.select(), 50);
  },
  render: (text, record) => {
    const content =
      filter?.[0] === column.dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[filter?.[1] || '']}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      );

    return column.popover && record[column.popover] ? (
      <Popover content={record[column.popover]}>{content}</Popover>
    ) : (
      content
    );
  },
});

const sortStorageKey = 'sortableSearchableTableSort';
const filterStorageKey = 'sortableSearchableTableFilter';

function SortableSearchableTable({
  id,
  columns,
  dataSource,
  ...props
}: SortableSearchableTableProps & TableProps<any>) {
  const currentUser = useCurrentUserContext();
  const { hasAlert } = useAgentsContext();
  const [sort, setSort] = useState(['', '']);
  const [filter, setFilter] = useState(['', '']);
  const inputRef = useRef<InputRef>(null);

  const handleChange = (
    pagination: unknown,
    filters: unknown,
    sorter: SorterResult<object> | SorterResult<object>[],
    extra: { action: 'paginate' | 'sort' | 'filter' },
  ) => {
    if (extra?.action === 'sort' && currentUser?.id) {
      const savedSort = browserStorage.session.get(sortStorageKey, true) || {};

      savedSort[currentUser.id] = {
        ...savedSort[currentUser.id],
        [id]: [(sorter as SorterResult<object>).columnKey, (sorter as SorterResult<object>).order],
      };

      browserStorage.session.set(sortStorageKey, savedSort, true);
      setSort(savedSort[currentUser.id][id]);
    }
  };

  const handleSearch = useCallback(
    (column = '', searchTerm = '') => {
      if (!currentUser?.id) return;

      const savedFilter = browserStorage.session.get(filterStorageKey, true) || {};

      savedFilter[currentUser.id] = { ...savedFilter[currentUser.id], [id]: [column, searchTerm] };

      browserStorage.session.set(filterStorageKey, savedFilter, true);
      setFilter(savedFilter[currentUser.id][id]);
    },
    [id, currentUser?.id],
  );

  const onReset: OnResetType = useCallback(
    (confirm, clearFilters) => {
      handleSearch('', '');
      clearFilters?.();
      confirm?.({ closeDropdown: false });
    },
    [handleSearch],
  );

  const onInputChange: OnInputChangeType = (dataIndex, value, confirm, setSelectedKeys) => {
    handleSearch(dataIndex, value);
    setSelectedKeys(value ? [value] : []);
    confirm({ closeDropdown: false });
  };

  const parsedColumns = columns?.map((column: ColumnDateType) => ({
    ...column,
    ...(column.sorter && sort?.[0] === column.dataIndex ? { sortOrder: sort[1] as SortOrder } : {}),
    ...(column.filtered
      ? getColumnSearchProps({
          column,
          filter,
          inputRef,
          onInputChange,
          onReset,
        })
      : {}),
  }));
  const parsedDataSource = dataSource?.filter((record) =>
    filter?.[0]
      ? record[filter[0]]?.toString().toLowerCase().includes(filter[1].toLowerCase())
      : true,
  );

  useEffect(() => {
    if (!currentUser?.id) return;

    setSort(browserStorage.session.get(sortStorageKey, true)?.[currentUser.id]?.[id]);
    setFilter(browserStorage.session.get(filterStorageKey, true)?.[currentUser.id]?.[id]);
  }, [currentUser?.id, id]);

  useEffect(() => {
    if (hasAlert) onReset();
  }, [hasAlert, onReset]);

  return (
    <StyledTable
      {...props}
      columns={parsedColumns}
      dataSource={parsedDataSource}
      onChange={handleChange}
    />
  );
}

export default SortableSearchableTable;
