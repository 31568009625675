import { getAgentsSortedByStatus } from '~/utils/agent';
import useVehicleDetailsPopupContext from '~/components/DetailsPopup/vehicleDetailsPopup/VehicleDetailsPopupContext';
import { CarrierDetailsPopupContextProvider } from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import CarrierDetailsPopupHeadContent from '~/components/DetailsPopup/content/headContent/CarrierDetailsPopupHeadContent';
import CarrierDetailsPopupMidContent from '~/components/DetailsPopup/content/midContent/CarrierDetailsPopupMidContent';
import CarrierDetailsContentColumnGrid from '~/components/DetailsPopup/content/midContent/components/CarrierDetailsContentColumnGrid';

export default function SosVehicleDetailsPopupGrid() {
  const { vehicle } = useVehicleDetailsPopupContext();
  const agents = getAgentsSortedByStatus(vehicle?.agents);

  if (!agents?.length) return null;

  if (agents.length > 2) {
    return (
      <CarrierDetailsContentColumnGrid cols="N">
        {agents.map(({ id }, index) => (
          <CarrierDetailsPopupContextProvider key={id} carrierID={id} vehicle={vehicle}>
            {index === 0 && <CarrierDetailsPopupHeadContent count={agents.length} />}
            <CarrierDetailsPopupMidContent count={agents.length} />
          </CarrierDetailsPopupContextProvider>
        ))}
      </CarrierDetailsContentColumnGrid>
    );
  }

  return (
    <CarrierDetailsContentColumnGrid cols={agents.length > 1 ? '2' : undefined}>
      {agents.map(({ id }) => (
        <CarrierDetailsPopupContextProvider key={id} carrierID={id}>
          <CarrierDetailsPopupHeadContent count={agents.length} />
          <CarrierDetailsPopupMidContent count={agents.length} />
        </CarrierDetailsPopupContextProvider>
      ))}
    </CarrierDetailsContentColumnGrid>
  );
}
