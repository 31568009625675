import { ImageProps } from 'antd';
import { computeURL } from '~/utils/assets';
import Logo from '~/components/atoms/logo/Logo';

const src = computeURL('/icons/equipment_status_icon__black.png');

function EquipmentStatusIcon(props: ImageProps) {
  return <Logo src={src} preview={false} {...props} />;
}

export default EquipmentStatusIcon;
