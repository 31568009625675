import styled from 'styled-components';

const CarrierDetailsContentColumnGrid = styled.div<{ cols?: '2' | 'N' }>`
  display: grid;
  gap: 16px;
  margin: 16px;
  ${({ cols }) =>
    cols === '2' &&
    `
      grid-auto-flow: column;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);

      > div {
        align-content: start;
      }
  `};

  ${({ cols }) =>
    cols === 'N' &&
    `
      grid-template-columns: repeat(2, 1fr);
      align-items: start;

      > div:first-child {
        grid-column: 1 / span 2;
      }
  `};
`;

export default CarrierDetailsContentColumnGrid;
