import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
    }

    #root {
      position: relative;
      width: 100%;
      height: 100%;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5.5px;
      background-color: transparent;

    }
    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      border-radius: 5.5px;
      background-color: inherit;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5.5px;
      background-color: #9c9c9c;
    }
`;

export default GlobalStyles;
