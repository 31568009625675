import styled from 'styled-components';
import { ButtonProps } from 'antd';
import { white } from '~/styles/colours';
import {
  CDPAlertStatus,
  CDPWarningStatus,
  CDPInMissionStatus,
  CDPInSafeZoneStatus,
  CDPConnectionLostStatus,
} from '~/components/atoms/button/CarrierDetailsPopupAlarmStatus';
import AlertIconWhiteSmall from '~/components/atoms/icon/AlertIconWhiteSmall';
import i18n from '~/locales/i18n';
import { AGENT_STATUS } from '~/types/alarm';

interface CarrierDetailsPopupCustomizedButtonProps extends ButtonProps {
  alarmType: AGENT_STATUS;
}

const H4 = styled('h4')`
  margin: 0;
  font-size: 18px;
  color: ${white};
`;

function CarrierDetailsPopupCustomizedButton({
  alarmType,
  ...props
}: CarrierDetailsPopupCustomizedButtonProps) {
  switch (alarmType) {
    case AGENT_STATUS.ALERT:
      return (
        <CDPAlertStatus {...props}>
          <AlertIconWhiteSmall />
          <H4>{i18n.t('general.generalAlarms.alert')}</H4>
        </CDPAlertStatus>
      );
    case AGENT_STATUS.WARNING:
      return (
        <CDPWarningStatus {...props}>
          <H4>{i18n.t('general.generalAlarms.warning')}</H4>
        </CDPWarningStatus>
      );
    case AGENT_STATUS.IN_MISSION:
      return (
        <CDPInMissionStatus {...props}>
          <H4>{i18n.t('general.generalAlarms.inMission')}</H4>
        </CDPInMissionStatus>
      );
    case AGENT_STATUS.IN_SAFE_ZONE:
      return (
        <CDPInSafeZoneStatus {...props}>
          <H4>{i18n.t('general.generalAlarms.inSafeZone')}</H4>
        </CDPInSafeZoneStatus>
      );
    case AGENT_STATUS.CONNECTION_LOST:
      return (
        <CDPConnectionLostStatus {...props}>
          <H4>{i18n.t('general.generalAlarms.connectionLost')}</H4>
        </CDPConnectionLostStatus>
      );
    default:
      return null;
  }
}

export default CarrierDetailsPopupCustomizedButton;
