import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { RuleObject } from 'antd/lib/form';
import { Input, Button, Form } from 'antd';
import i18n from '~/locales/i18n';
import notification from '~/utils/notification';
import { isValidCoordinate } from '~/utils/validation';
import useMutationUpdateSubsidiary from '~/services/api/apis/useMutationUpdateSubsidiary';
import useSubsidiary from '~/hooks/useSubsidiary';
import * as medias from '~/styles/medias';

const WrapperDiv = styled('div')`
  max-width: 600px;
`;

const H4 = styled('h4')`
  font-size: 12px;
  font-weight: normal;
`;

const H5 = styled('h5')`
  font-size: 16px;
  margin: 16px 0 8px;
  font-weight: bold;
`;

const CoordinatesDiv = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;

  ${medias.gteMedium} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

function coordinateValidator(value: string, coordinate: Parameters<typeof isValidCoordinate>[1]) {
  return isValidCoordinate(parseFloat(value), coordinate)
    ? Promise.resolve()
    : Promise.reject(new Error());
}

interface FormValues {
  name: string;
  latitude: string;
  longitude: string;
}

export default function SubsidiaryDetails() {
  const { currentSubsidiary } = useSubsidiary();
  const { updateSubsidiary, isLoading } = useMutationUpdateSubsidiary();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubsidiary?.id]);

  const onFinish = useCallback(
    async (values: FormValues) => {
      try {
        await updateSubsidiary({
          name: values.name,
          location: {
            lat: parseFloat(values.latitude),
            lng: parseFloat(values.longitude),
          },
        });
        notification.success({
          message: i18n.t('subsidiaryDetails.updateSuccessful'),
        });
      } catch (error) {
        notification.error({
          message: (error as Error)?.message,
        });
      }
    },
    [updateSubsidiary],
  );

  if (!currentSubsidiary?.id) {
    return <p>{i18n.t('subsidiaryDetails.missingSubsidiary')}</p>;
  }

  return (
    <WrapperDiv>
      <H4>{currentSubsidiary?.name}</H4>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          name: currentSubsidiary?.name || '',
          latitude: currentSubsidiary?.location?.lat?.toString() || '',
          longitude: currentSubsidiary?.location?.lng?.toString() || '',
        }}
      >
        <H5>{i18n.t('subsidiaryDetails.subsidiaryName')}</H5>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: i18n.t<string>('subsidiaryDetails.errorMessages.name'),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <H5>{i18n.t('subsidiaryDetails.subsidiaryMapCoordinates')}</H5>
        <CoordinatesDiv>
          <Form.Item
            name="latitude"
            rules={[
              {
                required: true,
                message: i18n.t<string>('subsidiaryDetails.errorMessages.latitude'),
                validator: (rule: RuleObject, value: FormValues['latitude']) =>
                  coordinateValidator(value, 'latitude'),
              },
            ]}
          >
            <Input type="text" addonBefore={i18n.t('subsidiaryDetails.latitude')} />
          </Form.Item>
          <Form.Item
            name="longitude"
            rules={[
              {
                required: true,
                message: i18n.t<string>('subsidiaryDetails.errorMessages.longitude'),
                validator: (rule: RuleObject, value: FormValues['longitude']) =>
                  coordinateValidator(value, 'longitude'),
              },
            ]}
          >
            <Input type="text" addonBefore={i18n.t('subsidiaryDetails.longitude')} />
          </Form.Item>
        </CoordinatesDiv>
        <Form.Item>
          <Button htmlType="submit" type="default" loading={isLoading}>
            {i18n.t('general.forms.save')}
          </Button>
        </Form.Item>
      </Form>
    </WrapperDiv>
  );
}
