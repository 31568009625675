import styled from 'styled-components';
import { Form } from 'antd';
import { grey, white } from '~/styles/colours';

const StyledForm = styled(Form)`
  width: 393px;
  max-height: 500px;
  border: 1px solid ${grey};
  margin: 7% auto !important;
  padding: 42px 24px 50px !important;
  background: ${white};
`;

export default StyledForm;
