import orderBy from 'lodash/orderBy';
import i18n from '~/locales/i18n';
import { AGENT_STATUS } from '~/types/alarm';
import { getAgentActiveAlarmMap, getAgentStatus, getAgentStatusColor } from '~/utils/agent';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import CarrierDetailsPopupInfoTypeItem2List from '~/components/DetailsPopup/content/list/CarrierDetailsPopupInfoTypeItem2List';
import CarrierDetailsPopupInfoTypeAlertItem from './CarrierDetailsPopupInfoTypeAlertItem';

interface Props {
  count?: number;
}

export default function CarrierDetailsPopupInfoTypeAlertItemList({ count = 1 }: Props) {
  const { agent, alertTime } = useCarrierDetailsPopupContext();

  if (!agent) return null;

  const alarms = orderBy(
    Object.entries(getAgentActiveAlarmMap(agent)),
    ([, value]) => value?.administrator,
    'desc',
  );

  return (
    <>
      {alarms.map(([key, alarm]) => (
        <CarrierDetailsPopupInfoTypeAlertItem
          key={key}
          alertTime={alertTime}
          labelText={i18n.t(`general.generalAlarms.${key}`, { level: alarm.value })}
          alarm={alarm}
        />
      ))}
      {agent.status !== AGENT_STATUS.ALERT && (
        <CarrierDetailsPopupInfoTypeItem2List
          elements={[
            {
              labelText: i18n.t(`carrierDetailsPopup.alerts.noAlerts`),
              content: count > 1 && (
                <span style={{ color: getAgentStatusColor(agent?.status) }}>
                  {getAgentStatus(agent)}
                </span>
              ),
            },
          ]}
        />
      )}
    </>
  );
}
