import styled from 'styled-components';
import Space from './Space';

const CarrierDetailsPopupInfoTypeItemSpace = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 250px;
`;

export default CarrierDetailsPopupInfoTypeItemSpace;
