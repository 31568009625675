import { useMemo } from 'react';
import styled from 'styled-components';
import Tooltip from 'antd/lib/tooltip';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import camelCase from 'lodash/camelCase';
import { AgentAttributesType, AgentType } from '~/types/agent';
import { DETAILS_POPUP_TYPE } from '~/types/popup';
import { getAgentStatus, computeCarrierShorthandLabel } from '~/utils/agent';
import { computeStatusLabel } from '~/utils/equipment';
import { computeMissionTime } from '~/utils/dateTime';
import i18n from '~/locales/i18n';
import routes from '~/navigation/config/routes';
import useAgentsContext from '~/context/AgentsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import BlackLink from '~/components/atoms/link/BlackLink';
import SortableSearchableTable from '~/components/SortableSearchableTable';
import useAgentsTableColumns from './useAgentsTableColumns';
import { computeEquipmentStatuses } from './utils';

const ActionBtnsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const PopoverSummaryDl = styled.dl`
  margin: 0;

  & > div {
    display: flex;
    gap: 4px;

    dt {
      margin: 0;
      font-weight: bold;
    }

    dd {
      margin: 0;
    }
  }
`;
interface AgentsTableProps {
  onAgentEdit: (agent: AgentType) => void;
  onAgentDelete: (agent: AgentType) => void;
}

const agentAttributes = [
  'first_name',
  'last_name',
  'phone_number',
  'team',
  'age',
  'gender',
] as const;

function AgentsTable({ onAgentEdit, onAgentDelete }: AgentsTableProps) {
  const { agents } = useAgentsContext();
  const columns = useAgentsTableColumns();
  const features = useCompanyFeatures();

  const getDetailsLink = useMemo(
    () =>
      (
        carrierId: string,
        attributes: AgentAttributesType,
      ): {
        pathname: string;
        search: string | undefined;
      } => {
        const vehicleId = attributes.plate_number;

        if (features.vehicles && vehicleId) {
          return routes.status({
            id: vehicleId,
            type: DETAILS_POPUP_TYPE.VEHICLE,
          });
        }

        return routes.status({ id: carrierId });
      },
    [features.vehicles],
  );

  const dataSource = useMemo(
    () =>
      agents.map((agent: AgentType) => {
        const {
          id,
          completeName,
          team,
          deviceName,
          status,
          missionStartTimeISO,
          missionEndTimeISO,
          isOffline,
          attributes,
          equipmentStatus,
        } = agent;
        const garmentStatuses = computeEquipmentStatuses(equipmentStatus, features);

        return {
          key: id,
          agent: completeName
            ? `${completeName} (${computeCarrierShorthandLabel(completeName)})`
            : '-',
          agentPopover: (
            <PopoverSummaryDl>
              {agentAttributes.map((attributeName) => (
                <div key={attributeName}>
                  <dt>{i18n.t(`agentForm.${camelCase(attributeName)}`)}:</dt>
                  <dd>{attributes[attributeName] || i18n.t('agentForm.unknown')}</dd>
                </div>
              ))}
            </PopoverSummaryDl>
          ),
          team: team || '-',
          vehicle: (!isOffline && attributes.plate_number) || '-',
          status,
          agentStatus: getAgentStatus({ isOffline, status }),
          isOffline,
          missionStart: computeMissionTime(missionStartTimeISO) || '-',
          missionEnd: computeMissionTime(missionEndTimeISO) || '-',
          garment: deviceName || '-',
          garmentStatus: garmentStatuses.status.label,
          garmentStatusColor: garmentStatuses.status.color,
          garmentStatusPopover: !isOffline && (
            <PopoverSummaryDl>
              <div>
                <dt>{i18n.t('carrierDetailsPopup.equipment.brain')}:</dt>
                <dd style={{ color: garmentStatuses.brain.color }}>
                  {garmentStatuses.brain.label}
                </dd>
              </div>
              {features.emergencyButton && (
                <div>
                  <dt>{i18n.t('carrierDetailsPopup.equipment.sosButton')}:</dt>
                  <dd style={{ color: garmentStatuses.emergencyButton.color }}>
                    {garmentStatuses.emergencyButton.label}{' '}
                    {computeStatusLabel(equipmentStatus.emergencyButton) &&
                      `(${computeStatusLabel(equipmentStatus.emergencyButton)})`}
                  </dd>
                </div>
              )}
              {features.heartRateSensor && (
                <div>
                  <dt>{i18n.t('carrierDetailsPopup.equipment.heartRate')}:</dt>
                  <dd style={{ color: garmentStatuses.heartRate.color }}>
                    {garmentStatuses.heartRate.label}{' '}
                    {computeStatusLabel(equipmentStatus.heartRate) &&
                      `(${computeStatusLabel(equipmentStatus.heartRate)})`}
                  </dd>
                </div>
              )}
              {features.bodyTemperatureSensor && (
                <div>
                  <dt>{i18n.t('carrierDetailsPopup.equipment.bodyTemperature')}:</dt>
                  <dd style={{ color: garmentStatuses.bodyTemperature.color }}>
                    {garmentStatuses.bodyTemperature.label}{' '}
                    {computeStatusLabel(equipmentStatus.bodyTemperature) &&
                      `(${computeStatusLabel(equipmentStatus.bodyTemperature)})`}
                  </dd>
                </div>
              )}
              {features.gasSensor && (
                <div>
                  <dt>{i18n.t('carrierDetailsPopup.equipment.gas')}:</dt>
                  <dd style={{ color: garmentStatuses.gas.color }}>
                    {garmentStatuses.gas.label}{' '}
                    {computeStatusLabel(equipmentStatus.gas) &&
                      `(${computeStatusLabel(equipmentStatus.gas)})`}
                  </dd>
                </div>
              )}
              {features.impactDetectionFront && (
                <div>
                  <dt>{i18n.t('carrierDetailsPopup.equipment.traakFront')}:</dt>
                  <dd style={{ color: garmentStatuses.traakFront.color }}>
                    {garmentStatuses.traakFront.label}{' '}
                    {computeStatusLabel(equipmentStatus.traakFront) &&
                      `(${computeStatusLabel(equipmentStatus.traakFront)})`}
                  </dd>
                </div>
              )}
              {features.impactDetectionBack && (
                <div>
                  <dt>{i18n.t('carrierDetailsPopup.equipment.traakBack')}:</dt>
                  <dd style={{ color: garmentStatuses.traakBack.color }}>
                    {garmentStatuses.traakBack.label}{' '}
                    {computeStatusLabel(equipmentStatus.traakBack) &&
                      `(${computeStatusLabel(equipmentStatus.traakBack)})`}
                  </dd>
                </div>
              )}
            </PopoverSummaryDl>
          ),
          actions: (
            <ActionBtnsDiv>
              {features.deleteAgents && (
                <Tooltip title={i18n.t('agentForm.deleteAgent')}>
                  <DeleteOutlined onClick={() => onAgentDelete(agent)} />
                </Tooltip>
              )}
              <Tooltip title={i18n.t('agentForm.editAgent')}>
                <EditOutlined onClick={() => onAgentEdit(agent)} />
              </Tooltip>
              {!isOffline && (
                <Tooltip title={i18n.t('agentsPage.viewDetails')}>
                  <BlackLink
                    $forced
                    to={getDetailsLink(id, attributes)}
                    data-id="agent-details-link"
                  >
                    <InfoCircleOutlined />
                  </BlackLink>
                </Tooltip>
              )}
            </ActionBtnsDiv>
          ),
        };
      }) ?? [],
    [agents, features, getDetailsLink, onAgentEdit, onAgentDelete],
  );

  return (
    <SortableSearchableTable
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      id="agents"
      data-id="agents-table"
    />
  );
}

export default AgentsTable;
