import { ReactNode } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { grey, white } from '~/styles/colours';

const WrapperDiv = styled('div')`
  padding: 0 5px;
`;

const StyledSelect = styled(Select<string>)`
  .ant-select-selector.ant-select-selector {
    width: 200px;
  }

  .ant-select-arrow.ant-select-arrow,
  .ant-select-selection-item.ant-select-selection-item {
    color: ${white};
    font-weight: 600;

    &:hover {
      color: ${grey};
    }
  }
`;

interface Props {
  children: ReactNode;
  value: string;
  onChange: (value: string) => void;
  'data-id': string;
}

export default function NavBarSelect({ children, value, onChange, 'data-id': dataId }: Props) {
  return (
    <WrapperDiv>
      <StyledSelect
        bordered={false}
        listHeight={500}
        value={value}
        onChange={onChange}
        data-id={dataId}
      >
        {children}
      </StyledSelect>
    </WrapperDiv>
  );
}
