import { grey } from '~/styles/colours';
import { AGENT_STATUS } from '~/types/alarm';
import { computeCarrierShorthandLabel } from '~/utils/agent';
import { getAgentStatusColor } from '~/utils/agent';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import DetailsPopupHeader from './DetailsPopupHeader';

interface CarrierDetailsPopupHeaderProps {
  variant: 'team' | 'vehicle';
}

function CarrierDetailsPopupHeader({ variant }: CarrierDetailsPopupHeaderProps) {
  const { agent, carrierName } = useCarrierDetailsPopupContext();

  return (
    <DetailsPopupHeader
      bulletLabel={carrierName ? computeCarrierShorthandLabel(carrierName) : ''}
      bulletColor={agent?.status ? getAgentStatusColor(agent?.status) : grey}
      title={carrierName}
      subtitle={agent?.attributes[variant === 'team' ? 'team' : 'plate_number']}
      alarmType={agent?.status ?? AGENT_STATUS.CONNECTION_LOST}
    />
  );
}

export default CarrierDetailsPopupHeader;
