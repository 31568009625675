import { Layout } from 'antd';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import useCurrentUserContext from '~/context/CurrentUserContext';
import { darkBlue } from '~/styles/colours';
import { Z_INDEX } from '~/styles/layers';
import { NAVBAR_HEIGHT } from '~/styles/dimensions';
import NavBarLogo from './NavBarLogo';
import NavBarDivider from './NavBarDivider';
import NavBarSelectCompany from './NavBarSelectCompany';
import NavBarSelectSubsidiary from './NavBarSelectSubsidiary';
import NavBarMenu from './NavBarMenu';
import NavBarConnectionIndicator from './NavBarConnectionIndicator';
import NavBarUserDropdown from './NavBarUserDropdown';
import NavBarEntityName from './NavBarEntityName';

export default function NavBar() {
  const currentUser = useCurrentUserContext();
  const { companyList } = useCompany();
  const { subsidiaryList } = useSubsidiary();

  return (
    <Layout.Header
      style={{
        height: `${NAVBAR_HEIGHT}px`,
        background: darkBlue,
        padding: '0 16px',
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        zIndex: Z_INDEX.NAV_BAR,
        userSelect: 'none',
      }}
    >
      <NavBarLogo />
      <NavBarDivider />
      {companyList.length > 0 ? (
        <NavBarSelectCompany />
      ) : (
        <NavBarEntityName name={currentUser?.company?.name} />
      )}
      <NavBarDivider />
      {subsidiaryList.length > 0 ? (
        <NavBarSelectSubsidiary />
      ) : (
        <NavBarEntityName name={currentUser?.subsidiary?.name} />
      )}
      <NavBarDivider />
      <NavBarMenu />
      <NavBarConnectionIndicator />
      <NavBarDivider />
      <NavBarUserDropdown />
    </Layout.Header>
  );
}
