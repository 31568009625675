import { AGENT_STATUS } from '~/types/alarm';

export const currentYear = new Date().getFullYear();
export const APPLICATION_NAME = "Wearin' Dashboard";

export const agentStatusPriority = Object.freeze({
  [AGENT_STATUS.IN_SAFE_ZONE]: 1,
  [AGENT_STATUS.IN_MISSION]: 2,
  [AGENT_STATUS.WARNING]: 3,
  [AGENT_STATUS.CONNECTION_LOST]: 4,
  [AGENT_STATUS.ALERT]: 5,
});
