import { gql } from '@apollo/client';

export interface SetCompanyAttributeMutationVariables {
  companyId: string;
  name: string;
  value: string;
}

export interface SetCompanyAttributeMutationData {
  setCompanyAttribute: {
    __typename: 'Company';
    id: string;
    name: string;
    attributes: {
      __typename: 'Attribute';
      name: string;
      value: string;
    };
  };
}

export default gql`
  mutation MutationSetCompanyAttribute($companyId: ID!, $name: String!, $value: String) {
    setCompanyAttribute(company_id: $companyId, name: $name, value: $value) {
      id
      name
      attributes {
        name
        value
      }
    }
  }
`;
