import { grey } from '~/styles/colours';
import { AGENT_STATUS } from '~/types/alarm';
import { getAgentStatusColor } from '~/utils/agent';
import { computeVehicleShorthandLabel } from '~/utils/agent';
import DetailsPopupHeader from '~/components/DetailsPopup/content/header/DetailsPopupHeader';
import useVehicleDetailsPopupContext from '~/components/DetailsPopup/vehicleDetailsPopup/VehicleDetailsPopupContext';

export default function SosVehicleDetailsPopupHeader() {
  const { vehicle } = useVehicleDetailsPopupContext();
  const vehicleID = vehicle?.id;
  const alarmType = vehicle?.status ?? AGENT_STATUS.CONNECTION_LOST;
  const label: string = computeVehicleShorthandLabel(vehicleID ?? '');
  const color: string = vehicle?.status ? getAgentStatusColor(vehicle?.status) : grey;

  return (
    <DetailsPopupHeader
      bulletLabel={label}
      bulletColor={color}
      title={vehicleID}
      alarmType={alarmType}
    />
  );
}
