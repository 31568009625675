import type { TitleProps as AntdTitleProps } from 'antd/lib/typography/Title';
import AntdTitle from 'antd/lib/typography/Title';

interface TitleProps extends AntdTitleProps {
  spaceless?: boolean;
}

function Title({ spaceless, style = {}, ...props }: TitleProps) {
  return (
    <AntdTitle
      style={{
        ...style,
        ...(spaceless ? { margin: 0 } : {}),
      }}
      {...props}
    />
  );
}

export default Title;
