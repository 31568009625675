import { useEffect, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { AGENT_STATUS } from '~/types';
import browserStorage from '~/utils/browserStorage';
import {
  selectedAgentStatusesVar,
  SELECTED_AGENT_STATUSES_DEFAULT_VALUE,
} from '~/services/api/reactiveVariables/selectedAgentStatusesVar';
import useAgentsContext from '~/context/AgentsContext';
import useCurrentUserContext from '~/context/CurrentUserContext';

const STORAGE_KEY = 'selectedAgentStatuses';

export const AGENT_STATUSES = [
  AGENT_STATUS.ALERT,
  AGENT_STATUS.CONNECTION_LOST,
  AGENT_STATUS.IN_MISSION,
  AGENT_STATUS.IN_SAFE_ZONE,
];

export default function useAgentStatuses(): {
  selectedAgentStatuses: AGENT_STATUS[];
  toggleSelectedAgentStatus: (status: AGENT_STATUS) => void;
} {
  const currentUser = useCurrentUserContext();
  const { hasAlert } = useAgentsContext();
  // reactive var as a singleton global state shared between all hook's instances
  const selectedAgentStatuses = useReactiveVar(selectedAgentStatusesVar);

  const setSelectedAgentStatuses = useCallback(
    (selectedStatuses: AGENT_STATUS[]) => {
      if (!currentUser?.id) return;

      browserStorage.session.set(
        STORAGE_KEY,
        {
          ...(browserStorage.session.get(STORAGE_KEY, true) || {}),
          [currentUser.id]: selectedStatuses,
        },
        true,
      );
      selectedAgentStatusesVar(selectedStatuses);
    },
    [currentUser?.id],
  );

  const toggleSelectedAgentStatus = useCallback(
    (status: AGENT_STATUS) => {
      if (selectedAgentStatuses.includes(status)) {
        setSelectedAgentStatuses(selectedAgentStatuses.filter((s) => s !== status));
      } else {
        const result = [...selectedAgentStatuses, status];

        setSelectedAgentStatuses(result.length === AGENT_STATUSES.length ? [] : result);
      }
    },
    [selectedAgentStatuses, setSelectedAgentStatuses],
  );

  useEffect(() => {
    if (!currentUser?.id) {
      selectedAgentStatusesVar(SELECTED_AGENT_STATUSES_DEFAULT_VALUE);

      return;
    }

    const cached =
      browserStorage.session.get(STORAGE_KEY, true)?.[currentUser.id] ||
      SELECTED_AGENT_STATUSES_DEFAULT_VALUE;

    // can happen only on app load
    if (!selectedAgentStatuses.length && cached.length) {
      selectedAgentStatusesVar(cached);
    }
  }, [currentUser?.id, selectedAgentStatuses.length]);

  useEffect(() => {
    if (hasAlert && currentUser?.id) {
      // delayed to make sure on app load it comes after useAgentStatuses init
      setTimeout(() => setSelectedAgentStatuses(SELECTED_AGENT_STATUSES_DEFAULT_VALUE), 0);
    }
  }, [hasAlert, setSelectedAgentStatuses, currentUser?.id]);

  return { selectedAgentStatuses, toggleSelectedAgentStatus };
}
