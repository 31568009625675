import { useMemo, CSSProperties } from 'react';
import { CompareFn } from 'antd/es/table/interface';
import i18n from '~/locales/i18n';
import { AGENT_STATUS, SORT_ORDER } from '~/types';
import { getAgentStatusColor } from '~/utils/agent';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import { createDefaultSorter, createStatusSorter } from './utils';

interface AgentTableColumn {
  title: string;
  dataIndex: string;
  key: string;
  sorter?: CompareFn<Record<string, unknown>>;
  filtered?: boolean;
  popover?: string;
  defaultSortOrder?: SORT_ORDER;
  onCell?: (record: Record<string, unknown>) => { style: CSSProperties };
}

export default function useAgentsTableColumns() {
  const features = useCompanyFeatures();

  const columns: AgentTableColumn[] = useMemo(
    () => [
      {
        title: i18n.t('agentsPage.agent'),
        dataIndex: 'agent',
        key: 'agent',
        sorter: createDefaultSorter('agent'),
        filtered: true,
        popover: 'agentPopover',
      },
      {
        title: i18n.t('agentsPage.team'),
        dataIndex: 'team',
        key: 'team',
        sorter: createDefaultSorter('team'),
        filtered: true,
      },
      {
        title: i18n.t('agentsPage.status'),
        dataIndex: 'agentStatus',
        key: 'agentStatus',
        onCell: ({ isOffline, status }) => ({
          style: isOffline ? {} : { background: getAgentStatusColor(status as AGENT_STATUS) },
        }),
        defaultSortOrder: SORT_ORDER.DESCEND,
        sorter: createStatusSorter(),
        filtered: true,
      },
      ...(features.vehicles
        ? [
            {
              title: i18n.t('agentsPage.vehicle'),
              dataIndex: 'vehicle',
              key: 'vehicle',
              sorter: createDefaultSorter('vehicle'),
              filtered: true,
            },
          ]
        : []),
      {
        title: i18n.t('agentsPage.garment'),
        dataIndex: 'garment',
        key: 'garment',
        sorter: createDefaultSorter('garment'),
        filtered: true,
      },
      {
        title: i18n.t('agentsPage.garmentStatus'),
        dataIndex: 'garmentStatus',
        key: 'garmentStatus',
        sorter: createDefaultSorter('garmentStatus'),
        filtered: true,
        onCell: ({ garmentStatusColor }) => ({ style: { color: garmentStatusColor as string } }),
        popover: 'garmentStatusPopover',
      },
      {
        title: i18n.t('agentsPage.missionStart'),
        dataIndex: 'missionStart',
        key: 'missionStart',
        sorter: createDefaultSorter('missionStart'),
      },
      {
        title: i18n.t('agentsPage.missionEnd'),
        dataIndex: 'missionEnd',
        key: 'missionEnd',
        sorter: createDefaultSorter('missionEnd'),
      },
      {
        title: i18n.t('agentsPage.actions'),
        dataIndex: 'actions',
        key: 'actions',
      },
    ],
    [features.vehicles],
  );

  return columns;
}
