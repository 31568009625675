import styled from 'styled-components';
import Button from './Button';

const AlertsToggleButton = styled(Button).attrs({ type: 'text', htmlType: 'submit' })`
  background: none;
  border: none;
  padding: 0;
`;

export default AlertsToggleButton;
