import { useState, FormEvent, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Row, Spin, FormProps } from 'antd';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import QrcodeOutlined from '@ant-design/icons/lib/icons/QrcodeOutlined';
import routes from '~/navigation/config/routes';
import i18n from '~/locales/i18n';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import notification from '~/utils/notification';
import useAuthenticationContext from '~/context/AuthenticationContext';
import { black } from '~/styles/colours';
import ConfirmationCodeForm from '~/components/atoms/form/ConfirmationCodeForm';
import CenteredFormItem from '~/components/atoms/form/CenteredFormItem';
import AuthButton from '~/components/atoms/button/AuthButton';
import WearinLogoBlack from '~/components/atoms/logo/WearinLogoBlack';
import InputPassword from '~/components/atoms/input/InputPassword';
import Input from '~/components/atoms/input/Input';
import Title from '~/components/atoms/typography/Title';
import Text from '~/components/atoms/typography/Text';
import PasswordPolicyPopover from '~/components/atoms/PasswordPolicyPopover';

interface FormFields {
  confirmationCode: string;
  password: string;
}

export default function ResetPasswordPage() {
  const [confirmDirty, setConfirmDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { passwordReset } = useAuthenticationContext();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormFields>();

  const handleBlur = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (_rule: object, value: string) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(new Error(i18n.t<string>('passwordReset.inconsistentPasswords')));
    }

    return Promise.resolve();
  };

  const handleFinish = ({ confirmationCode, password }: FormFields) => {
    setLoading(true);
    passwordReset({
      newPassword: password.trim(),
      confirmationCode: confirmationCode || '',
    })
      .then(() => {
        notification.success({
          message: i18n.t('passwordReset.successfulTitle'),
          description: i18n.t('passwordReset.successfulText'),
        });
        browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
        navigate(routes.default());
      })
      .catch((catchedError: Error) => {
        notification.error({
          message: i18n.t('passwordReset.failedText'),
          description: catchedError.message,
        });
        setLoading(false);
      });
  };

  return (
    <ConfirmationCodeForm<FC<FormProps<FormFields>>> form={form} onFinish={handleFinish}>
      <Col md={24} lg={18}>
        <CenteredFormItem>
          <WearinLogoBlack />
        </CenteredFormItem>
        <CenteredFormItem>
          <Title level={4}>{i18n.t('passwordReset.checkYourEmailTitle')}</Title>
          <Text>{i18n.t('passwordReset.checkYourEmailText')}</Text>
        </CenteredFormItem>
        <CenteredFormItem
          name="confirmationCode"
          rules={[
            {
              required: true,
              len: 6,
              message: i18n.t<string>('passwordReset.confirmationCodeRulesMessage'),
              pattern: /^[0-9]+$/,
            },
          ]}
        >
          <Input
            prefix={<QrcodeOutlined style={{ color: black }} />}
            size="large"
            type="number"
            placeholder={i18n.t<string>('passwordReset.confirmationCodePlaceholder')}
          />
        </CenteredFormItem>
      </Col>
      <Col md={24} lg={18}>
        <PasswordPolicyPopover>
          <CenteredFormItem
            name="password"
            rules={[
              {
                required: true,
                message: i18n.t<string>('passwordReset.newPasswordRulesMessage'),
              },
            ]}
          >
            <InputPassword
              placeholder={i18n.t<string>('passwordReset.newPasswordPlaceholder')}
              autoComplete="new-password"
            />
          </CenteredFormItem>
        </PasswordPolicyPopover>
      </Col>
      <Col md={24} lg={18}>
        <CenteredFormItem
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: i18n.t<string>('passwordReset.confirmPasswordRulesMessage'),
            },
            { validator: compareToFirstPassword },
          ]}
        >
          <InputPassword
            placeholder={i18n.t<string>('passwordReset.confirmPasswordPlaceholder')}
            onBlur={handleBlur}
            autoComplete="new-password"
          />
        </CenteredFormItem>
      </Col>
      <Col md={24} lg={18}>
        <CenteredFormItem>
          <Row>
            <AuthButton style={{ width: '100%' }}>
              {loading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              ) : (
                i18n.t('passwordReset.submitButton')
              )}
            </AuthButton>
          </Row>
        </CenteredFormItem>
      </Col>
    </ConfirmationCodeForm>
  );
}
