import { useState } from 'react';
import styled from 'styled-components';
import { AutoComplete, Form, Select } from 'antd';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import uniq from 'lodash/uniq';
import i18n from '~/locales/i18n';
import { isValidAge, isValidEmail, isValidPhoneNumber } from '~/utils/validation';
import notification from '~/utils/notification';
import {
  useMutationCreateAgent,
  useMutationSetAgentAttributes,
} from '~/services/api/apis/useAgent';
import useAgentsContext from '~/context/AgentsContext';
import useSubsidiary from '~/hooks/useSubsidiary';
import { green } from '~/styles/colours';
import Button from '~/components/atoms/button/Button';
import Input from '~/components/atoms/input/Input';
import LoadingSpin from '~/components/atoms/LoadingSpin';

const PasswordDiv = styled('div')`
  word-break: break-word;
  border: solid 2px ${green};
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 12px;
`;

export const getEmptyAgent = () => ({
  key: Date.now().toString(),
  id: '',
  email: '',
  name: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  team: '',
  age: '',
  gender: 'unknown',
});

const genderOptions = ['male', 'female', 'other', 'unknown'];

const formUserIdValidator = (rule: object, value: string) =>
  value?.trim().length > 1
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidName')));

const formNameValidator = (rule: object, value: string) =>
  !value || value.trim().length > 1
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidName')));

const formEmailValidator = (rule: object, value: string) =>
  isValidEmail(value)
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidEmail')));

const formPhoneNumberValidator = (rule: object, value: string) =>
  !value || isValidPhoneNumber(value)
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidPhoneNumber')));

const formAgeValidator = (rule: object, value: string) =>
  !value || isValidAge(value)
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidAge')));

export type AgentFormValues = ReturnType<typeof getEmptyAgent>;

interface AgentFormProps {
  agent?: Partial<AgentFormValues>;
  onSuccess: () => void;
  className?: string;
}

export default function AgentForm({ agent, onSuccess, className }: AgentFormProps) {
  const { agents } = useAgentsContext();
  const { currentSubsidiaryIdentifier: subsidiaryId } = useSubsidiary();
  const { createAgent, loading: isCreateLoading, createdAgent } = useMutationCreateAgent();
  const { setAgentAttributes, loading: isEditLoading } = useMutationSetAgentAttributes();
  const [form] = Form.useForm<AgentFormValues>();
  const [autoCompletableTeams, setAutoCompletableTeams] = useState<string[]>([]);
  const allTeams = uniq(agents?.map(({ team }) => team)?.filter((team) => team));
  const teamOptions = allTeams
    .filter((team) => autoCompletableTeams.includes(team))
    .map((team) => ({ label: team, value: team }));
  const isLoading = isCreateLoading || isEditLoading;
  const actionType = agent?.name ? 'edit' : 'add';

  if (!agent?.key) return null;

  const handleTeamInputChange = (value: string) => {
    const term = value.trim().toLowerCase();

    setAutoCompletableTeams(
      term ? allTeams.filter((name) => name.toLowerCase().includes(term)) : [],
    );
  };

  const handleSubmit = async ({ email, name, ...rest }: AgentFormValues) => {
    const attributes = Object.entries(rest).map(([key, value]) => ({
      name: key,
      value: value?.trim() || '',
    }));

    try {
      if (actionType === 'edit' && agent.id) {
        await setAgentAttributes({ variables: { agentId: agent.id || '', attributes } });
        onSuccess();
      } else {
        await createAgent({ variables: { subsidiaryId, email, name, attributes } });
      }

      notification.success({
        message: i18n.t(`agentForm.${actionType}SuccessTitle`),
        description: i18n.t(`agentForm.${actionType}SuccessDescription`),
      });
    } catch (error) {
      notification.error({
        message: i18n.t(`agentForm.${actionType}FailTitle`),
        // TODO: this sometimes gives useful info and sometimes outputs system's internal stuff
        // description: error.message,
      });
    }
  };

  if (createdAgent?.tempPassword) {
    return (
      <div>
        <p>{i18n.t('agentForm.temporaryPassword.title', { email: form.getFieldValue('email') })}</p>
        <PasswordDiv>{createdAgent.tempPassword}</PasswordDiv>
        <Button
          type="default"
          onClick={() => {
            navigator.clipboard.writeText(createdAgent.tempPassword);
            notification.success({
              message: i18n.t('general.clipboard.copySuccess'),
            });
          }}
        >
          {i18n.t('agentForm.temporaryPassword.copy')}
        </Button>
        <p>{i18n.t('agentForm.temporaryPassword.description')}</p>
        <p>
          <WarningOutlined /> {i18n.t('agentForm.temporaryPassword.caution')}
        </p>
        <div style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" onClick={onSuccess}>
            {i18n.t('general.forms.done')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      validateTrigger="onBlur"
      initialValues={agent}
      autoComplete="off"
      onFinish={(values) => handleSubmit(values)}
      className={className}
    >
      <div>
        {typeof agent?.email === 'string' && (
          <Form.Item
            name="email"
            label={i18n.t('agentForm.email')}
            rules={[{ required: true, message: '' }, { validator: formEmailValidator }]}
          >
            <Input placeholder={i18n.t<string>('agentForm.emailPlaceholder')} />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label={i18n.t('agentForm.name')}
          rules={[{ required: true, message: '' }, { validator: formUserIdValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.namePlaceholder')} disabled={!!agent?.id} />
        </Form.Item>
        <Form.Item
          name="first_name"
          label={i18n.t('agentForm.firstName')}
          rules={[{ required: false, message: '' }, { validator: formNameValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.firstNamePlaceholder')} />
        </Form.Item>
        <Form.Item
          name="last_name"
          label={i18n.t('agentForm.lastName')}
          rules={[{ required: false, message: '' }, { validator: formNameValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.lastNamePlaceholder')} />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label={i18n.t('agentForm.phoneNumber')}
          rules={[{ required: false, message: '' }, { validator: formPhoneNumberValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.phoneNumberPlaceholder')} />
        </Form.Item>
        <Form.Item name="team" label={i18n.t('agentForm.team')}>
          <AutoComplete
            options={teamOptions}
            onChange={handleTeamInputChange}
            placeholder={i18n.t<string>('agentForm.teamPlaceholder')}
          >
            <Input />
          </AutoComplete>
        </Form.Item>
        <Form.Item name="gender" label={i18n.t('agentForm.gender')}>
          <Select>
            {genderOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {i18n.t<string>(`agentForm.${option}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="age"
          label={i18n.t('agentForm.age')}
          rules={[{ required: false, message: '' }, { validator: formAgeValidator }]}
        >
          <Input placeholder={i18n.t<string>('agentForm.agePlaceholder')} />
        </Form.Item>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button size="large" type="primary" htmlType="submit" disabled={isLoading}>
          {isLoading ? <LoadingSpin /> : i18n.t('general.forms.save')}
        </Button>
      </div>
    </Form>
  );
}
