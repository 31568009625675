import styled from 'styled-components';
import Text from '~/components/atoms/typography/Text';
import useAuthenticationContext from '~/context/AuthenticationContext';
import useCurrentUserContext from '~/context/CurrentUserContext';

function DashboardOverlayUsername() {
  const currentUser = useCurrentUserContext();
  const { getUser } = useAuthenticationContext();
  const username = currentUser?.email ?? getUser()?.getUsername();

  return <Text>{username}</Text>;
}

export default styled(DashboardOverlayUsername)`
  font-weight: bold;
`;
