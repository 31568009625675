import { createContext, ReactNode, useContext, useMemo } from 'react';
import useReverseGeocoding from '~/hooks/useReverseGeocoding';
import { AgentType } from '~/types/agent';
import { VehicleType } from '~/types/vehicle';
import { GpsSensorType } from '~/types/sensor';
import { computeAlarmStartingTime } from '~/utils/alarm';
import useAgentsContext from '~/context/AgentsContext';

interface CarrierDetailsPopupContextResponseType {
  agent?: AgentType;
  carrierName?: string;
  address?: string;
  gps?: GpsSensorType;
  alertTime: string | false;
  relativeAlertTime: string | false;
}

const carrierDetailsPopupContextDefaults: CarrierDetailsPopupContextResponseType = {
  agent: undefined,
  carrierName: undefined,
  address: undefined,
  gps: undefined,
  alertTime: false,
  relativeAlertTime: false,
};

const CarrierDetailsPopupContext = createContext(carrierDetailsPopupContextDefaults);

const useCarrierDetailsPopupContext = () => useContext(CarrierDetailsPopupContext);

interface Props {
  children: ReactNode;
  carrierID: string;
  vehicle?: VehicleType;
}

export function CarrierDetailsPopupContextProvider({ children, carrierID, vehicle }: Props) {
  const { getAgent } = useAgentsContext();
  const agent: AgentType | undefined = useMemo(
    () => getAgent(carrierID ?? ''),
    [getAgent, carrierID],
  );
  const { alertTime, relativeAlertTime } = useMemo(
    () => computeAlarmStartingTime(agent?.alarms),
    [agent?.alarms],
  );
  const gpsLocation = vehicle?.location || agent?.sensors.gps;
  const gps: GpsSensorType | undefined = useMemo(() => gpsLocation, [gpsLocation]);
  const { address } = useReverseGeocoding(gps);

  const returnValues = useMemo(
    () => ({
      agent,
      carrierName: agent?.completeName,
      address,
      gps,
      alertTime,
      relativeAlertTime,
    }),
    [address, agent, alertTime, gps, relativeAlertTime],
  );

  if (!agent) return null;

  return (
    <CarrierDetailsPopupContext.Provider value={returnValues}>
      {children}
    </CarrierDetailsPopupContext.Provider>
  );
}

export default useCarrierDetailsPopupContext;
