import { ApolloError, useQuery } from '@apollo/client';
import { USER_TYPES } from '~/types';
import QUERY_CURRENT_USER, {
  CurrentUserQueryData,
} from '~/services/api/operations/queries/QueryCurrentUser';
import useAuthenticationContext from '~/context/AuthenticationContext';

export interface CurrentUserType extends Omit<CurrentUserQueryData['me'], '__typename'> {
  type: USER_TYPES;
}

interface UseQueryCurrentUserResponseType {
  currentUser?: CurrentUserType;
  loading: boolean;
  error?: ApolloError;
}

export default function useQueryCurrentUser(): UseQueryCurrentUserResponseType {
  const { isAuthenticated } = useAuthenticationContext();
  const { data, loading, error } = useQuery<CurrentUserQueryData>(QUERY_CURRENT_USER, {
    skip: !isAuthenticated,
  });
  const currentUser = data?.me
    ? {
        ...data.me,
        type: data.me.__typename, // eslint-disable-line no-underscore-dangle
        company: data.me.company || data.me.subsidiary?.company,
      }
    : undefined;

  return { currentUser, loading, error };
}
