import { gql } from '@apollo/client';
import type { SENSOR_QUERY_TYPES, SENSOR_STATUS_QUERY_TYPES } from '~/types';

export interface SubsidiaryMeasurementSubscriptionVariables {
  subsidiaryID: string;
  type: SENSOR_QUERY_TYPES | SENSOR_STATUS_QUERY_TYPES;
}

export interface SubsidiaryMeasurementSubscriptionData {
  measurements: {
    carrier_id: string;
    device_name: string;
    type: string;
    measurements: {
      timestamp: string;
      value: string; // JSON string
    }[];
  };
}

export default gql`
  subscription SubscribeToSubsidiaryMeasurementType($subsidiaryID: ID!, $type: String!) {
    measurements(subsidiary_id: $subsidiaryID, type: $type) {
      carrier_id
      device_name
      type
      measurements {
        timestamp
        value
      }
    }
  }
`;
