import { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/lib/icons/UpOutlined';
import orderBy from 'lodash/orderBy';
import { AgentType } from '~/types';
import { newAlert } from '~/services/api/reactiveVariables/newAlert';
import { playAlarm, stopAlarm, unmuteAlarm } from '~/utils/sounds';
import { getAgentActiveAlarms } from '~/utils/agent';
import i18n from '~/locales/i18n';
import { red, white } from '~/styles/colours';
import { Z_INDEX } from '~/styles/layers';
import useAgentsContext from '~/context/AgentsContext';
import useAppContext from '~/context/AppContext';
import AlertsToggleButton from '~/components/atoms/button/AlertsToggleButton';
import Text from '~/components/atoms/typography/Text';
import AlertsBottomPopupCount from '~/components/atoms/AlertsBottomPopupCount';
import AlertIconWhiteSmall from '~/components/atoms/icon/AlertIconWhiteSmall';
import AlertIconWhiteLarge from '~/components/atoms/icon/AlertIconWhiteLarge';
import AlertsBottomPopupAgentsLink from '~/components/atoms/link/AlertsBottomPopupAgentsLink';
import AlertsBottomPopupTableRow from './AlertsBottomPopupTableRow';

const Container = styled.div`
  width: 100%;
  border: 2px solid ${red};
  bottom: 0;
  z-index: ${Z_INDEX.ALERTS_BOTTOM_POPUP_LEVEL};
  display: grid;
  position: fixed;
  background: ${white};
  grid-template-rows: auto;
  grid-template-columns: 240px 1fr;
`;

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${red};
`;

const RightColumn = styled.div`
  padding: 24px 48px;
  min-height: 124px;
  max-height: 50vh;
  overflow-y: auto;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const ExpandCollapseBtn = styled(AlertsToggleButton)`
  position: absolute;
  top: 0px;
  left: calc(50% + 120px);
  transform: translateX(-50%);
`;

const getSortedAlarmAgents = (agents: AgentType[]) =>
  orderBy(
    agents,
    (agent) => {
      const activeAlarms = getAgentActiveAlarms(agent);

      if (activeAlarms.every((alert) => alert.administrator)) return 0;

      if (activeAlarms.every((alert) => !alert.administrator)) return 2;

      return 1;
    },
    'desc',
  );

export default function AlertsBottomPopup() {
  const [collapsed, setCollapsed] = useState(true);
  const { carrierId } = useReactiveVar(newAlert);
  const { userInteractedWithDocument } = useAppContext();
  const { alertAgents, hasAlert } = useAgentsContext();

  useEffect(() => {
    if (!carrierId) return;

    setCollapsed(false);
    unmuteAlarm();
    // alert has been shown and it is not "new" anymore so we clear the state
    newAlert({});
  }, [carrierId]);

  // TODO: move this to something global, this is not this component's concern
  useEffect(() => (hasAlert ? playAlarm() : stopAlarm()), [hasAlert, userInteractedWithDocument]);

  if (!hasAlert) return null;

  return collapsed ? (
    <Container data-id="collapsed-alert-bottom-popup">
      <LeftColumn>
        <AlertIconWhiteSmall />
        <AlertsBottomPopupCount size="sm" alertsAgentsLength={alertAgents.length} />
        <AlertsBottomPopupAgentsLink size="sm" />
      </LeftColumn>
      <ExpandCollapseBtn
        onClick={() => setCollapsed(!collapsed)}
        data-id="alert-bottom-popup-expand-btn"
      >
        <UpOutlined />
        <Text style={{ paddingLeft: '5px' }}>{i18n.t('alertsBottomPopup.expand')}</Text>
      </ExpandCollapseBtn>
    </Container>
  ) : (
    <Container data-id="alert-bottom-popup">
      <LeftColumn>
        <AlertIconWhiteLarge />
        <AlertsBottomPopupCount size="lg" alertsAgentsLength={alertAgents.length} />
        <AlertsBottomPopupAgentsLink size="lg" />
      </LeftColumn>
      <RightColumn>
        <StyledTable>
          <tbody>
            {getSortedAlarmAgents(alertAgents).map((agent) => (
              <AlertsBottomPopupTableRow
                key={agent.id}
                agent={agent}
                collapse={() => setCollapsed(!collapsed)}
              />
            ))}
          </tbody>
        </StyledTable>
      </RightColumn>
      <ExpandCollapseBtn
        onClick={() => setCollapsed(!collapsed)}
        data-id="alert-bottom-popup-collapse-btn"
      >
        <DownOutlined />
        <Text style={{ paddingLeft: '5px' }}>{i18n.t('alertsBottomPopup.collapse')}</Text>
      </ExpandCollapseBtn>
    </Container>
  );
}
