import { useCallback, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteProps } from '~/types/route';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import routes from '~/navigation/config/routes';
import useAuthenticationContext from '~/context/AuthenticationContext';
import useCurrentUserContext from '~/context/CurrentUserContext';
import AlertsBottomPopup from '~/components/AlertsBottomPopup';
import DashboardOverlay from '~/components/DashboardOverlay';

function PrivateRoute({ element }: RouteProps) {
  const { isAuthenticated, getIdToken, logout, refreshSession } = useAuthenticationContext();
  const currentUser = useCurrentUserContext();
  const tokenExpirationTimeoutIdRef = useRef<number>();

  const handleTokenExpiration = useCallback(async () => {
    window.clearTimeout(tokenExpirationTimeoutIdRef.current);

    if (!isAuthenticated) return;

    const expiresAt = ((await getIdToken())?.getExpiration() || 0) * 1000;
    const expiresIn = expiresAt - Date.now();

    // eslint-disable-next-line no-console
    console.log(
      `Expired token handler set up at ${new Date()}`,
      expiresAt,
      new Date(expiresAt),
      expiresIn,
    );

    if (expiresAt === 0) return;

    const handleToken = async () => {
      const latestExpiresAt = ((await getIdToken())?.getExpiration() || 0) * 1000;
      const latestExpiresIn = latestExpiresAt - Date.now();

      // eslint-disable-next-line no-console
      console.log(
        `Expired token handler called at ${new Date()}`,
        new Date(expiresAt),
        new Date(latestExpiresAt),
      );

      if (latestExpiresAt !== 0 && latestExpiresIn > expiresIn) {
        tokenExpirationTimeoutIdRef.current = window.setTimeout(handleToken, latestExpiresIn);
      } else {
        try {
          // eslint-disable-next-line no-console
          console.log('refreshing session');
          const freshSession = await refreshSession();
          if (freshSession?.isValid()) {
            console.log('Token successfully refreshed'); // eslint-disable-line no-console
            handleTokenExpiration();
          } else {
            throw new Error('Refreshed session not valid');
          }
        } catch (error) {
          console.log('Refreshing token failed, logging user out', error); // eslint-disable-line no-console
          browserStorage.session.set(BROWSER_STORAGE_KEY.TOKEN_EXPIRED, true);
          await logout();
        }
      }
    };

    tokenExpirationTimeoutIdRef.current = window.setTimeout(handleToken, expiresIn);
  }, [isAuthenticated, getIdToken, logout, refreshSession]);

  useEffect(
    () => () => {
      window.clearTimeout(tokenExpirationTimeoutIdRef.current);
    },
    [],
  );

  useEffect(() => {
    handleTokenExpiration();
  }, [isAuthenticated, handleTokenExpiration]);

  // waiting for currentUser to be fetched after user authentication
  if (isAuthenticated && !currentUser?.id) return null;

  return isAuthenticated ? (
    <>
      {element}
      <AlertsBottomPopup />
      <DashboardOverlay />
    </>
  ) : (
    <Navigate to={routes.default()} />
  );
}

export default PrivateRoute;
