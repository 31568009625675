import camelCase from 'lodash/camelCase';
import i18n from '~/locales/i18n';
import { EQUIPMENT_STATUSES, EquipmentStatusDetails } from '~/types';

export const computeStatusLabel = (equipment?: EquipmentStatusDetails) => {
  if (!equipment?.status || equipment.status === EQUIPMENT_STATUSES.NO_ERROR) return '';

  const translationKey = `equipment.status.${camelCase(equipment.status)}`;
  const translation = i18n.t(translationKey);

  return translation === translationKey ? equipment.status : translation;
};
