import { RefObject, useEffect, useMemo, useRef } from 'react';
import { IvsStream, STREAM_STATE } from '~/types/video';
import notification from '~/utils/notification';

const MAXIMUM_RELOAD_ATTEMPTS = 40;
const AMAZON_IVS_PLAYER_PATH = 'https://player.live-video.net/1.8.0/amazon-ivs-player.min.js';
// const DEFAULT_VIDEO_STREAM_URL =
//   'https://3d26876b73d7.us-west-2.playback.live-video.net/api/video/v1/us-west-2.913157848533.channel.xJ2tVekwmMGd.m3u8';
const DEFAULT_PLAYER_AUTOPLAY = true;
const DEFAULT_PLAYER_MUTED = true;
const DEFAULT_PLAYER_VOLUME = 1;
const DEFAULT_REBUFFER_TO_LIVE = true;

function buildStreamUrl(stream?: IvsStream) {
  return `${stream?.playback_url}?token=${stream?.playback_key}`;
}

function isStreamReady(stream?: IvsStream) {
  return (
    stream &&
    stream.ivs_stream_state === STREAM_STATE.START &&
    stream.playback_url &&
    stream.playback_key
  );
}

function useLoadIvsPlayer(stream?: IvsStream): {
  videoElement: RefObject<HTMLVideoElement>;
} {
  const videoElement = useRef<HTMLVideoElement>(null);
  const streamUrl = useMemo(() => buildStreamUrl(stream), [stream]);
  const player = useRef<any>(null);
  const reloadAttempts = useRef<number>(0);
  const { IVSPlayer } = window;

  useEffect(() => {
    if (IVSPlayer) return;

    const mediaPlayerScript = document.createElement('script');
    mediaPlayerScript.src = AMAZON_IVS_PLAYER_PATH;
    mediaPlayerScript.async = true;
    document.body.appendChild(mediaPlayerScript);
  }, [IVSPlayer]);

  useEffect(() => {
    if (!isStreamReady(stream) || !IVSPlayer || player.current) {
      return undefined;
    }

    // First, check if the browser supports the Amazon IVS player.
    if (!IVSPlayer.isPlayerSupported) {
      notification.warning({
        message: 'Player not supported',
        description: 'The current browser does not support the Amazon IVS player.',
      });
      return undefined;
    }

    // Initialize player
    player.current = IVSPlayer.create();
    player.current.attachHTMLVideoElement(videoElement.current);

    const {
      PlayerEventType: { ERROR },
    } = IVSPlayer;

    const handleErrorEvent = (error: Error) => {
      if (reloadAttempts.current < MAXIMUM_RELOAD_ATTEMPTS) {
        reloadAttempts.current += 1;
        setTimeout(() => {
          player.current?.load(streamUrl);
        }, 1000);
      } else {
        notification.error({
          message: 'Player error',
          description: error.message,
        });
      }
    };

    // Attach event listeners
    player.current.addEventListener(ERROR, handleErrorEvent);

    // Setup stream default settings
    player.current.setRebufferToLive(DEFAULT_REBUFFER_TO_LIVE);
    player.current.setAutoplay(DEFAULT_PLAYER_AUTOPLAY);
    player.current.setVolume(DEFAULT_PLAYER_VOLUME);
    player.current.setMuted(DEFAULT_PLAYER_MUTED);

    // Debugging tool
    // player.current.setLogLevel(IVSPlayer.LogLevel.DEBUG);

    // Attempt to load the stream
    player.current.load(streamUrl);

    // Unsubscribing on component unload
    return (): void => {
      player.current.removeEventListener(ERROR, handleErrorEvent);
      player.current.delete();
      player.current = undefined;
    };
  }, [IVSPlayer, stream, streamUrl]);

  return { videoElement };
}

export interface VideoPlayerProps {
  stream?: IvsStream;
}

export default function VideoPlayer({ stream }: VideoPlayerProps) {
  const { videoElement } = useLoadIvsPlayer(stream);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video width="100%" ref={videoElement} playsInline controls autoPlay data-id="video" />
  );
}
