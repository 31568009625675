import { useQuery, useReactiveVar } from '@apollo/client';
import { useState } from 'react';
import useInterval from 'react-use/lib/useInterval';
import QUERY_ME, { MeQueryData } from '~/services/api/operations/queries/QueryMe';
import { reconnectTimestamp } from '~/services/api/reactiveVariables/reconnectTimestamp';

export interface ConnectionStatus {
  navigatorOnline: boolean;
  lastOnlineTimestamp: number;
  lastCheckTimestamp: number;
  appsyncReconnectedTimestamp: number;
}

const ONLINE_STATUS_CHECK_PERIOD_MS = 2000;
const APPSYNC_STATUS_CHECK_PERIOD_MS = 10000;

export default function useConnectionStatus(): ConnectionStatus {
  const [navigatorOnline, setNavigatorOnline] = useState(true);
  const [lastCheckTimestamp, setLastCheckTimestamp] = useState(0);
  const [lastOnlineTimestamp, setLastOnlineTimestamp] = useState(0);
  const [appsyncReconnectedTimestamp, setAppsyncReconnectedTimestamp] = useState(0);
  const [appsyncOnline, setAppsyncOnline] = useState(true);
  const reconnectTimestampValue = useReactiveVar(reconnectTimestamp);

  useInterval(() => {
    // A simple check for online / offline status.
    // It does not provice guarantee that the server is reachable.
    // It doesn't even guarantee that connection to internet works.
    // It only says there is some network connectivity if onLine is true.
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
    const online = window.navigator.onLine;
    const now = Date.now();

    if (online) setLastOnlineTimestamp(now);

    setLastCheckTimestamp(now);
    setNavigatorOnline(online);
  }, ONLINE_STATUS_CHECK_PERIOD_MS);

  const { error, refetch } = useQuery<MeQueryData>(QUERY_ME, { fetchPolicy: 'network-only' });

  useInterval(() => {
    // eslint-disable-next-line no-console
    console.log('testing appsync connection', new Date().toISOString());
    refetch();

    if (error) {
      console.log('connection error'); // eslint-disable-line no-console
      setAppsyncOnline(false);
    } else if (!appsyncOnline) {
      console.log('reconnected'); // eslint-disable-line no-console
      setAppsyncOnline(true);

      const now = Date.now();

      setAppsyncReconnectedTimestamp(now);

      if (now !== reconnectTimestampValue) reconnectTimestamp(now);
    }
  }, APPSYNC_STATUS_CHECK_PERIOD_MS);

  return {
    navigatorOnline,
    lastCheckTimestamp,
    lastOnlineTimestamp,
    appsyncReconnectedTimestamp,
  };
}
