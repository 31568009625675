import { AllAlarms, AlarmType, ALARM_TYPES } from '~/types';
import i18n from '~/locales/i18n';
import { formatRelativeFromNow, formatTime } from '~/utils/dateTime';

export function computeAlarmMessage(alarm?: AlarmType): string {
  switch (alarm?.type) {
    case ALARM_TYPES.FALL:
      return i18n.t('general.generalAlarms.fall');
    case ALARM_TYPES.EMERGENCY:
      return i18n.t('general.generalAlarms.emergency');
    case ALARM_TYPES.ATTACK:
      return i18n.t('general.generalAlarms.attack');
    case ALARM_TYPES.TRAAK_FRONT:
      return i18n.t('general.generalAlarms.traakFront');
    case ALARM_TYPES.TRAAK_BACK:
      return i18n.t('general.generalAlarms.traakBack');
    case ALARM_TYPES.GAS_CH4HC:
      return i18n.t('general.generalAlarms.gasCH4HC', { level: alarm.value });
    case ALARM_TYPES.GAS_CO:
      return i18n.t('general.generalAlarms.gasCO', { level: alarm.value });
    case ALARM_TYPES.GAS_CO2:
      return i18n.t('general.generalAlarms.gasCO2', { level: alarm.value });
    case ALARM_TYPES.GAS_H2S:
      return i18n.t('general.generalAlarms.gasH2S', { level: alarm.value });
    case ALARM_TYPES.GAS_O2:
      return i18n.t('general.generalAlarms.gasO2', { level: alarm.value });
    default:
      return '';
  }
}

interface ComputeAlarmStartingTimeReturnType {
  alertTime: string | false;
  relativeAlertTime: string | false;
}

export const defaultAlarmStaringTime: ComputeAlarmStartingTimeReturnType = Object.seal({
  alertTime: false,
  relativeAlertTime: false,
});

export function computeAlarmStartingTime(
  alarms: AllAlarms | undefined,
): ComputeAlarmStartingTimeReturnType {
  if (!alarms) return defaultAlarmStaringTime;
  const { falls, emergencies, attacks, traakFront, traakBack } = alarms;
  const fallTime = falls && !falls[0]?.dismissed_at && falls[0]?.created_at;
  const emergencyTime = emergencies && !emergencies[0]?.dismissed_at && emergencies[0]?.created_at;
  const attackTime = attacks && !attacks[0]?.dismissed_at && attacks[0]?.created_at;
  const traakFrontTime =
    traakFront?.[0] && !traakFront[0]?.dismissed_at && traakFront[0]?.created_at;
  const traakBackTime = traakBack?.[0] && !traakBack[0]?.dismissed_at && traakBack[0]?.created_at;

  const alertTime = fallTime || emergencyTime || attackTime || traakFrontTime || traakBackTime;
  const alertTimeFormatted = alertTime ? formatTime(alertTime, 'readable') : false;
  const relativeAlertTime = alertTime ? formatRelativeFromNow(alertTime) : false;

  return {
    alertTime: alertTimeFormatted,
    relativeAlertTime,
  };
}
