import type { TextProps } from 'antd/lib/typography/Text';
import Text from 'antd/lib/typography/Text';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import { red } from '~/styles/colours';
import FullWidth from '~/components/atoms/FullWidth';
import CarrierDetailsPopupInfoTypeItemLabelSpace from '~/components/atoms/space/CarrierDetailsPopupInfoTypeItemLabelSpace';
import i18n from '~/locales/i18n';
import { AlarmType } from '~/types/alarm';
import useCurrentUserContext from '~/context/CurrentUserContext';
import ManageAlertModal from './ManageAlertModal';

const BoldRedText = styled(Text)`
  color: ${red};
  font-weight: 500;
  white-space: normal;
`;

const BoldRedClickableText = styled(BoldRedText)`
  text-decoration: underline;
  cursor: pointer;
`;

const AlarmAdminSpan = styled.span`
  text-align: right;
  margin-left: 8px;
`;

interface Props extends TextProps {
  labelText: string;
  alertTime: string | false;
  alarm: AlarmType;
}

export default function CarrierDetailsPopupInfoTypeAlertItem({
  labelText,
  alertTime,
  alarm,
  ...props
}: Props) {
  const [modalVisibility, toggleModalVisibility] = useToggle(false);
  const currentUser = useCurrentUserContext();

  return (
    <>
      <FullWidth style={{ gap: '8px' }}>
        <CarrierDetailsPopupInfoTypeItemLabelSpace style={{ whiteSpace: 'nowrap' }}>
          <BoldRedText>{labelText}</BoldRedText>
          <BoldRedText>{alertTime}</BoldRedText>
        </CarrierDetailsPopupInfoTypeItemLabelSpace>
        {alarm.administrator && alarm.administrator.id !== currentUser?.id ? (
          <AlarmAdminSpan>
            {i18n.t('general.generalAlarms.alarmAssigned', { name: alarm.administrator.email })}
          </AlarmAdminSpan>
        ) : (
          <BoldRedClickableText onClick={toggleModalVisibility} {...props}>
            {i18n.t('carrierDetailsPopup.manageAlert')}
          </BoldRedClickableText>
        )}
      </FullWidth>
      <ManageAlertModal
        visible={modalVisibility}
        toggleVisibility={toggleModalVisibility}
        alarm={alarm}
      />
    </>
  );
}
