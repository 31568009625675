import i18n from '~/locales/i18n';
import { GAS_MEASUREMENT_TYPES } from '~/types/measurements';
import { GAS_SENSOR_TYPES } from '~/types/sensor';
import { numberToString } from '~/utils/parse';
import { red } from '~/styles/colours';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import CarrierDetailsPopupInfoTypeItem2List from '~/components/DetailsPopup/content/list/CarrierDetailsPopupInfoTypeItem2List';

export default function CarrierDetailsPopupInfoTypeGasSensorItemList() {
  const { agent } = useCarrierDetailsPopupContext();
  const gas = agent?.equipmentStatus.gas.healthy ? agent?.sensors?.gas : undefined;
  const list = [
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPES.CO}`),
      valueText: numberToString(gas?.[GAS_SENSOR_TYPES.CO], GAS_MEASUREMENT_TYPES.PPM),
      style: agent?.alarms?.gasCO?.[0]?.value ? { color: red } : undefined,
    },
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPES.CO2}`),
      valueText: numberToString(gas?.[GAS_SENSOR_TYPES.CO2], GAS_MEASUREMENT_TYPES.PPM),
      style: agent?.alarms?.gasCO2?.[0]?.value ? { color: red } : undefined,
    },
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPES.H2S}`),
      valueText: numberToString(gas?.[GAS_SENSOR_TYPES.H2S], GAS_MEASUREMENT_TYPES.PPM),
      style: agent?.alarms?.gasH2S?.[0]?.value ? { color: red } : undefined,
    },
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPES.CH4_HC}`),
      valueText: numberToString(gas?.[GAS_SENSOR_TYPES.CH4_HC], GAS_MEASUREMENT_TYPES.PERCENT_LEL),
      style: agent?.alarms?.gasCH4HC?.[0]?.value ? { color: red } : undefined,
    },
    {
      labelText: i18n.t(`carrierDetailsPopup.gasSensors.${GAS_SENSOR_TYPES.O2}`),
      valueText: numberToString(gas?.[GAS_SENSOR_TYPES.O2], GAS_MEASUREMENT_TYPES.PERCENT),
      style: agent?.alarms?.gasO2?.[0]?.value ? { color: red } : undefined,
    },
  ];

  return <CarrierDetailsPopupInfoTypeItem2List elements={list} />;
}
