import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Spin, FormProps } from 'antd';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import CenteredFormItem from '~/components/atoms/form/CenteredFormItem';
import ConfirmationCodeForm from '~/components/atoms/form/ConfirmationCodeForm';
import AuthButton from '~/components/atoms/button/AuthButton';
import WearinLogoBlack from '~/components/atoms/logo/WearinLogoBlack';
import Input from '~/components/atoms/input/Input';
import Title from '~/components/atoms/typography/Title';
import Text from '~/components/atoms/typography/Text';
import useQueryParams from '~/hooks/useQueryParams';
import useAuthenticationContext from '~/context/AuthenticationContext';
import { CustomURLSearchParams } from '~/types/route';
import notification from '~/utils/notification';
import routes from '~/navigation/config/routes';
import i18n from '~/locales/i18n';

export default function VerifyConfirmationCodePage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState<string>('');
  const params: CustomURLSearchParams = useQueryParams();
  const { verifyConfirmationCode } = useAuthenticationContext();
  const navigate = useNavigate();
  const error = '';

  const handleOpenNotification = (type: string, title: string, message: string): void => {
    switch (type) {
      case 'success':
        notification.success({
          message: title,
          description: message,
        });

        navigate(routes.resetPassword({ ...params, confirmationCode }));
        break;

      case 'error':
        notification.error({
          message: title,
          description: message,
        });
        break;

      default:
        break;
    }
  };

  const handleOnPaste = (event: React.ClipboardEvent) => {
    event.preventDefault();

    const code = event.clipboardData.getData('Text').trim();

    /** Update input */
    setConfirmationCode(code);

    // Checks if string is numbers only
    const reg = /^[0-9]+$/;

    // Checks if the code is a valid number
    if (reg.test(code) && code.length === 6) {
      setLoading(true);
      verifyConfirmationCode({
        confirmationCode: code,
      })
        .then(() => {
          handleOpenNotification(
            'success',
            i18n.t('confirmationCode.successTitle'),
            i18n.t('confirmationCode.successMessage'),
          );
        })
        .catch((catchedError: Error) => {
          handleOpenNotification(
            'error',
            i18n.t('confirmationCode.errorMessage'),
            catchedError.message,
          );
          setLoading(false);
        });
    }
  };

  const handleFinish = () => {
    setLoading(true);
    verifyConfirmationCode({
      confirmationCode,
    })
      .then(() => {
        handleOpenNotification(
          'success',
          i18n.t('confirmationCode.successTitle'),
          i18n.t('confirmationCode.successMessage'),
        );
      })
      .catch((catchedError: Error) => {
        handleOpenNotification(
          'error',
          i18n.t('confirmationCode.errorMessage'),
          catchedError.message,
        );
        setLoading(false);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(event.currentTarget.value);
  };

  return (
    <ConfirmationCodeForm<FC<FormProps<never>>> onFinish={handleFinish}>
      <Col md={24} lg={18}>
        <CenteredFormItem>
          <WearinLogoBlack />
        </CenteredFormItem>

        <CenteredFormItem>
          <Title level={4}>{i18n.t('confirmationCode.checkYourEmailTitle')}</Title>
          <Text>{i18n.t('confirmationCode.checkYourEmailText')}</Text>
        </CenteredFormItem>

        <CenteredFormItem
          validateStatus={error && 'error'}
          help={error}
          label={i18n.t('confirmationCode.confirmationCodePlaceholder')}
        >
          <Input
            size="large"
            type="number"
            placeholder={i18n.t<string>('confirmationCode.confirmationCodePlaceholder')}
            onChange={handleChange}
            onPaste={handleOnPaste}
            value={confirmationCode}
          />
        </CenteredFormItem>
      </Col>
      <Col md={24} lg={12}>
        <AuthButton disabled={loading} size="large">
          {loading ? (
            <Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />} />
          ) : (
            i18n.t('confirmationCode.submitButton')
          )}
        </AuthButton>
      </Col>
    </ConfirmationCodeForm>
  );
}
