import i18n from '~/locales/i18n';
import { numberToString } from '~/utils/parse';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import CarrierDetailsPopupInfoTypeItem2List from '~/components/DetailsPopup/content/list/CarrierDetailsPopupInfoTypeItem2List';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';

export default function CarrierDetailsPopupInfoTypePhysiologicalSensorItemList() {
  const { agent } = useCarrierDetailsPopupContext();
  const companyFeatures = useCompanyFeatures();

  const heartRate = agent?.equipmentStatus.heartRate.status ? agent?.sensors?.heartRate : undefined;
  const bodyTemperature = agent?.equipmentStatus.bodyTemperature.status
    ? agent?.sensors?.bodyTemperature
    : undefined;
  const list = [
    ...(companyFeatures.heartRateSensor
      ? [
          {
            labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.heartRate'),
            valueText: numberToString(heartRate, 'BPM'),
          },
        ]
      : []),
    ...(companyFeatures.bodyTemperatureSensor
      ? [
          {
            labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.bodyTemperature'),
            valueText: numberToString(bodyTemperature, '\u00B0C'),
          },
        ]
      : []),
  ];

  return <CarrierDetailsPopupInfoTypeItem2List elements={list} />;
}
