import { gql } from '@apollo/client';

export interface CarrierSubscriptionVariables {
  subsidiaryID: string;
}

export interface CarrierSubscriptionData {
  carrier: {
    carrier: {
      id: string;
      name: string;
      device: {
        name: string;
        attributes: {
          name: string;
          value: string;
          __typename?: 'Attribute';
        }[];
      };
      attributes: {
        name: string;
        value: string;
        __typename?: 'Attribute';
      }[];
      videoStream?: unknown; // TODO: Should we delete this?
    };
  };
}

export default gql`
  subscription SubscribeToCarrier($subsidiaryID: ID!) {
    carrier(subsidiary_id: $subsidiaryID) {
      carrier {
        id
        name
        device {
          name
          attributes {
            name
            value
          }
        }
        attributes {
          name
          value
        }
      }
    }
  }
`;
