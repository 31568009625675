import { useState } from 'react';
import styled from 'styled-components';
import Form from 'antd/lib/form';
import Title from 'antd/lib/typography/Title';
import i18n from '~/locales/i18n';
import notification from '~/utils/notification';
import useAuthenticationContext from '~/context/AuthenticationContext';
import useCurrentUserContext from '~/context/CurrentUserContext';
import Button from '~/components/atoms/button/Button';
import InputPassword from '~/components/atoms/input/InputPassword';
import LoadingSpin from '~/components/atoms/LoadingSpin';
import PasswordPolicyPopover from '~/components/atoms/PasswordPolicyPopover';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

type FormValues = typeof initialValues;

const StyledForm = styled(Form<FormValues>)`
  max-width: 500px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 24px !important;
`;

function ChangePasswordForm() {
  const currentUser = useCurrentUserContext();
  const { changePassword } = useAuthenticationContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();

  if (!currentUser) return null;

  const compareToFirstPassword = (rule: object, value: string) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      return Promise.reject(Error(i18n.t<string>('changePassword.passwordsMismatch')));
    }

    return Promise.resolve();
  };

  const handleSubmit = (values: FormValues) => {
    setLoading(true);
    changePassword(values.oldPassword.trim(), values.newPassword.trim())
      .then(() => {
        notification.success({
          message: i18n.t('changePassword.successTitle'),
          description: i18n.t('changePassword.successDescription'),
        });
      })
      .catch((catchedError: Error) => {
        notification.error({
          message: i18n.t('changePassword.failTitle'),
          description: catchedError.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <StyledTitle level={4}>{i18n.t('changePassword.title')}</StyledTitle>
      <StyledForm
        form={form}
        layout="vertical"
        initialValues={initialValues}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <StyledForm.Item
          name="oldPassword"
          rules={[{ required: true, message: i18n.t<string>('changePassword.invalidOldPassword') }]}
        >
          <InputPassword size="large" placeholder={i18n.t<string>('changePassword.oldPassword')} />
        </StyledForm.Item>
        <PasswordPolicyPopover>
          <StyledForm.Item
            name="newPassword"
            rules={[
              { required: true, message: i18n.t<string>('changePassword.invalidNewPassword') },
            ]}
          >
            <InputPassword
              size="large"
              placeholder={i18n.t<string>('changePassword.newPassword')}
            />
          </StyledForm.Item>
        </PasswordPolicyPopover>
        <StyledForm.Item
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: i18n.t<string>('changePassword.invalidConfirmPassword'),
            },
            { validator: compareToFirstPassword },
          ]}
        >
          <InputPassword
            size="large"
            placeholder={i18n.t<string>('changePassword.confirmPassword')}
          />
        </StyledForm.Item>
        <div style={{ textAlign: 'right' }}>
          <Button size="large" type="primary" htmlType="submit" disabled={isLoading}>
            {isLoading ? <LoadingSpin /> : i18n.t('general.forms.save')}
          </Button>
        </div>
      </StyledForm>
    </>
  );
}

export default ChangePasswordForm;
