export enum ALARM_TYPES {
  FALL = 'fall',
  EMERGENCY = 'emergency',
  ATTACK = 'attack',
  TRAAK_FRONT = 'traak_front',
  TRAAK_BACK = 'traak_back',
  GAS_CH4HC = 'gasCH4HC',
  GAS_CO = 'gasCO',
  GAS_CO2 = 'gasCO2',
  GAS_H2S = 'gasH2S',
  GAS_O2 = 'gasO2',
}

export enum AGENT_STATUS {
  ALERT = 'alert',
  CONNECTION_LOST = 'connectionLost',
  WARNING = 'warning',
  IN_MISSION = 'inMission',
  IN_SAFE_ZONE = 'inSafeZone',
}

export interface AlarmType {
  id: string;
  dismissed_at: string | null;
  dismiss_requested_at: string | null;
  dismiss_reason: string | null;
  undismissable?: boolean;
  type: ALARM_TYPES;
  created_at: string;
  value: string | number;
  administrator?: { id: string; email: string };
}

export interface AllAlarms {
  falls: AlarmType[];
  attacks: AlarmType[];
  emergencies: AlarmType[];
  traakFront: AlarmType[];
  traakBack: AlarmType[];
  gasCH4HC: AlarmType[];
  gasCO: AlarmType[];
  gasCO2: AlarmType[];
  gasH2S: AlarmType[];
  gasO2: AlarmType[];
}

export const ALARM_ITEMS_QUERY_BODY = `{
  items {
    id
    dismissed_at
    dismiss_requested_at
    dismiss_reason
    type
    value
    created_at
    administrator {
      id
      email
      ... on SubsidiaryAdmin {
        id
        email
      }
      ... on CompanyAdmin {
        id
        email
      }
    }
  }
}`;
