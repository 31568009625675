import { createContext, useContext, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import useQueryCurrentUser, { CurrentUserType } from '~/services/api/apis/useQueryCurrentUser';
import notification from '~/utils/notification';

const CurrentUserContext = createContext<CurrentUserType | undefined>(undefined);

const useCurrentUserContext = () => useContext(CurrentUserContext);

interface CurrentUserContextProviderProps {
  children: React.ReactNode;
}

export function CurrentUserContextProvider({ children }: CurrentUserContextProviderProps) {
  const { currentUser, error } = useQueryCurrentUser();

  useEffect(() => {
    if (currentUser?.id && datadogRum.getUser()?.id !== currentUser?.id) {
      datadogRum.setUser({ id: currentUser.id });
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error.name,
        description: error.message,
        placement: 'top',
      });
    }
  }, [error]);

  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>;
}

export default useCurrentUserContext;
