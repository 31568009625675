import { useCallback, useEffect, useRef, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import TeamOutlined from '@ant-design/icons/lib/icons/TeamOutlined';
import { AGENT_STATUS } from '~/types';
import config from '~/config/config';
import { CARRIER_MAP_ZOOM_LEVEL } from '~/config/defaults';
import i18n from '~/locales/i18n';
import { NAVBAR_HEIGHT } from '~/styles/dimensions';
import useMarkers from './useMarkers';
import {
  computeAnchorPoint,
  computeIcon,
  getMarkerClusterRender,
  useDeepCompareEffectForMaps,
} from './utils';
import { whiteGreyStyles } from './styles';

const MapDiv = styled.div`
  flex-grow: 1;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
`;

const FitBoundsBtn = styled(TeamOutlined)`
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-size: 18px !important;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.14902);
  min-width: 32px;
  min-height: 32px;
  position: fixed;
  top: 192px;
  right: 8px;
`;

const agentStatuses = [
  AGENT_STATUS.IN_SAFE_ZONE,
  AGENT_STATUS.IN_MISSION,
  AGENT_STATUS.WARNING,
  AGENT_STATUS.CONNECTION_LOST,
  AGENT_STATUS.ALERT,
];

function Map(options: google.maps.MapOptions) {
  const markers = useMarkers();
  const markerClusterersRef = useRef<MarkerClusterer[]>([]);
  const mapMarkersRef = useRef<google.maps.Marker[]>([]);
  const infoWindowRef = useRef(new google.maps.InfoWindow());
  const mapDivRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (!mapDivRef.current || map) return;

    const nextMap = new window.google.maps.Map(mapDivRef.current, {
      styles: whiteGreyStyles,
      fullscreenControl: false,
      mapTypeControlOptions: { position: window.google.maps.ControlPosition.TOP_RIGHT },
      zoomControlOptions: { position: window.google.maps.ControlPosition.RIGHT_TOP },
      controlSize: 32,
      streetViewControl: false,
    });

    markerClusterersRef.current = agentStatuses.map(
      (status) =>
        new MarkerClusterer({
          map: nextMap,
          markers: [],
          renderer: { render: getMarkerClusterRender(status) },
          algorithm: new SuperClusterAlgorithm({ maxZoom: CARRIER_MAP_ZOOM_LEVEL - 1 }),
        }),
    );

    setMap(nextMap);
  }, [map]);

  // because React does not do deep comparisons, a custom hook is used
  // https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) map.setOptions(options);
  }, [map, options]);

  const handleMapFitBounds = useCallback(() => {
    if (!map || !markers?.length) return;

    const bounds = new window.google.maps.LatLngBounds();

    markers.forEach(({ position }) => {
      bounds.extend({ lat: position.lat, lng: position.lng });
    });
    map.fitBounds(bounds);
  }, [map, markers]);

  useEffect(
    () => () => {
      markerClusterersRef.current.forEach((clusterer) => {
        clusterer.clearMarkers();
        clusterer.setMap(null);
      });
      mapMarkersRef.current.forEach((marker) => {
        google.maps.event.clearInstanceListeners(marker);
        marker.setMap(null);
      });

      markerClusterersRef.current = [];
      mapMarkersRef.current = [];
    },
    [],
  );

  useEffect(() => {
    if (map) {
      mapMarkersRef.current = [];

      agentStatuses.forEach((agentStatus, index) => {
        const mapMarkers = markers
          .filter(({ status }) => status === agentStatus)
          .map(({ id, type, label, status, isHighlighted, position, zIndex, tooltip, onClick }) => {
            const mapMarker: google.maps.Marker = new google.maps.Marker({
              map,
              draggable: false,
              icon: computeIcon(type, status, isHighlighted),
              label: { text: label, color: 'white', fontWeight: '600' },
              position,
              anchorPoint: computeAnchorPoint(type, status, isHighlighted),
              zIndex,
            });

            if (onClick) mapMarker.addListener('click', () => onClick(id));

            mapMarker.addListener('mouseover', () => {
              infoWindowRef.current.setContent(tooltip);
              infoWindowRef.current.open({ map, anchor: mapMarker, shouldFocus: false });
            });
            mapMarker.addListener('mouseout', () => {
              infoWindowRef.current.close();
            });

            return mapMarker;
          });

        mapMarkersRef.current = [...mapMarkersRef.current, ...mapMarkers];
        markerClusterersRef.current[index]?.addMarkers(mapMarkers);
      });
    }

    return () => {
      markerClusterersRef.current.forEach((clusterer) => {
        clusterer.clearMarkers();
      });
      mapMarkersRef.current.forEach((marker) => {
        google.maps.event.clearInstanceListeners(marker);
        marker.setMap(null);
      });

      mapMarkersRef.current = [];
    };
  }, [map, markers]);

  return (
    <>
      <MapDiv ref={mapDivRef} data-id="map" />
      <Tooltip placement="left" title={i18n.t('map.recenter')}>
        <FitBoundsBtn onClick={handleMapFitBounds} />
      </Tooltip>
    </>
  );
}

export default function MapWrapper(props: google.maps.MapOptions) {
  return (
    <Wrapper apiKey={config.googleMapsApiKey}>
      <Map {...props} />
    </Wrapper>
  );
}
