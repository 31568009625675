import { useMemo } from 'react';
import useQueryParams, { QueryParams } from '~/hooks/useQueryParams';
import { DETAILS_POPUP_TYPE } from '~/types/popup';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import { CarrierDetailsPopupContextProvider } from './CarrierDetailsPopupContext';
import DefaultCarrierDetailsPopup from './DefaultCarrierDetailsPopup';
import SosCarrierDetailsInSVDPopup from './SosCarrierDetailsInSVDPopup';
import SosCarrierDetailsPopup from './SosCarrierDetailsPopup';

export interface CarrierDetailsPopupProps {
  carrierID: string;
}

export default function CarrierDetailsPopup({ carrierID }: CarrierDetailsPopupProps) {
  const { type }: QueryParams = useQueryParams();
  const features = useCompanyFeatures();

  const carrierDetailsPopup = useMemo(() => {
    if (features.vehicles) {
      return type === DETAILS_POPUP_TYPE.VEHICLE ? (
        <SosCarrierDetailsInSVDPopup />
      ) : (
        <SosCarrierDetailsPopup />
      );
    }

    return <DefaultCarrierDetailsPopup />;
  }, [type, features.vehicles]);

  if (!carrierID) return null;

  return (
    <CarrierDetailsPopupContextProvider carrierID={carrierID}>
      {carrierDetailsPopup}
    </CarrierDetailsPopupContextProvider>
  );
}
