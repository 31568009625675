import { createGraphiQLFetcher, Fetcher } from '@graphiql/toolkit';
import { GraphiQL } from 'graphiql';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'graphiql/graphiql.css';
import useAuthenticationContext from '~/context/AuthenticationContext';
import config from '~/config/config';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import { NAVBAR_HEIGHT } from '~/styles/dimensions';

const Div = styled('div')`
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
`;

export default function DeveloperToolsPage() {
  const [fetcher, setFetcher] = useState<{ fn?: Fetcher }>({});
  const { getIdToken, isAuthenticated } = useAuthenticationContext();
  const features = useCompanyFeatures();

  const createFetcher = useCallback(async () => {
    if (getIdToken) {
      const authToken = await getIdToken();
      if (authToken?.jwtToken) {
        const graphiQLFetcher = createGraphiQLFetcher({
          url: config.awsConfig.aws_appsync_graphqlEndpoint,
          headers: {
            authorization: authToken.jwtToken,
          },
        });
        setFetcher({ fn: graphiQLFetcher });
      }
    }
  }, [getIdToken]);

  useEffect(() => {
    if (isAuthenticated) {
      createFetcher();
    }
  }, [createFetcher, isAuthenticated]);

  if (!fetcher?.fn || !features.graphiql) {
    return null;
  }

  return (
    <Div>
      <GraphiQL fetcher={fetcher.fn} />
    </Div>
  );
}
