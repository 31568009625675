import { gql } from '@apollo/client';

export interface AssociateAlarmAdminMutationVariables {
  alarmId: string;
  adminId: string;
}

export interface AssociateAlarmAdminMutationData {
  associateAlarmAdministrator: {
    __typename: 'Alarm';
    id: string;
    administrator: {
      __typename: 'CompanyAdmin' | 'SubsidiaryAdmin';
      id: string;
      email: string;
    };
  };
}

export default gql`
  mutation MutationAssociateAlarmAdmin($alarmId: ID!, $adminId: ID!) {
    associateAlarmAdministrator(alarm_id: $alarmId, administrator_id: $adminId) {
      id
      administrator {
        id
        email
      }
    }
  }
`;
