import { useState, useCallback } from 'react';
import styled from 'styled-components';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import i18n from '~/locales/i18n';
import { AgentType } from '~/types/agent';
import AgentsTable from '~/components/AgentsTable';
import Button from '~/components/atoms/button/Button';
import Modal from '~/components/atoms/Modal';
import useSubsidiary from '~/hooks/useSubsidiary';
import SectionHeader from '~/components/atoms/typography/SectionHeader';
import AgentForm, { AgentFormValues, getEmptyAgent } from '~/components/form/AgentForm';
import AgentDeleteForm from '~/components/form/AgentDeleteForm';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 16px;
`;

const StyledAgentForm = styled(AgentForm)`
  > div:first-child {
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 8px;
  }
`;

export default function AgentsPage() {
  const [formEditAgent, setFormEditAgent] = useState<Partial<AgentFormValues>>();
  const [formDeleteAgent, setFormDeleteAgent] = useState<AgentType>();
  const { currentSubsidiaryIdentifier } = useSubsidiary();

  const handleAgentEdit = useCallback(
    (agent: AgentType) =>
      setFormEditAgent({
        key: Date.now().toString(),
        id: agent.id,
        name: agent.name || '',
        first_name: agent.attributes?.first_name || '',
        last_name: agent.attributes?.last_name || '',
        phone_number: agent.attributes?.phone_number || '',
        team: agent.attributes?.team || '',
        age: agent.attributes?.age || '',
        gender: agent.attributes?.gender || 'unknown',
      }),
    [],
  );

  const handleDeleteAgent = useCallback((agent: AgentType) => {
    setFormDeleteAgent(agent);
  }, []);

  const clearForm = useCallback(() => {
    setFormEditAgent(undefined);
    setFormDeleteAgent(undefined);
  }, []);

  return (
    <>
      <Modal
        title={
          formEditAgent
            ? i18n.t(`agentForm.${formEditAgent?.name ? 'edit' : 'add'}Agent`)
            : i18n.t('agentForm.deleteAgent')
        }
        footer={null}
        centered
        width={500}
        open={Boolean(formEditAgent || formDeleteAgent)}
        onCancel={clearForm}
      >
        {formEditAgent ? (
          <StyledAgentForm key={formEditAgent?.key} agent={formEditAgent} onSuccess={clearForm} />
        ) : (
          <AgentDeleteForm key={formDeleteAgent?.id} agent={formDeleteAgent} onClose={clearForm} />
        )}
      </Modal>
      <Header>
        <SectionHeader
          title={i18n.t('agentsPage.title')}
          subtitle={i18n.t('agentsPage.subtitle')}
        />
        {currentSubsidiaryIdentifier && (
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setFormEditAgent(getEmptyAgent())}
          >
            {i18n.t('agentForm.addAgent')}
          </Button>
        )}
      </Header>
      <AgentsTable onAgentEdit={handleAgentEdit} onAgentDelete={handleDeleteAgent} />
    </>
  );
}
