import styled from 'styled-components';

const FullWidth = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  text-align: ${(props: { align?: string }) => (props.align ? props.align : '')};
`;

export default FullWidth;
