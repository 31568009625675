import { ReactNode } from 'react';
import useAuthenticationContext from '~/context/AuthenticationContext';
import PrivateLayout from './PrivateLayout';
import PublicLayout from './PublicLayout';

interface Props {
  children: ReactNode;
}

export default function PageLayout({ children }: Props) {
  const { isAuthenticated } = useAuthenticationContext();

  const Layout = isAuthenticated ? PrivateLayout : PublicLayout;

  return <Layout>{children}</Layout>;
}
