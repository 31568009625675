import { gql } from '@apollo/client';

export interface RequestDismissAlarmMutationVariables {
  alarmID: string;
  reason: 'Acknowledged' | 'Cancelled';
}

export interface RequestDismissAlarmMutationData {
  requestDismissAlarm: {
    __typename: 'Alarm';
    id: string;
  };
}

export default gql`
  mutation MutationRequestDismissAlarm($alarmID: ID!, $reason: AlarmDismissType!) {
    requestDismissAlarm(alarm_id: $alarmID, reason: $reason) {
      id
    }
  }
`;
