import { useMutation } from '@apollo/client';
import MUTATION_DISASSOCIATE_ALARM_ADMIN, {
  DisassociateAlarmAdminMutationData,
  DisAssociateAlarmAdminMutationVariables,
} from '~/services/api/operations/mutations/MutationDisassociateAlarmAdmin';

export function useMutationDisassociateAlarmAdmin() {
  const [disassociateAlarmAdmin, { loading, error }] = useMutation<
    DisassociateAlarmAdminMutationData,
    DisAssociateAlarmAdminMutationVariables
  >(MUTATION_DISASSOCIATE_ALARM_ADMIN);

  return { disassociateAlarmAdmin, loading, error };
}
