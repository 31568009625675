import { Navigate } from 'react-router-dom';
import routes from '~/navigation/config/routes';
import useAuthenticationContext from '~/context/AuthenticationContext';
import { RouteProps } from '~/types/route';

function PublicRoute({ element }: RouteProps) {
  const { isAuthenticated } = useAuthenticationContext();

  return isAuthenticated ? <Navigate to={routes.default()} /> : element;
}

export default PublicRoute;
