import { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import { black, brightGreen, midGrey, red } from '~/styles/colours';
import { EquipmentStatusDetails, EquipmentStatusType, AGENT_STATUS } from '~/types';
import { getAgentStatusWeight } from '~/utils/agent';

enum EQUIPMENT_STATUS {
  OK = 'ok',
  NOK = 'nok',
  DYSFUNCTION = 'dysfunction',
  OFFLINE = 'offline',
  UNAVAILABLE = 'unavailable',
  CONNECTION_LOST = 'connectionLost',
}

const equipmentStatusColorMap = {
  [EQUIPMENT_STATUS.OK]: brightGreen,
  [EQUIPMENT_STATUS.NOK]: red,
  [EQUIPMENT_STATUS.DYSFUNCTION]: red,
  [EQUIPMENT_STATUS.OFFLINE]: black,
  [EQUIPMENT_STATUS.UNAVAILABLE]: black,
  [EQUIPMENT_STATUS.CONNECTION_LOST]: midGrey,
};

const getEquipmentStatusLabelColor = (status: EQUIPMENT_STATUS, count?: number) => ({
  label: i18n.t(`carrierDetailsPopup.equipment.status.${status}`, { count }),
  color: equipmentStatusColorMap[status],
});

function computeEquipmentDysfunctionStatus(
  { heartRate, bodyTemperature, gas, emergencyButton, traakFront, traakBack }: EquipmentStatusType,
  features: CompanyFeatures,
) {
  let count = 0;

  if (features.heartRateSensor && !heartRate.healthy) count += 1;

  if (features.bodyTemperatureSensor && !bodyTemperature.healthy) count += 1;

  if (features.gasSensor && !gas.healthy) count += 1;

  if (features.emergencyButton && !emergencyButton.healthy) count += 1;

  if (features.impactDetectionFront && !traakFront.healthy) count += 1;

  if (features.impactDetectionBack && !traakBack.healthy) count += 1;

  return count
    ? getEquipmentStatusLabelColor(EQUIPMENT_STATUS.DYSFUNCTION, count)
    : getEquipmentStatusLabelColor(EQUIPMENT_STATUS.OK);
}

const computeEquipmentStatus = (equipmentStatusDetails?: EquipmentStatusDetails) =>
  getEquipmentStatusLabelColor(
    equipmentStatusDetails?.healthy ? EQUIPMENT_STATUS.OK : EQUIPMENT_STATUS.NOK,
  );

export function computeEquipmentStatuses(
  equipmentStatus: EquipmentStatusType,
  features: CompanyFeatures,
) {
  if (equipmentStatus.offline.healthy || equipmentStatus.connectionLost.healthy) {
    return {
      brain: getEquipmentStatusLabelColor(EQUIPMENT_STATUS.NOK),
      status: getEquipmentStatusLabelColor(
        equipmentStatus.offline.healthy
          ? EQUIPMENT_STATUS.OFFLINE
          : EQUIPMENT_STATUS.CONNECTION_LOST,
      ),
      traakFront: getEquipmentStatusLabelColor(EQUIPMENT_STATUS.UNAVAILABLE),
      traakBack: getEquipmentStatusLabelColor(EQUIPMENT_STATUS.UNAVAILABLE),
      heartRate: getEquipmentStatusLabelColor(EQUIPMENT_STATUS.UNAVAILABLE),
      emergencyButton: getEquipmentStatusLabelColor(EQUIPMENT_STATUS.UNAVAILABLE),
      gas: getEquipmentStatusLabelColor(EQUIPMENT_STATUS.UNAVAILABLE),
      bodyTemperature: getEquipmentStatusLabelColor(EQUIPMENT_STATUS.UNAVAILABLE),
    };
  }

  return {
    brain: getEquipmentStatusLabelColor(EQUIPMENT_STATUS.OK),
    status: computeEquipmentDysfunctionStatus(equipmentStatus, features),
    heartRate: computeEquipmentStatus(equipmentStatus.heartRate),
    gas: computeEquipmentStatus(equipmentStatus.gas),
    bodyTemperature: computeEquipmentStatus(equipmentStatus.bodyTemperature),
    emergencyButton: computeEquipmentStatus(equipmentStatus.emergencyButton),
    traakFront: computeEquipmentStatus(equipmentStatus.traakFront),
    traakBack: computeEquipmentStatus(equipmentStatus.traakBack),
  };
}

export const createDefaultSorter =
  (key: string) => (a: Record<string, unknown>, b: Record<string, unknown>) =>
    (a[key]?.toString() ?? '')
      .toLowerCase()
      .localeCompare((b[key]?.toString() ?? '').toLowerCase());

export const createStatusSorter = () => (a: Record<string, unknown>, b: Record<string, unknown>) =>
  getAgentStatusWeight({ isOffline: !!a.isOffline, status: a.status as AGENT_STATUS }) -
  getAgentStatusWeight({ isOffline: !!b.isOffline, status: b.status as AGENT_STATUS });
