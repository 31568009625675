import { ImageProps } from 'antd';
import { computeURL } from '~/utils/assets';
import Logo from '~/components/atoms/logo/Logo';

const src = computeURL(`/icons/health_icon.png`);
const style = { width: '24px' };

function HealthIcon(props: ImageProps) {
  return <Logo src={src} preview={false} style={style} {...props} />;
}

export default HealthIcon;
