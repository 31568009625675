import { CSSProperties } from 'react';
import type { ButtonProps } from 'antd';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import Button from './Button';

const style: CSSProperties = {
  top: 0,
  right: 0,
  position: 'absolute',
  background: 'none',
  border: 'none',
  padding: 'none',
};

function CloseButton({ children, ...props }: ButtonProps) {
  return (
    <Button style={style} type="text" {...props}>
      <CloseOutlined />
      {children}
    </Button>
  );
}

export default CloseButton;
