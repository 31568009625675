import type { ImageProps } from 'antd';
import Image from '~/components/atoms/Image';
import { computeURL } from '~/utils/assets';

const defaultSrc = computeURL('/images/logo-wearin-black.png');

function Logo({ src = defaultSrc, ...props }: ImageProps) {
  return <Image src={src} {...props} />;
}

export default Logo;
