import { useMemo } from 'react';
import useCompany from '~/hooks/useCompany';

export interface CompanyFeatures {
  // General
  vehicles: boolean;
  safeZone: boolean;
  endOfDayReset: boolean;
  graphiql: boolean;
  deleteAgents: boolean;
  // Sensors
  emergencyButton: boolean;
  biometricData: boolean;
  heartRateSensor: boolean;
  bodyTemperatureSensor: boolean;
  gasSensor: boolean;
  impactDetectionFront: boolean;
  impactDetectionBack: boolean;
}

export default function useCompanyFeatures(): CompanyFeatures {
  const { currentCompany } = useCompany();

  const companyFeatures = useMemo(() => {
    const isFeatureEnabled = (feature: `feature_${string}`): boolean =>
      currentCompany?.attributes?.find((attribute) => attribute?.name === feature)?.value ===
      'enabled';

    return {
      // General
      vehicles: isFeatureEnabled('feature_vehicles'),
      safeZone: isFeatureEnabled('feature_safe_zone'),
      endOfDayReset: isFeatureEnabled('feature_end_of_day_reset'),
      graphiql: isFeatureEnabled('feature_graphiql'),
      deleteAgents: isFeatureEnabled('feature_delete_agents'),
      // Sensors
      emergencyButton: isFeatureEnabled('feature_emergency_button'),
      biometricData: isFeatureEnabled('feature_biometric_data'),
      heartRateSensor: isFeatureEnabled('feature_heart_rate_sensor'),
      bodyTemperatureSensor: isFeatureEnabled('feature_body_temperature_sensor'),
      gasSensor: isFeatureEnabled('feature_gas_sensor'),
      impactDetectionFront: isFeatureEnabled('feature_impact_detection_front'),
      impactDetectionBack: isFeatureEnabled('feature_impact_detection_back'),
    } satisfies CompanyFeatures;
  }, [currentCompany?.attributes]);

  return companyFeatures;
}
