import styled from 'styled-components';
import { lightGrey } from '~/styles/colours';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import CarrierDetailsPopupAddressAndLocation from './CarrierDetailsPopupAddressAndLocation';
import CarrierDetailsPopupMissionStartedAndDuration from './CarrierDetailsPopupMissionStartedAndDuration';
import CarrierDetailsPopupPhoneNumber from './CarrierDetailsPopupPhoneNumber';
import CarrierDetailsPopupSpeed from './CarrierDetailsPopupSpeed';

export interface CarrierDetailsPopupHeadContentProps {
  count: number;
}

const CarrierDetailsPopupSubSpace = styled.div`
  display: grid;
  gap: 8px;
  grid-template-rows: auto;
  padding: 16px;
  background: ${lightGrey};
  border-radius: 5px;
`;

function CarrierDetailsPopupHeadContent({ count }: CarrierDetailsPopupHeadContentProps) {
  const { gps, agent, address } = useCarrierDetailsPopupContext();

  return (
    <CarrierDetailsPopupSubSpace>
      {count < 3 && <CarrierDetailsPopupMissionStartedAndDuration agent={agent} />}
      <CarrierDetailsPopupAddressAndLocation gps={gps} address={address} />
      <CarrierDetailsPopupPhoneNumber agent={agent} />
      <CarrierDetailsPopupSpeed gps={gps} />
    </CarrierDetailsPopupSubSpace>
  );
}

export default CarrierDetailsPopupHeadContent;
