import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProps } from 'antd';
import {
  AWSLoginResponseOnConditionalSuccess,
  AWSSetupMfaUserAttributes,
  AWS_AUTHENTICATION_RESPONSE_TYPE,
} from '~/types/awsService';
import routes from '~/navigation/config/routes';
import i18n from '~/locales/i18n';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import notification from '~/utils/notification';
import useAuthenticationContext from '~/context/AuthenticationContext';
import CenteredFormItem from '~/components/atoms/form/CenteredFormItem';
import Form from '~/components/atoms/form/Form';
import LoadingSpin from '~/components/atoms/LoadingSpin';
import AuthButton from '~/components/atoms/button/AuthButton';
import WearinLogoBlack from '~/components/atoms/logo/WearinLogoBlack';
import InputPassword from '~/components/atoms/input/InputPassword';
import PasswordPolicyPopover from '~/components/atoms/PasswordPolicyPopover';

interface FormFields {
  newPassword: string;
  confirmPassword: string;
}

export default function NewPasswordChallengePage() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { completeNewPasswordChallenge } = useAuthenticationContext();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormFields>();

  const compareToFirstPassword = (rule: object, value: string) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      return Promise.reject(Error(i18n.t<string>('newPasswordChallenge.passwordsMismatch')));
    }

    return Promise.resolve();
  };

  const handleFinish = ({ newPassword }: FormFields) => {
    setLoading(true);
    completeNewPasswordChallenge({ newPassword: newPassword.trim() })
      .then((response) => {
        switch (response?.type) {
          case AWS_AUTHENTICATION_RESPONSE_TYPE.MFA_SETUP:
            notification.success({
              message: i18n.t('newPasswordChallenge.successTitle'),
              description: i18n.t('newPasswordChallenge.mfaSetupDescription'),
            });
            navigate(
              routes.mfa({
                base64Image: (
                  (response as AWSLoginResponseOnConditionalSuccess).data
                    ?.userAttributes as AWSSetupMfaUserAttributes
                ).base64Image,
              }),
            );
            break;
          case AWS_AUTHENTICATION_RESPONSE_TYPE.ON_SUCCESS:
          default:
            notification.success({
              message: i18n.t('newPasswordChallenge.successTitle'),
              description: i18n.t('newPasswordChallenge.successDescription'),
            });
            browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
            navigate(routes.default());
            break;
        }
      })
      .catch((catchedError: Error) => {
        notification.error({
          message: i18n.t('newPasswordChallenge.failTitle'),
          description: catchedError.message,
        });
        setLoading(false);
      });
  };

  return (
    <Form<FC<FormProps<FormFields>>> form={form} onFinish={handleFinish}>
      <CenteredFormItem>
        <WearinLogoBlack />
      </CenteredFormItem>
      <PasswordPolicyPopover>
        <CenteredFormItem
          name="newPassword"
          rules={[
            { required: true, message: i18n.t<string>('newPasswordChallenge.invalidNewPassword') },
          ]}
        >
          <InputPassword placeholder={i18n.t<string>('newPasswordChallenge.newPassword')} />
        </CenteredFormItem>
      </PasswordPolicyPopover>
      <CenteredFormItem
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: i18n.t<string>('newPasswordChallenge.invalidConfirmPassword'),
          },
          { validator: compareToFirstPassword },
        ]}
      >
        <InputPassword placeholder={i18n.t<string>('newPasswordChallenge.confirmPassword')} />
      </CenteredFormItem>
      <CenteredFormItem>
        <AuthButton style={{ width: '100%' }} disabled={isLoading}>
          {isLoading ? <LoadingSpin /> : i18n.t('newPasswordChallenge.submit')}
        </AuthButton>
      </CenteredFormItem>
    </Form>
  );
}
