import Popover from 'antd/lib/popover';
import i18n from '~/locales/i18n';

interface PasswordPolicyPopoverProps {
  children: React.ReactNode;
}

function PasswordPolicyPopover({ children }: PasswordPolicyPopoverProps) {
  return (
    <Popover
      placement="right"
      trigger="focus"
      title={i18n.t('passwordPolicyPopover.title')}
      content={
        <>
          <h4>{i18n.t('passwordPolicyPopover.rulesTitle')}</h4>
          <ul>
            <li>{i18n.t('passwordPolicyPopover.minLength')}</li>
            <li>{i18n.t('passwordPolicyPopover.number')}</li>
            <li>{i18n.t('passwordPolicyPopover.specialCharacter')}</li>
            <li>{i18n.t('passwordPolicyPopover.uppercaseLetter')}</li>
            <li>{i18n.t('passwordPolicyPopover.lowercaseLetter')}</li>
          </ul>
        </>
      }
    >
      {children}
    </Popover>
  );
}

export default PasswordPolicyPopover;
