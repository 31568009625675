import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, MenuProps } from 'antd';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import i18next from 'i18next';
import useAuthenticationContext from '~/context/AuthenticationContext';
import notification from '~/utils/notification';
import i18n from '~/locales/i18n';
import config from '~/config/config';
import { currentYear } from '~/config/constants';
import routes from '~/navigation/config/routes';
import useCurrentUserContext from '~/context/CurrentUserContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import { grey, white } from '~/styles/colours';
import Text from '~/components/atoms/typography/Text';
import Link from '~/components/atoms/link/Link';
import Modal from '~/components/atoms/Modal';

const WrapperDiv = styled('div')`
  padding-left: 5px;
`;

const StyledButton = styled(Button)`
  color: ${white} !important;
  font-weight: 600;

  &:hover,
  &:focus {
    color: ${grey} !important;
  }
`;

const CopyrightP = styled('p')`
  margin: 16px 0 0;
`;

export default function NavBarUserDropdown() {
  const { getUser, logout } = useAuthenticationContext();
  const currentUser = useCurrentUserContext();
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const username = currentUser?.email ?? getUser()?.getUsername();
  const features = useCompanyFeatures();

  const menu: MenuProps = useMemo(
    () => ({
      onClick: async ({ key }) => {
        switch (key) {
          case 'about':
            setIsAboutOpen(true);
            break;
          case 'logout':
            try {
              await logout().then(() => {
                notification.warning({
                  message: i18n.t('general.auth.loggedOutTitle'),
                  description: i18n.t('general.auth.loggedOutDescription'),
                });
              });
            } catch (cachedError) {
              notification.error({ message: (cachedError as Error).message });
            }
            break;
          default:
            break;
        }
      },
      theme: 'light',
      items: [
        {
          key: 'settings',
          label: <Link to={routes.settings()}>{i18n.t('header.user.settings')}</Link>,
        },
        ...(features.graphiql
          ? [
              {
                key: 'developer-tools',
                label: (
                  <Link to={routes.developerTools()}>{i18n.t('header.user.developerTools')}</Link>
                ),
              },
            ]
          : []),
        {
          key: 'about',
          label: i18n.t('header.user.about'),
        },
        {
          key: 'logout',
          label: <div data-id="logout-btn">{i18n.t('header.user.logoutButton')}</div>,
        },
      ],
    }),
    [features.graphiql, logout],
  );

  return (
    <>
      <WrapperDiv>
        <Dropdown menu={menu}>
          <StyledButton type="text">
            <UserOutlined />
            &nbsp;<span data-id="username">{username}</span>
          </StyledButton>
        </Dropdown>
      </WrapperDiv>
      <Modal
        title={i18n.t('header.user.about')}
        footer={null}
        centered
        width={500}
        open={isAboutOpen}
        onCancel={() => setIsAboutOpen(false)}
      >
        <Text>{`wearin'`}</Text>
        <br />
        <>
          <Text>
            {i18n.t('dashboardOverlay.version', { version: config.version || 'development' })}
          </Text>
          <br />
        </>
        <>
          <Text>
            {i18n.t('dashboardOverlay.buildNumber', { number: config.commitHash || 'development' })}
          </Text>
          <br />
        </>
        <Text>
          {i18n.t('dashboardOverlay.deployTime', { date: config.commitDate || 'development' })}
        </Text>
        <CopyrightP>{i18next.t('footer.copyright', { currentYear })}</CopyrightP>
      </Modal>
    </>
  );
}
