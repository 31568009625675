import { useState, ClipboardEvent, ChangeEvent, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Spin, FormProps } from 'antd';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import QrcodeOutlined from '@ant-design/icons/lib/icons/QrcodeOutlined';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import notification from '~/utils/notification';
import { CustomURLSearchParams } from '~/types/route';
import { AWS_AUTHENTICATION_RESPONSE_TYPE } from '~/types/awsService';
import i18n from '~/locales/i18n';
import routes from '~/navigation/config/routes';
import { black } from '~/styles/colours';
import useAuthenticationContext from '~/context/AuthenticationContext';
import useQueryParams from '~/hooks/useQueryParams';
import ConfirmationCodeForm from '~/components/atoms/form/ConfirmationCodeForm';
import CenteredFormItem from '~/components/atoms/form/CenteredFormItem';
import AuthButton from '~/components/atoms/button/AuthButton';
import Image from '~/components/atoms/Image';
import WearinLogoBlack from '~/components/atoms/logo/WearinLogoBlack';
import Input from '~/components/atoms/input/Input';

const MFA_LENGTH = 6;
const MFA_REGEX = /^[0-9]{6}$/;
const MFA_SHORTER_REGEX = /^[0-9]{1,6}$/;

interface FormFields {
  mfaCode: string;
}

const StyledConfirmationCodeForm = styled(ConfirmationCodeForm<FC<FormProps<FormFields>>>)`
  max-width: 530px;
  width: 50%;
`;

export default function MFAPage() {
  const { mfaType, base64Image }: CustomURLSearchParams = useQueryParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { verifyMFA, verifyMFASetup } = useAuthenticationContext();
  const navigate = useNavigate();
  const error = '';

  const verifyMFACallback = (code?: string): void => {
    if (!code || !MFA_REGEX.test(code)) {
      notification.error({
        message: i18n.t('mfa.errorTitle'),
        description: i18n.t('mfa.patternFailed'),
      });

      return;
    }

    setLoading(true);

    if (base64Image) {
      verifyMFASetup({ code })
        .then(() => {
          notification.success({
            message: i18n.t('mfa.successTitle'),
            description: i18n.t('mfa.validCode'),
          });
          browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
          navigate(routes.default());
        })
        .catch(({ message }: Error) => {
          notification.error({
            message: i18n.t('mfa.errorTitle'),
            description: message,
          });
          setLoading(false);
        });

      return;
    }

    verifyMFA({
      mfaType: mfaType as AWS_AUTHENTICATION_RESPONSE_TYPE,
      confirmationCode: code,
    })
      .then(() => {
        notification.success({
          message: i18n.t('mfa.successTitle'),
          description: i18n.t('mfa.validCode'),
        });
        browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
        navigate(routes.default());
      })
      .catch(({ message }: Error) => {
        notification.error({
          message: i18n.t('mfa.errorTitle'),
          description: message,
        });
        setLoading(false);
      });
  };

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    verifyMFACallback(event.clipboardData.getData('Text').trim());
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const code = event.currentTarget.value;

    if (!MFA_SHORTER_REGEX.test(code)) return null;

    if (code.length === MFA_LENGTH) verifyMFACallback(code);

    return code;
  };

  const handleFinish = ({ mfaCode }: FormFields) => verifyMFACallback(mfaCode);

  return (
    <StyledConfirmationCodeForm onFinish={handleFinish}>
      <CenteredFormItem>
        <WearinLogoBlack />
      </CenteredFormItem>
      <Col md={24} lg={18}>
        <CenteredFormItem>
          <h2>{i18n.t('mfa.title')}</h2>
          <p>{i18n.t('mfa.message')}</p>
        </CenteredFormItem>
        <CenteredFormItem
          name="mfaCode"
          colon={false}
          required={false}
          validateStatus={error && 'error'}
          help={error}
        >
          <Input
            prefix={<QrcodeOutlined style={{ color: black }} />}
            size="large"
            type="number"
            placeholder={i18n.t<string>('mfa.inputPlaceholder')}
            onPaste={handlePaste}
            onChange={handleChange}
            autoFocus
          />
        </CenteredFormItem>
      </Col>
      {base64Image && (
        <CenteredFormItem>
          <Image src={base64Image} />
        </CenteredFormItem>
      )}
      <Col md={24} lg={12}>
        <AuthButton disabled={loading} size="large">
          {loading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            i18n.t('mfa.buttonText')
          )}
        </AuthButton>
      </Col>
    </StyledConfirmationCodeForm>
  );
}
