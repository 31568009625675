import { computeURL } from '~/utils/assets';
import { alertSoundMuted } from '~/services/api/reactiveVariables/alertSoundMuted';

const ALARM_WAV_PATH = computeURL('/sounds/alarm.wav');
const REACTIVATE_TIMEOUT_MS = 10 * 60 * 1000;
const alarm = new Audio(ALARM_WAV_PATH);
let muted = false;
let timeoutId: number;

// used in e2e tests
if (window.location.hostname === 'localhost') window.alarm = alarm;

alarm.loop = true;

export function playAlarm() {
  // eslint-disable-next-line no-console
  if (!muted) alarm.play()?.catch((error) => console.log('alarm play failed', error));
}

export function stopAlarm() {
  window.clearTimeout(timeoutId);
  alarm.pause();
  alarm.currentTime = 0;
}

export function unmuteAlarm() {
  window.clearTimeout(timeoutId);

  muted = false;

  alertSoundMuted(false);
  playAlarm();
}

export function muteAlarm() {
  window.clearTimeout(timeoutId);

  timeoutId = window.setTimeout(unmuteAlarm, REACTIVATE_TIMEOUT_MS);
  muted = true;
  alertSoundMuted(true);
  stopAlarm();
}
