import { gql } from '@apollo/client';
import type { IvsStream } from '~/types';

export interface VideoStreamSubscriptionVariables {
  carrierID: string;
}

export interface VideoStreamSubscriptionData {
  carrier: {
    carrier: {
      id: string;
      name: string;
      videoStream: IvsStream;
    };
  };
}

export default gql`
  subscription SubscribeToVideoStream($carrierID: ID!) {
    carrier(carrier_id: $carrierID) {
      carrier {
        id
        name
        videoStream: video_stream {
          ivs_stream_state
        }
      }
    }
  }
`;
