import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useReactiveVar } from '@apollo/client';
import minBy from 'lodash/minBy';
import {
  AgentType,
  ALARM_TYPES,
  SENSOR_TYPE_KEYS,
  SensorType,
  EQUIPMENT_STATUSES,
  EquipmentStatusType,
  AGENT_STATUS,
  BrainStopSensorType,
  AgentAttributesType,
  SENSOR_TYPE_STATUSES,
  DeviceAttributesType,
  AllAlarms,
  VehicleType,
  GpsCoordinatesType,
} from '~/types';
import { agentStatusPriority } from '~/config/constants';
import { DEFAULT_EQUIPMENT } from '~/config/defaults';
import { parseJSON, transformAttributes } from '~/utils/parse';
import { isToday } from '~/utils/dateTime';
import notification from '~/utils/notification';
import { getAgentGasAlarms } from '~/utils/agent';
import { currentSubsidiaryIdentifierVar } from '~/services/api/reactiveVariables/currentSubsidiaryIdentifierVar';
import useQueryWithSubscriptionSubsidiaryCarrierList from '~/services/api/apis/useQueryWithSubscriptionSubsidiaryCarrierList';
import { CarrierItem } from '~/services/api/operations/queries/QuerySubsidiaryCarrierList';
import useAuthenticationContext from '~/context/AuthenticationContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';

function computeAgentStatus({
  alarms,
  connectionLost,
  safeZone,
}: {
  alarms: AllAlarms;
  connectionLost: boolean;
  safeZone: boolean;
}): AGENT_STATUS {
  if (connectionLost) return AGENT_STATUS.CONNECTION_LOST;

  const {
    falls,
    emergencies,
    attacks,
    traakFront,
    traakBack,
    gasCH4HC,
    gasCO,
    gasCO2,
    gasH2S,
    gasO2,
  } = alarms;
  const hasFall = Boolean(falls?.length && !falls[0].dismissed_at);
  const hasEmergency = Boolean(emergencies?.length && !emergencies[0]?.dismissed_at);
  const hasAttack = Boolean(attacks?.length && !attacks[0]?.dismissed_at);
  const hasTraakFront = Boolean(traakFront?.length && !traakFront[0].dismissed_at);
  const hasTraakBack = Boolean(traakBack?.length && !traakBack[0].dismissed_at);
  const hasGas =
    gasCH4HC[0]?.value ||
    gasCO[0]?.value ||
    gasCO2[0]?.value ||
    gasH2S[0]?.value ||
    gasO2[0]?.value;

  if (hasFall || hasEmergency || hasAttack || hasTraakFront || hasTraakBack || hasGas) {
    return AGENT_STATUS.ALERT;
  }

  return safeZone ? AGENT_STATUS.IN_SAFE_ZONE : AGENT_STATUS.IN_MISSION;
}

function computeVehicleStatus(agents: AgentType[]): AGENT_STATUS {
  const initialVehicleStatus = minBy(
    Object.entries(agentStatusPriority),
    ([, value]) => value,
  )?.[0] as AGENT_STATUS;

  return agents.reduce(
    (vehicleStatus: AGENT_STATUS, agent: AgentType) =>
      agentStatusPriority[agent.status] > agentStatusPriority[vehicleStatus]
        ? agent.status
        : vehicleStatus,
    initialVehicleStatus,
  );
}

const computeVehicleCompleteName = (agents: AgentType[]) =>
  agents.map((agent) => agent.completeName).join(' ');

function isEquipmentHealthy(
  status?: EQUIPMENT_STATUSES,
  connectionLost?: boolean,
  isOffline?: boolean,
): boolean {
  if (connectionLost || isOffline) return false;

  switch (status) {
    case EQUIPMENT_STATUSES.NO_ERROR:
      return true;
    case EQUIPMENT_STATUSES.NO_SENSOR:
    case EQUIPMENT_STATUSES.PHONE_BLE_DISABLED:
    case EQUIPMENT_STATUSES.BLE_DISABLED:
    case EQUIPMENT_STATUSES.SENSOR_CONNECTING:
    case EQUIPMENT_STATUSES.SENSOR_DISCONNECTED:
    case EQUIPMENT_STATUSES.SENSOR_ERROR:
    case EQUIPMENT_STATUSES.SENSOR_INACTIVE:
    case EQUIPMENT_STATUSES.SENSOR_UNPAIRED:
    default:
      return false;
  }
}

function computeVehicleLocation(agents: AgentType[]): GpsCoordinatesType {
  if (!agents) return { lat: 0, lng: 0 };

  if (agents.length > 1) {
    // if 2 agents are in a vehicle
    // make sure we are using the most recent location
    // if one agent gets disconnected we still want the vehicle to keep moving
    const [agent1, agent2] = agents;
    const location1 = agent1.sensors?.gps;
    const location2 = agent2.sensors?.gps;
    const locationTimestamp1 = location1?.timestamp ? new Date(location1.timestamp).getTime() : 0;
    const locationTimestamp2 = location2?.timestamp ? new Date(location2.timestamp).getTime() : 0;

    return locationTimestamp1 >= locationTimestamp2 ? location1 : location2;
  }

  return agents[0]?.sensors?.gps;
}

function isAgentNew(device: CarrierItem['device']) {
  if (device) {
    const sensorTypes = Object.keys(SENSOR_TYPE_KEYS) as Array<keyof typeof SENSOR_TYPE_KEYS>;

    return !sensorTypes.some(
      (sensorType) =>
        device[(SENSOR_TYPE_KEYS[sensorType] as SensorType).NAME]?.items?.[0]?.value != null,
    );
  }

  return true;
}

const CONNECTION_LOST_THRESHOLD = 5 * 60 * 1000;
const CONNECTION_LOST_CHECK_PERIOD = 5 * 1000;

interface AgentsContextResponseType {
  agents: AgentType[];
  getAgent: (id: string) => AgentType | undefined;
  vehicles: VehicleType[];
  getVehicle: (id: string) => VehicleType | undefined;
  alertAgents: AgentType[];
  hasAlert: boolean;
}

const AgentsContext = createContext<AgentsContextResponseType>({
  agents: [],
  getAgent: () => undefined,
  vehicles: [],
  getVehicle: () => undefined,
  alertAgents: [],
  hasAlert: false,
});

const useAgentsContext = () => useContext(AgentsContext);

interface AgentsContextProviderProps {
  children: ReactNode;
}

export function AgentsContextProvider({ children }: AgentsContextProviderProps) {
  const { isAuthenticated } = useAuthenticationContext();
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar);
  const features = useCompanyFeatures();
  const [agents, setAgents] = useState<AgentType[]>([]);
  const [vehicles, setVehicles] = useState<VehicleType[]>([]);
  const disconnectTimestampsTimeoutIdRef = useRef<number>();
  const disconnectTimestampsRef = useRef<Record<string, string>>({});
  const [disconnectTimestampsIteration, setDisconnectTimestampsIteration] = useState(Date.now());
  const { subsidiaryCarrierList } = useQueryWithSubscriptionSubsidiaryCarrierList({
    subsidiaryID: currentSubsidiaryIdentifier,
    skip: !currentSubsidiaryIdentifier || !isAuthenticated,
  });

  useEffect(() => {
    const setDisconnectTimestamps = () => {
      let timestampsModified = false;

      subsidiaryCarrierList?.forEach(({ id, device }) => {
        // check for connection status
        const connectionItem = device?.[SENSOR_TYPE_KEYS.CONNECTED.NAME]?.items[0];

        if (connectionItem?.value && connectionItem?.timestamp) {
          const isConnected = connectionItem.value === 'true';

          // clear if connected
          if (isConnected && disconnectTimestampsRef.current[id]) {
            delete disconnectTimestampsRef.current[id];
            timestampsModified = true;
          }

          // set timestamp if it hasn't been set yet
          if (!isConnected && !disconnectTimestampsRef.current[id]) {
            disconnectTimestampsRef.current[id] = connectionItem.timestamp;
            timestampsModified = true;
          }
        }
      });

      setDisconnectTimestampsIteration((prev) =>
        timestampsModified || Date.now() - prev > CONNECTION_LOST_THRESHOLD ? Date.now() : prev,
      );

      disconnectTimestampsTimeoutIdRef.current = window.setTimeout(
        setDisconnectTimestamps,
        CONNECTION_LOST_CHECK_PERIOD,
      );
    };

    setDisconnectTimestamps();

    return () => {
      window.clearTimeout(disconnectTimestampsTimeoutIdRef.current);
    };
  }, [subsidiaryCarrierList]);

  useEffect(() => {
    setAgents(
      subsidiaryCarrierList?.map(({ id, name, device, attributes }) => {
        // Attributes
        const attributesMap = transformAttributes<AgentAttributesType>(attributes);
        const deviceAttributesMap = transformAttributes<DeviceAttributesType>(device?.attributes);

        const completeName = (
          attributesMap.first_name && attributesMap.last_name
            ? `${attributesMap.first_name} ${attributesMap.last_name}`
            : name
        )?.trim();

        // EQUIPMENT STATUS
        const equipmentStatus: EquipmentStatusType = { ...DEFAULT_EQUIPMENT };

        // SENSORS

        // Connected
        const disconnectTimestamp = disconnectTimestampsRef.current[id];
        let connectionLost = false;

        if (disconnectTimestamp) {
          const difference = Date.now() - new Date(disconnectTimestamp).getTime();

          if (difference > CONNECTION_LOST_THRESHOLD) connectionLost = true;
        }

        const connectionHistory = device?.[SENSOR_TYPE_KEYS.CONNECTION_HISTORY.NAME]?.items
          ?.slice()
          .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

        const firstConnectionToday = connectionHistory?.find(
          (x) => x.value === 'true' && isToday(x.timestamp),
        );

        const lastConnectionToday = connectionHistory
          ?.slice()
          .reverse()
          .find((x) => x.value === 'true' && isToday(x.timestamp));

        const hasConnectedToday = !!firstConnectionToday;

        // Brain stop button
        let brainStopButton: BrainStopSensorType = {};
        let brainStopTimestamp = '';

        try {
          const item = device?.[SENSOR_TYPE_KEYS.BRAIN_STOP.NAME]?.items[0];

          if (item) {
            brainStopButton = JSON.parse(item.value);
            brainStopTimestamp = item.timestamp;
          }
        } catch (error) {
          brainStopButton = {};
        }

        let missionEndTimeISO =
          brainStopButton.stop && isToday(brainStopTimestamp) ? brainStopTimestamp : '';
        let missionStartTimeISO = firstConnectionToday?.timestamp ?? '';

        // If mission ended on another day, we set start of the mission to start of this day.
        if (missionEndTimeISO && !missionStartTimeISO) {
          missionStartTimeISO = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
        }

        // If agent got connected again after ending the mission, clear the timestamp.
        if (
          lastConnectionToday?.timestamp &&
          missionEndTimeISO &&
          new Date(lastConnectionToday?.timestamp).getTime() > new Date(missionEndTimeISO).getTime()
        ) {
          missionEndTimeISO = '';
        }

        const isOffline =
          brainStopButton.stop ||
          (features.endOfDayReset && !hasConnectedToday) ||
          isAgentNew(device);

        // GPS
        const gpsItem = device?.[SENSOR_TYPE_KEYS.GPS.NAME]?.items[0];
        const gpsUnprocessed = gpsItem?.value;
        let gpsProcessed;

        try {
          gpsProcessed = gpsUnprocessed && JSON.parse(gpsUnprocessed);
        } catch (error) {
          notification.error({ message: (error as Error).message });
        }

        const gps = gpsProcessed && {
          ...gpsProcessed,
          timestamp: gpsItem?.timestamp,
        };

        // Connection lost
        equipmentStatus.connectionLost = {
          status: EQUIPMENT_STATUSES.NO_ERROR,
          healthy: connectionLost,
        };

        // Offline
        equipmentStatus.offline = {
          status: EQUIPMENT_STATUSES.NO_ERROR,
          healthy: isOffline,
        };

        // Heart rate
        const heartRate = Number(device?.[SENSOR_TYPE_KEYS.HEART_RATE.NAME]?.items[0]?.value);
        const heartRateStatus = features.heartRateSensor
          ? device?.[SENSOR_TYPE_KEYS.HEART_RATE.STATUS_NAME]?.items[0]?.value
          : undefined;
        equipmentStatus.heartRate = {
          status: heartRateStatus || EQUIPMENT_STATUSES.NO_SENSOR,
          healthy: isEquipmentHealthy(heartRateStatus, connectionLost, isOffline),
        };

        // Body temperature
        const bodyTemperature = Number(
          device?.[SENSOR_TYPE_KEYS.BODY_TEMPERATURE.NAME]?.items[0]?.value,
        );
        const bodyTemperatureStatus = features.bodyTemperatureSensor
          ? device?.[SENSOR_TYPE_KEYS.BODY_TEMPERATURE.STATUS_NAME]?.items[0]?.value
          : undefined;
        equipmentStatus.bodyTemperature = {
          status: bodyTemperatureStatus || EQUIPMENT_STATUSES.NO_SENSOR,
          healthy: isEquipmentHealthy(bodyTemperatureStatus, connectionLost, isOffline),
        };

        // Gas
        const gas = parseJSON(device?.[SENSOR_TYPE_KEYS.GAS.NAME]?.items[0]?.value);
        const gasStatus = features.gasSensor
          ? device?.[SENSOR_TYPE_KEYS.GAS.STATUS_NAME]?.items[0]?.value
          : undefined;
        equipmentStatus.gas = {
          status: gasStatus || EQUIPMENT_STATUSES.NO_SENSOR,
          healthy: isEquipmentHealthy(gasStatus, connectionLost, isOffline),
        };

        // Traak front - impact detection armor
        const traakFrontStatus = features.impactDetectionFront
          ? device?.[SENSOR_TYPE_KEYS.TRAAK_FRONT.STATUS_NAME]?.items[0]?.value
          : undefined;
        equipmentStatus.traakFront = {
          status: traakFrontStatus || EQUIPMENT_STATUSES.NO_SENSOR,
          healthy: isEquipmentHealthy(traakFrontStatus, connectionLost, isOffline),
        };

        // Traak back - impact detection armor
        const traakBackStatus = features.impactDetectionBack
          ? device?.[SENSOR_TYPE_KEYS.TRAAK_BACK.STATUS_NAME]?.items[0]?.value
          : undefined;
        equipmentStatus.traakBack = {
          status: traakBackStatus || EQUIPMENT_STATUSES.NO_SENSOR,
          healthy: isEquipmentHealthy(traakBackStatus, connectionLost, isOffline),
        };

        // Emergency button
        const emergencyButtonStatus = features.emergencyButton
          ? device?.[SENSOR_TYPE_STATUSES.EMERGENCY]?.items[0]?.value
          : undefined;
        equipmentStatus.emergencyButton = {
          status: emergencyButtonStatus || EQUIPMENT_STATUSES.NO_SENSOR,
          healthy: isEquipmentHealthy(emergencyButtonStatus, connectionLost, isOffline),
        };

        // ALARMS
        const alarms: AllAlarms = {
          ...getAgentGasAlarms(device, features.gasSensor),
          falls: device?.[ALARM_TYPES.FALL]?.items || [],
          emergencies: features.emergencyButton ? device?.[ALARM_TYPES.EMERGENCY]?.items || [] : [],
          attacks: features.vehicles ? device?.[ALARM_TYPES.ATTACK]?.items || [] : [],
          traakFront: features.impactDetectionFront
            ? device?.[ALARM_TYPES.TRAAK_FRONT]?.items || []
            : [],
          traakBack: features.impactDetectionBack
            ? device?.[ALARM_TYPES.TRAAK_BACK]?.items || []
            : [],
        };
        const status: AGENT_STATUS = computeAgentStatus({
          alarms,
          connectionLost,
          safeZone: Boolean(features.safeZone && attributesMap?.in_safe_zone),
        });

        const agent: AgentType = {
          id,
          name,
          completeName,
          deviceName: deviceAttributesMap?.name || device?.name,
          attributes: attributesMap,
          team: attributesMap?.team || '',
          isOffline,
          sensors: { gps, heartRate, bodyTemperature, gas },
          alarms,
          status,
          equipmentStatus,
          missionStartTimeISO,
          missionEndTimeISO,
          connectionLost,
        };

        return agent;
      }) ?? [],
    );
  }, [
    features.heartRateSensor,
    features.bodyTemperatureSensor,
    features.gasSensor,
    features.emergencyButton,
    features.vehicles,
    features.safeZone,
    features.impactDetectionFront,
    features.impactDetectionBack,
    features.endOfDayReset,
    subsidiaryCarrierList,
    disconnectTimestampsIteration,
  ]);

  const getAgent = useCallback(
    (id: string): AgentType | undefined => agents.find((agent: AgentType) => agent.id === id),
    [agents],
  );

  const getVehicle = useCallback(
    (plateNumber: string): VehicleType | undefined =>
      vehicles.find((vehicle) => vehicle.plateNumber === plateNumber),
    [vehicles],
  );

  useEffect(() => {
    const newVehicles: VehicleType[] = [];
    const vehiclesTemp: VehicleType[] = [];

    agents.forEach((agent: AgentType) => {
      const plateNumber = agent.attributes.plate_number || '';

      if (!agent.isOffline && plateNumber) {
        let vehicle = vehiclesTemp.find(
          (vehiclesListItem) => vehiclesListItem.plateNumber === plateNumber,
        );

        if (!vehicle) {
          vehicle = {
            id: plateNumber,
            agents: [],
            location: agent.sensors.gps,
            plateNumber,
            completeName: '',
            status: AGENT_STATUS.WARNING,
          };

          vehiclesTemp.push(vehicle);
        }

        vehicle.agents.push(agent);
      }
    });

    vehiclesTemp.forEach((vehicle) => {
      const status = computeVehicleStatus(vehicle.agents);
      const completeName = computeVehicleCompleteName(vehicle.agents);
      const location = computeVehicleLocation(vehicle.agents);

      newVehicles.push({
        ...vehicle,
        completeName,
        status,
        location,
      });
    });

    setVehicles(features.vehicles ? newVehicles : []);
  }, [agents, features.vehicles]);

  const alertAgents = useMemo(
    () => agents.filter(({ status }) => status === AGENT_STATUS.ALERT),
    [agents],
  );

  const hasAlert = alertAgents.length > 0;

  const value: AgentsContextResponseType = useMemo(
    () => ({ agents, getAgent, vehicles, getVehicle, alertAgents, hasAlert }),
    [agents, getAgent, vehicles, getVehicle, alertAgents, hasAlert],
  );

  return <AgentsContext.Provider value={value}>{children}</AgentsContext.Provider>;
}

export default useAgentsContext;
