import Space from '~/components/atoms/space/Space';
import CarrierDetailsPopupInfoType, { INFORMATION_TYPES } from './CarrierDetailsPopupInfoType';

export interface CarrierDetailsPopupInfoTypeListProps {
  informationTypeList: INFORMATION_TYPES[];
  count?: number;
}

export default function CarrierDetailsPopupInfoTypeList({
  informationTypeList,
  count,
}: CarrierDetailsPopupInfoTypeListProps) {
  return (
    <Space style={{ display: 'grid', rowGap: '16px' }}>
      {informationTypeList.map((informationType) => (
        <CarrierDetailsPopupInfoType
          key={`${informationType}`}
          informationType={informationType}
          count={count}
        />
      ))}
    </Space>
  );
}
