import styled from 'styled-components';
import i18n from '~/locales/i18n';
import { white } from '~/styles/colours';

export interface AlertsBottomPopupCountProps {
  size?: 'sm' | 'lg';
  className?: string;
  alertsAgentsLength: number;
}

function AlertsBottomPopupCount({
  size, // eslint-disable-line @typescript-eslint/no-unused-vars
  className,
  alertsAgentsLength,
}: AlertsBottomPopupCountProps) {
  return (
    <span className={className} data-id="alert-count">
      {i18n.t('alertsBottomPopup.alertCount', { count: alertsAgentsLength })}
    </span>
  );
}

const sizes = {
  sm: { fontSize: '14px' },
  lg: { fontSize: '20px' },
};

export default styled(AlertsBottomPopupCount)`
  color: ${white};
  font-weight: bold;
  font-size: ${({ size }) => (size && sizes[size]?.fontSize) || 'inherit'};
`;
