import DetailsPopupSpace from '~/components/atoms/space/DetailsPopupSpace';
import CarrierDetailsPopupFooter from '~/components/DetailsPopup/content/footer/CarrierDetailsPopupFooter';
import CarrierDetailsPopupHeadContent from '~/components/DetailsPopup/content/headContent/CarrierDetailsPopupHeadContent';
import CarrierDetailsPopupMidContent from '~/components/DetailsPopup/content/midContent/CarrierDetailsPopupMidContent';
import CarrierDetailsContentColumnGrid from '~/components/DetailsPopup/content/midContent/components/CarrierDetailsContentColumnGrid';
import CarrierDetailsPopupHeader from '~/components/DetailsPopup/content/header/CarrierDetailsPopupHeader';

export default function SosCarrierDetailsPopup() {
  return (
    <DetailsPopupSpace data-id="sos-carrier-details-popup">
      <CarrierDetailsPopupHeader variant="vehicle" />
      <CarrierDetailsContentColumnGrid>
        <CarrierDetailsPopupHeadContent count={1} />
        <CarrierDetailsPopupMidContent count={1} />
      </CarrierDetailsContentColumnGrid>
      <CarrierDetailsPopupFooter />
    </DetailsPopupSpace>
  );
}
