import { useEffect, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import browserStorage from '~/utils/browserStorage';
import {
  selectedTeamsVar,
  SELECTED_TEAMS_DEFAULT_VALUE,
} from '~/services/api/reactiveVariables/selectedTeamsVar';
import useAgentsContext from '~/context/AgentsContext';
import useCurrentUserContext from '~/context/CurrentUserContext';

const STORAGE_KEY = 'selectedTeams';

interface UseTeamsReturnType {
  selectedTeams: string[];
  setSelectedTeams: (teams: string[]) => void;
}

export default function useTeams(): UseTeamsReturnType {
  const currentUser = useCurrentUserContext();
  const { hasAlert } = useAgentsContext();
  // reactive var as a singleton global state shared between all hook's instances
  const selectedTeams = useReactiveVar(selectedTeamsVar);

  const setSelectedTeams = useCallback(
    (teams: string[]) => {
      if (!currentUser?.id) return;

      const savedTeams = browserStorage.session.get(STORAGE_KEY, true) || {};

      savedTeams[currentUser.id] = teams;

      browserStorage.session.set(STORAGE_KEY, savedTeams, true);
      selectedTeamsVar(teams);
    },
    [currentUser?.id],
  );

  useEffect(() => {
    if (!currentUser?.id) {
      selectedTeamsVar(SELECTED_TEAMS_DEFAULT_VALUE);

      return;
    }

    const cachedTeams =
      browserStorage.session.get(STORAGE_KEY, true)?.[currentUser.id] ||
      SELECTED_TEAMS_DEFAULT_VALUE;

    // can happen only on app load
    if (!selectedTeams.length && cachedTeams.length) selectedTeamsVar(cachedTeams);
  }, [currentUser?.id, selectedTeams.length]);

  useEffect(() => {
    if (hasAlert && currentUser?.id) {
      // delayed to make sure on app load it comes after useTeams init
      setTimeout(() => setSelectedTeams(SELECTED_TEAMS_DEFAULT_VALUE), 0);
    }
  }, [hasAlert, setSelectedTeams, currentUser?.id]);

  return { selectedTeams, setSelectedTeams };
}
