import { useMemo } from 'react';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import { AlarmType, AgentAttributesType, AgentType, DETAILS_POPUP_TYPE } from '~/types';
import routes from '~/navigation/config/routes';
import { computeAlarmMessage } from '~/utils/alarm';
import { getAgentActiveAlarms } from '~/utils/agent';
import { computeCarrierShorthandLabel } from '~/utils/agent';
import { computeDuration } from '~/utils/dateTime';
import { computeMissionTime } from '~/utils/dateTime';
import i18n from '~/locales/i18n';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import Text from '~/components/atoms/typography/Text';
import AlertsLink, { ALERTS_POPUP_LINK_SIZES } from '~/components/atoms/link/AlertsLink';

const computeAlarmTimestamp = (alarm: AlarmType) =>
  alarm.created_at && !alarm.dismissed_at ? alarm.created_at : '';

const StyledText = styled(Text)`
  font-size: 16px;
  padding-right: 5px;
`;

interface Props {
  agent: AgentType;
  collapse: () => void;
}

export default function AlertsBottomPopupTableRow({ collapse, agent }: Props) {
  const { id, completeName, attributes } = agent;
  const features = useCompanyFeatures();
  const carrierShorthandLabel = computeCarrierShorthandLabel(completeName);
  const activeAlarms = getAgentActiveAlarms(agent).map((alarm) => {
    const timestamp = computeAlarmTimestamp(alarm);

    return {
      id: alarm.id,
      message: computeAlarmMessage(alarm),
      timestamp,
      started: computeMissionTime(timestamp),
      duration: computeDuration(timestamp),
      admin: alarm.administrator,
    };
  });

  const getDetailsLink = useMemo(
    () =>
      (
        carrierId: string,
        agentAttributes: AgentAttributesType,
      ): {
        pathname: string;
        search: string | undefined;
      } => {
        const vehicleId = agentAttributes.plate_number;

        if (features.vehicles && vehicleId) {
          return routes.status({
            id: vehicleId,
            type: DETAILS_POPUP_TYPE.VEHICLE,
          });
        }

        return routes.status({ id: carrierId });
      },
    [features.vehicles],
  );

  return (
    <>
      {orderBy(activeAlarms, 'admin', 'desc').map((alarm) => (
        <tr key={alarm.id}>
          <td data-id="alert-info">
            <strong>{carrierShorthandLabel}</strong>
            {` - `}
            {completeName}
            {` - Type: `}
            <strong>{alarm.message}</strong>
            {alarm.admin && (
              <span>
                {' '}
                ({i18n.t('general.generalAlarms.alarmAssigned', { name: alarm.admin.email })})
              </span>
            )}
          </td>
          <td>
            {alarm.started}
            {` - `}
            {alarm.duration}
          </td>
          <td>
            <AlertsLink
              to={getDetailsLink(id, attributes)}
              onClick={collapse}
              linkSize={ALERTS_POPUP_LINK_SIZES.SMALL}
              data-id="alert-details-link"
            >
              <StyledText>
                {i18n.t('alertsBottomPopup.access')}
                {' >'}
              </StyledText>
            </AlertsLink>
          </td>
        </tr>
      ))}
    </>
  );
}
