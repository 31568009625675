import type { ReactNode } from 'react';
import styled from 'styled-components';
import i18n from '~/locales/i18n';
import Title from '~/components/atoms/typography/Title';
import Space from '~/components/atoms/space/Space';
import ArmorVestIcon from '~/components/atoms/icon/ArmorVestIcon';
import AlertIconBlackSmall from '~/components/atoms/icon/AlertIconBlackSmall';
import EquipmentStatusIcon from '~/components/atoms/icon/EquipmentStatusIcon';
import SkullIcon from '~/components/atoms/icon/SkullIcon';
import HealthIcon from '~/components/atoms/icon/HealthIcon';
import VideoCameraIcon from '~/components/atoms/icon/VideoCameraIcon';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import CarrierDetailsPopupInfoTypeAlertItemList from './content/alert/CarrierDetailsPopupInfoTypeAlertItemList';
import CarrierDetailsPopupInfoTypeSOSAlertTitle from './content/sosAlert/CarrierDetailsPopupInfoTypeSOSAlertTitle';
import CarrierDetailsPopupInfoTypeGasSensorItemList from './content/gasSensor/CarrierDetailsPopupInfoTypeGasSensorItemList';
import CarrierDetailsPopupInfoTypePhysiologicalSensorItemList from './content/physiologicalSensor/CarrierDetailsPopupInfoTypePhysiologicalSensorItemList';
import CarrierDetailsPopupInfoTypeSosEquipmentItemList from './content/sosEquipment/CarrierDetailsPopupInfoTypeSosEquipmentItemList';
import CarrierDetailsPopupInfoTypeTraak from './content/traak/CarrierDetailsPopupInfoTypeTraak';
import CarrierDetailsPopupInfoTypeVideoStreamingItem from './content/videoStreaming/CarrierDetailsPopupInfoTypeVideoStreamingItem';

export enum INFORMATION_TYPES {
  SOS_ALERT = 'sosAlerts',
  GAS_SENSOR = 'gasSensors',
  PHYSIOLOGICAL_SENSORS = 'physiologicalSensors',
  EQUIPMENT = 'equipment',
  TRAAK = 'traak',
  VIDEO_STREAMING = 'videoStreaming',
  VIDEO_STREAMING_NO_TITLE = 'videoStreamingNoTitle',
}

interface CarrierDetailsPopupInfoTypeProps {
  informationType: INFORMATION_TYPES;
  count?: number;
}

const CarrierDetailsPopupInfoTypeContentSpace = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export default function CarrierDetailsPopupInfoType({
  informationType,
  count,
}: CarrierDetailsPopupInfoTypeProps) {
  const companyFeatures = useCompanyFeatures();
  const { agent } = useCarrierDetailsPopupContext();
  let icon: ReactNode = null;
  let title: ReactNode = '';
  let items: ReactNode = null;

  switch (informationType) {
    case INFORMATION_TYPES.SOS_ALERT:
      icon = <AlertIconBlackSmall />;
      title = <CarrierDetailsPopupInfoTypeSOSAlertTitle />;
      items = <CarrierDetailsPopupInfoTypeAlertItemList count={count} />;

      break;
    case INFORMATION_TYPES.GAS_SENSOR:
      if (companyFeatures.gasSensor) {
        icon = <SkullIcon />;
        title = i18n.t(`carrierDetailsPopup.informationTypes.${informationType}`);
        items = <CarrierDetailsPopupInfoTypeGasSensorItemList />;
      }

      break;
    case INFORMATION_TYPES.PHYSIOLOGICAL_SENSORS:
      if (
        companyFeatures.biometricData &&
        (companyFeatures.heartRateSensor || companyFeatures.bodyTemperatureSensor)
      ) {
        icon = <HealthIcon />;
        title = i18n.t(`carrierDetailsPopup.informationTypes.${informationType}`);
        items = <CarrierDetailsPopupInfoTypePhysiologicalSensorItemList />;
      }

      break;
    case INFORMATION_TYPES.EQUIPMENT:
      icon = <EquipmentStatusIcon />;
      title = (
        <>
          {i18n.t(`carrierDetailsPopup.informationTypes.${INFORMATION_TYPES.EQUIPMENT}`)} -{' '}
          {agent?.deviceName}
        </>
      );
      items = <CarrierDetailsPopupInfoTypeSosEquipmentItemList />;

      break;
    case INFORMATION_TYPES.TRAAK:
      if (companyFeatures.impactDetectionFront || companyFeatures.impactDetectionBack) {
        icon = <ArmorVestIcon />;
        title = i18n.t(`carrierDetailsPopup.informationTypes.${informationType}`);
        items = <CarrierDetailsPopupInfoTypeTraak />;
      }

      break;
    case INFORMATION_TYPES.VIDEO_STREAMING:
      icon = <VideoCameraIcon />;
      title = i18n.t(`carrierDetailsPopup.informationTypes.${informationType}`);
      items = <CarrierDetailsPopupInfoTypeVideoStreamingItem />;

      break;
    case INFORMATION_TYPES.VIDEO_STREAMING_NO_TITLE:
      items = <CarrierDetailsPopupInfoTypeVideoStreamingItem />;

      break;
    default:
      break;
  }

  return items ? (
    <CarrierDetailsPopupInfoTypeContentSpace>
      {(icon || title) && (
        <Space>
          {icon}
          <Title spaceless level={5}>
            {title}
          </Title>
        </Space>
      )}
      {items}
    </CarrierDetailsPopupInfoTypeContentSpace>
  ) : null;
}
