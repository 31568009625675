import useQueryWithSubscriptionCarrierVideoStream from '~/services/api/apis/useQueryWithSubscriptionCarrierVideoStream';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import VideoPlayer from '~/components/VideoPlayer';
import NoVideoPopupReplacement from './NoVideoPopupReplacement';

export default function CarrierDetailsPopupInfoTypeVideoStreamingItem() {
  const { agent } = useCarrierDetailsPopupContext();
  const { videoStream } = useQueryWithSubscriptionCarrierVideoStream(agent?.id);

  return videoStream ? <VideoPlayer stream={videoStream} /> : <NoVideoPopupReplacement />;
}
