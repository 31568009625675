import { ImageProps } from 'antd';
import { computeURL } from '~/utils/assets';
import Logo from '~/components/atoms/logo/Logo';

const src = computeURL('/icons/environment_icon__black.png');

function EnvironmentIcon(props: ImageProps) {
  return <Logo src={src} preview={false} {...props} />;
}

export default EnvironmentIcon;
