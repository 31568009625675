import { AgentType } from '~/types/agent';
import { AlarmType, ALARM_TYPES, AGENT_STATUS } from '~/types/alarm';
import { GAS_SENSOR_ALARM_TYPES, SENSOR_TYPE_KEYS } from '~/types/sensor';
import { agentStatusPriority } from '~/config/constants';
import i18n from '~/locales/i18n';
import { parseJSON } from '~/utils/parse';
import { CarrierItem } from '~/services/api/operations/queries/QuerySubsidiaryCarrierList';
import { blue, green, midGrey, red, yellow } from '~/styles/colours';

export const getAgentStatusWeight = ({
  isOffline,
  status,
}: Pick<AgentType, 'isOffline' | 'status'>) => (isOffline ? 0 : agentStatusPriority[status]);

export const getAgentsSortedByStatus = (agents?: AgentType[]) =>
  agents
    ?.slice(0)
    .sort((agentA, agentB) => getAgentStatusWeight(agentB) - getAgentStatusWeight(agentA));

export const getAgentStatus = (agent: Partial<Pick<AgentType, 'isOffline' | 'status'>>) =>
  agent?.isOffline
    ? i18n.t(`general.generalAlarms.offline`)
    : i18n.t(`general.generalAlarms.${agent?.status}`);

const statusColorMap = {
  [AGENT_STATUS.ALERT]: red,
  [AGENT_STATUS.CONNECTION_LOST]: midGrey,
  [AGENT_STATUS.WARNING]: yellow,
  [AGENT_STATUS.IN_MISSION]: blue,
  [AGENT_STATUS.IN_SAFE_ZONE]: green,
};

export const getAgentStatusColor = (status: AGENT_STATUS) =>
  statusColorMap[status || AGENT_STATUS.CONNECTION_LOST];

export const getAgentGasAlarms = (device: CarrierItem['device'], enabled: boolean) => {
  // gas sensor values are stored at items[0] and alarm values at items[1]
  const gasAlarmItem = device?.[SENSOR_TYPE_KEYS.GAS.NAME]?.items[1];
  const alarmValue: Record<GAS_SENSOR_ALARM_TYPES, number> = parseJSON(gasAlarmItem?.value);
  const alarmBody: Omit<AlarmType, 'id' | 'type' | 'value'> = {
    created_at: gasAlarmItem?.timestamp || '',
    dismissed_at: null,
    dismiss_requested_at: null,
    dismiss_reason: null,
    undismissable: true,
  };

  const getGasAlarm = (type: ALARM_TYPES, gasType: GAS_SENSOR_ALARM_TYPES) =>
    alarmValue?.[gasType] ? [{ ...alarmBody, id: type, type, value: alarmValue[gasType] }] : [];

  return {
    gasCH4HC: enabled ? getGasAlarm(ALARM_TYPES.GAS_CH4HC, GAS_SENSOR_ALARM_TYPES.CH4_HC) : [],
    gasCO: enabled ? getGasAlarm(ALARM_TYPES.GAS_CO, GAS_SENSOR_ALARM_TYPES.CO) : [],
    gasCO2: enabled ? getGasAlarm(ALARM_TYPES.GAS_CO2, GAS_SENSOR_ALARM_TYPES.CO2) : [],
    gasH2S: enabled ? getGasAlarm(ALARM_TYPES.GAS_H2S, GAS_SENSOR_ALARM_TYPES.H2S) : [],
    gasO2: enabled ? getGasAlarm(ALARM_TYPES.GAS_O2, GAS_SENSOR_ALARM_TYPES.O2) : [],
  };
};

export const getAgentActiveAlarmMap = ({ alarms }: AgentType) => ({
  ...(alarms?.falls?.[0] && !alarms.falls[0].dismissed_at ? { fall: alarms.falls[0] } : {}),
  ...(alarms?.emergencies?.[0] && !alarms.emergencies[0].dismissed_at
    ? { emergency: alarms.emergencies[0] }
    : {}),
  ...(alarms?.attacks?.[0] && !alarms.attacks[0].dismissed_at ? { attack: alarms.attacks[0] } : {}),
  ...(alarms?.traakFront?.[0] && !alarms.traakFront[0].dismissed_at
    ? { traakFront: alarms.traakFront[0] }
    : {}),
  ...(alarms?.traakBack?.[0] && !alarms.traakBack[0].dismissed_at
    ? { traakBack: alarms.traakBack[0] }
    : {}),
  ...(alarms?.gasCH4HC?.[0] && !alarms.gasCH4HC[0].dismissed_at
    ? { gasCH4HC: alarms.gasCH4HC[0] }
    : {}),
  ...(alarms?.gasCO?.[0] && !alarms.gasCO[0].dismissed_at ? { gasCO: alarms.gasCO[0] } : {}),
  ...(alarms?.gasCO2?.[0] && !alarms.gasCO2[0].dismissed_at ? { gasCO2: alarms.gasCO2[0] } : {}),
  ...(alarms?.gasH2S?.[0] && !alarms.gasH2S[0].dismissed_at ? { gasH2S: alarms.gasH2S[0] } : {}),
  ...(alarms?.gasO2?.[0] && !alarms.gasO2[0].dismissed_at ? { gasO2: alarms.gasO2[0] } : {}),
});

export const getAgentActiveAlarms = (agent: AgentType) =>
  Object.values(getAgentActiveAlarmMap(agent));

export function computeCarrierShorthandLabel(name: string) {
  const nameUppercased = name.toUpperCase();
  const nameSplitted = nameUppercased.split(' ');

  switch (nameSplitted.length) {
    case 1:
      return nameSplitted[0].slice(0, 3);
    case 2:
      return `${nameSplitted[0].slice(0, 1)}${nameSplitted[1].slice(0, 2)}`;
    case 3:
    default:
      return `${nameSplitted[0].slice(0, 1)}${nameSplitted[1].slice(0, 1)}${nameSplitted[2].slice(
        0,
        1,
      )}`;
  }
}

export const computeVehicleShorthandLabel = (name: string) => name.slice(-3);
