import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import CloseCircleTwoTone from '@ant-design/icons/lib/icons/CloseCircleTwoTone';
import { red } from '~/styles/colours';

const style = { fontSize: '18px' };

function CloseCircleIcon(props: Partial<CustomIconComponentProps>) {
  return <CloseCircleTwoTone twoToneColor={red} style={style} {...props} />;
}

export default CloseCircleIcon;
