const containsSpecialCharacter = (text: string) =>
  /[-@#()+,:;<>={}|?[\]~\\/'"`!$%^&_.*]/.test(text);

const containsNumber = (text: string) => /\d/.test(text);

const containsLowercaseLetter = (text: string) => /[a-z]/.test(text);

const containsUppercaseLetter = (text: string) => /[A-Z]/.test(text);

// TODO: use on all email and password fields in forms
export const isValidPassword = (password: string) =>
  password.length > 7 &&
  containsSpecialCharacter(password) &&
  containsLowercaseLetter(password) &&
  containsUppercaseLetter(password) &&
  containsNumber(password);

export const isValidEmail = (email: string) =>
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    email,
  );

export const isValidPhoneNumber = (text: string) =>
  text?.length > 5 && /^[+0-9][0-9- ]+$/.test(text);

export const isValidAge = (text: string) =>
  /^[0-9][0-9]$/.test(text) && Number(text) >= 18 && Number(text) <= 80;

export const isValidCoordinate = (value: number, type: 'latitude' | 'longitude') =>
  Number.isFinite(value) && Math.abs(value) <= (type === 'latitude' ? 90 : 180);
