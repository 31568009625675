import { useMemo, ReactNode } from 'react';
import { Select } from 'antd';
import i18n from '~/locales/i18n';
import useSubsidiary from '~/hooks/useSubsidiary';
import NavBarSelect from './NavBarSelect';

export default function NavBarSelectSubsidiary() {
  const { currentSubsidiary, subsidiaryList, setCurrentSubsidiaryIdentifier } = useSubsidiary();

  const options: ReactNode[] = useMemo(
    () =>
      subsidiaryList.map(({ id, name }, index: number) => (
        <Select.Option
          key={id}
          value={id}
          selected={index === 0}
          data-id={`subsidiary-select-option-${id}`}
        >
          {name}
        </Select.Option>
      )),
    [subsidiaryList],
  );

  const value: string = currentSubsidiary?.name || i18n.t('header.selectSubsidiary');

  return (
    <NavBarSelect
      value={value}
      onChange={setCurrentSubsidiaryIdentifier}
      data-id="subsidiary-select"
    >
      {options}
    </NavBarSelect>
  );
}
