import { useEffect } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import i18n from '~/locales/i18n';
import { useMutationDeleteAgent } from '~/services/api/apis/useAgent';
import { computeCarrierShorthandLabel } from '~/utils/agent';
import notification from '~/utils/notification';
import { AgentType } from '~/types';

const ActionsDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  agent: AgentType | undefined;
  onClose: () => void;
}

export default function AgentDeleteForm({ agent, onClose }: Props) {
  const { deleteAgent, loading, error, deletedAgent } = useMutationDeleteAgent();

  const name = agent?.completeName
    ? `${agent?.completeName} (${computeCarrierShorthandLabel(agent?.completeName)})`
    : agent?.id;

  useEffect(() => {
    if (loading) return;

    if (deletedAgent) {
      notification.success({
        message: i18n.t('agentForm.deleteAgentSuccess', { name }),
      });
      onClose();
    } else if (error) {
      notification.error({
        message: error.message,
      });
    }
  }, [name, loading, error, deletedAgent, onClose]);

  if (!agent?.id) return null;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        deleteAgent({ variables: { agentId: agent.id } });
      }}
    >
      <p>
        {i18n.t('agentForm.deleteAgentConfirmation')}
        <br />
        <b>{name}</b>
      </p>
      <ActionsDiv>
        <Button type="default" onClick={onClose} disabled={loading}>
          {i18n.t('general.forms.no')}
        </Button>
        <Button htmlType="submit" type="primary" danger loading={loading}>
          {i18n.t('general.forms.yes')}
        </Button>
      </ActionsDiv>
    </form>
  );
}
