import { useSubscription, useQuery } from '@apollo/client';
import SUBSCRIBE_TO_VIDEO_STREAM, {
  VideoStreamSubscriptionData,
  VideoStreamSubscriptionVariables,
} from '~/services/api/operations/subscriptions/SubscribeToVideoStream';
import QUERY_CARRIER_VIDEO_STREAM, {
  CarrierVideoStreamQueryData,
  CarrierVideoStreamQueryVariables,
} from '~/services/api/operations/queries/QueryCarrierVideoStream';

export default function useQueryWithSubscriptionCarrierVideoStream(carrierID: string | undefined): {
  videoStream?: CarrierVideoStreamQueryData['carrier']['videoStream'];
} {
  /**
   * Query
   * We need to fetch key and url for video stream and these are only available when streaming starts.
   */
  const {
    data: queryData,
    refetch,
    loading,
  } = useQuery<CarrierVideoStreamQueryData, CarrierVideoStreamQueryVariables>(
    QUERY_CARRIER_VIDEO_STREAM,
    {
      skip: !carrierID,
      variables: { carrierID: carrierID || '' },
      // Turn off loading from Apollo cache. We need to load new data every time the stream state changes
      // because we are polling for the new video stream details (like url and key).
      fetchPolicy: 'network-only',
      onCompleted: ({ carrier }) => {
        const streamState = carrier?.videoStream?.ivs_stream_state ?? 'NONE';
        // eslint-disable-next-line no-console
        console.log(`> video stream: loaded details: carrier: ${carrierID}, state: ${streamState}`);
      },
    },
  );

  if (loading) {
    // eslint-disable-next-line no-console
    console.log(`> video stream: loading details: carrier: ${carrierID}, state: loading`);
  }

  /**
   * Subscription
   * We need to subscribe for stream status change and load new details every time the state changes.
   * We need to use the query because not all data comes through the subscription now.
   * For example the stream key is not coming in the subscription and needs to be loaded separately.
   */
  useSubscription<VideoStreamSubscriptionData, VideoStreamSubscriptionVariables>(
    SUBSCRIBE_TO_VIDEO_STREAM,
    {
      skip: !carrierID,
      variables: { carrierID: carrierID || '' },
      onData: async ({ data }) => {
        const streamState = data?.data?.carrier?.carrier?.videoStream?.ivs_stream_state ?? 'NONE';
        // eslint-disable-next-line no-console
        console.log(`> video stream: state update: carrier: ${carrierID}, state: ${streamState}`);
        // Load updated details.
        await refetch();
      },
      shouldResubscribe: true,
    },
  );

  return { videoStream: queryData?.carrier?.videoStream };
}
