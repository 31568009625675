import styled from 'styled-components';
import Form from 'antd/lib/form';
import Title from 'antd/lib/typography/Title';
import Table from 'antd/lib/table/Table';
import Result from 'antd/lib/result';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import orderBy from 'lodash/orderBy';
import { USER_TYPES } from '~/types';
import i18n from '~/locales/i18n';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';
import {
  useQueryCompanyM2MTokens,
  useMutationCreateM2MToken,
  useMutationDeleteM2MToken,
} from '~/services/api/apis/useCompanyTokens';
import QUERY_TOKENS from '~/services/api/operations/queries/QueryCompanyM2MTokens';
import useCurrentUserContext from '~/context/CurrentUserContext';
import useCompany from '~/hooks/useCompany';
import { green } from '~/styles/colours';
import Input from '~/components/atoms/input/Input';
import Button from '~/components/atoms/button/Button';
import LoadingSpin from '~/components/atoms/LoadingSpin';
import Space from '~/components/atoms/space/Space';

const StyledTitle = styled(Title)`
  margin-bottom: 24px !important;
`;

const StyledResult = styled(Result)`
  padding: 0;
  margin: 32px;

  .ant-result-icon {
    margin-bottom: 0;

    .anticon {
      font-size: 48px;
    }
  }

  .ant-result-extra {
    margin-top: 16px;
  }
`;

const TokenValue = styled.div`
  word-break: break-word;
  border: solid 2px ${green};
  border-radius: 16px;
  padding: 8px 16px;
`;

interface FormFields {
  comment: string;
}

function Integrations() {
  const currentUser = useCurrentUserContext();
  const { currentCompanyIdentifier: companyId } = useCompany();
  const { tokens, loading } = useQueryCompanyM2MTokens(companyId, !companyId);
  const { createM2MToken, result, loading: isCreateLoading } = useMutationCreateM2MToken();
  const { deleteM2MToken, loading: isDeleteLoading } = useMutationDeleteM2MToken();
  const [form] = Form.useForm<FormFields>();

  const handleCopy = () => {
    if (result?.token) {
      navigator.clipboard.writeText(result.token);
      notification.success({
        message: i18n.t('clipboard.copySuccess'),
      });
    }
  };

  const handleSubmit = (values: FormFields) => {
    createM2MToken({
      variables: { companyId, comment: values.comment },
      refetchQueries: [{ query: QUERY_TOKENS, variables: { companyId } }, 'QueryCompanyM2MTokens'],
    });
    form.resetFields();
  };

  const handleDelete = (tokenId: string) => {
    deleteM2MToken({
      variables: { tokenId },
      refetchQueries: [{ query: QUERY_TOKENS, variables: { companyId } }, 'QueryCompanyM2MTokens'],
    });
  };

  const tableData = orderBy(tokens || [], ['created_at'], ['desc']).map((item) => ({
    ...item,
    key: item.id,
    createdAt: formatDateTime(item.created_at, 'standard'),
    actions: <DeleteOutlined onClick={() => handleDelete(item.id)} />,
  }));

  const tableColumns = [
    { dataIndex: 'id', title: i18n.t('integrations.tokenId') },
    { dataIndex: 'comment', title: i18n.t('integrations.comment') },
    { dataIndex: 'createdAt', title: i18n.t('integrations.createdAt') },
    { dataIndex: 'actions', title: i18n.t('integrations.actions') },
  ];

  if (currentUser?.type !== USER_TYPES.SUPER_ADMIN || !companyId) return null;

  return (
    <>
      <StyledTitle level={4}>{i18n.t('integrations.title')}</StyledTitle>
      {(loading || isDeleteLoading) && <LoadingSpin fullscreen />}
      <Form form={form} layout="vertical" onFinish={handleSubmit} style={{ maxWidth: 500 }}>
        <Form.Item name="comment" label={i18n.t('integrations.newTokenLabel')}>
          <Input placeholder={i18n.t<string>('integrations.newTokenPlaceholder')} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={isCreateLoading || !form.getFieldValue('comment')?.trim()}
            >
              {isCreateLoading ? <LoadingSpin /> : i18n.t('integrations.create')}
            </Button>
          )}
        </Form.Item>
      </Form>
      {result?.token && (
        <StyledResult
          status="success"
          title={i18n.t('integrations.createSuccessTitle')}
          subTitle={i18n.t('integrations.createSuccessDescription')}
          extra={
            <Space direction="vertical" size="small">
              <TokenValue>{result.token}</TokenValue>
              <Button type="default" onClick={handleCopy}>
                {i18n.t('integrations.copy')}
              </Button>
            </Space>
          }
        />
      )}
      {tableData?.length > 0 && (
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          data-id="m2m-tokens-table"
        />
      )}
    </>
  );
}

export default Integrations;
