import styled from 'styled-components';
import { lightGrey } from '~/styles/colours';

const CarrierDetailsPopupMidContentSpace = styled.div`
  display: grid;
  gap: 16px;
  padding: 16px;
  background: ${lightGrey};
  align-items: start;
  border-radius: 5px;
  grid-template-columns: repeat(2, 1fr);
`;

export default CarrierDetailsPopupMidContentSpace;
