import { useMemo } from 'react';
import { AGENT_STATUS } from '~/types';
import useAgentsContext from '~/context/AgentsContext';
import useTeams from '~/hooks/useTeams';

export default function useAgentsStatistics() {
  const { agents } = useAgentsContext();
  const { selectedTeams } = useTeams();

  const agentsCount = useMemo(
    () =>
      agents.filter(
        ({ isOffline, team }) =>
          !isOffline && (!selectedTeams.length || selectedTeams.includes(team)),
      ).length,
    [agents, selectedTeams],
  );

  const agentsStatusCounts = useMemo(() => {
    const agentsStatusCountsTemp = {
      [AGENT_STATUS.ALERT]: 0,
      [AGENT_STATUS.WARNING]: 0,
      [AGENT_STATUS.IN_MISSION]: 0,
      [AGENT_STATUS.IN_SAFE_ZONE]: 0,
      [AGENT_STATUS.CONNECTION_LOST]: 0,
    };

    agents.forEach(({ isOffline, status, team }) => {
      if (!isOffline && (!selectedTeams.length || selectedTeams.includes(team))) {
        agentsStatusCountsTemp[status] += 1;
      }
    });

    return agentsStatusCountsTemp;
  }, [agents, selectedTeams]);

  return { agentsCount, agentsStatusCounts };
}
