import { useEffect, useState, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProps } from 'antd';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import {
  AWSLoginResponse,
  AWSPasswordResetUserAttributes,
  AWSSetupMfaUserAttributes,
  AWS_AUTHENTICATION_RESPONSE_TYPE,
} from '~/types/awsService';
import routes from '~/navigation/config/routes';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import notification from '~/utils/notification';
import useAuthenticationContext from '~/context/AuthenticationContext';
import i18n from '~/locales/i18n';
import { black } from '~/styles/colours';
import Form from '~/components/atoms/form/Form';
import CenteredFormItem from '~/components/atoms/form/CenteredFormItem';
import AuthButton from '~/components/atoms/button/AuthButton';
import WearinLogoBlack from '~/components/atoms/logo/WearinLogoBlack';
import InputPassword from '~/components/atoms/input/InputPassword';
import Input from '~/components/atoms/input/Input';
import BlackLink from '~/components/atoms/link/BlackLink';
import LoadingSpin from '~/components/atoms/LoadingSpin';
import Text from '~/components/atoms/typography/Text';
import Modal from '~/components/atoms/Modal';

interface FormFields {
  username: string;
  password: string;
}

export default function LoginPage() {
  const [isLoading, setLoading] = useState(false);
  const { login } = useAuthenticationContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isTokenExpiredOverlayOpen, setIsTokenExpiredOverlayOpen] = useState(
    !!browserStorage.session.get(BROWSER_STORAGE_KEY.TOKEN_EXPIRED),
  );

  useEffect(() => {
    browserStorage.session.remove(BROWSER_STORAGE_KEY.TOKEN_EXPIRED);
  }, []);

  const handleFinish = ({ username, password }: FormFields) => {
    if (!login) return;

    setLoading(true);
    login(username, password)
      .then((user?: AWSLoginResponse) => {
        if (!user) return;

        switch (user.type) {
          case AWS_AUTHENTICATION_RESPONSE_TYPE.ON_SUCCESS:
            notification.success({
              message: i18n.t('login.successTitle'),
              description: i18n.t('login.successDescription'),
            });
            browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
            navigate(state?.from || routes.default());
            break;

          // newPasswordRequired - happens for new user first time login
          case AWS_AUTHENTICATION_RESPONSE_TYPE.COMPLETE_NEW_PASSWORD_CHALLENGE_EXCEPTION:
            notification.warning({
              message: i18n.t('login.successTitle'),
              description: i18n.t('login.newPasswordChallengeDescription'),
            });
            navigate(
              routes.newPasswordChallenge({
                email: (user.data?.userAttributes as AWSPasswordResetUserAttributes).email,
              }),
            );
            break;

          // can happen on login failure
          case AWS_AUTHENTICATION_RESPONSE_TYPE.PASSWORD_RESET_REQUIRED_EXCEPTION:
            notification.warning({
              message: i18n.t('login.successTitle'),
              description: i18n.t('login.passwordResetDescription'),
            });
            navigate(
              routes.resetPassword({
                email: (user.data?.userAttributes as AWSPasswordResetUserAttributes).email,
              }),
            );
            break;

          // happens on login and completeNewPasswordChallenge
          case AWS_AUTHENTICATION_RESPONSE_TYPE.MFA_SETUP:
            notification.warning({
              message: i18n.t('login.mfaSetupTitle'),
              description: i18n.t('login.mfaSetupDescription'),
            });
            navigate(
              routes.mfa({
                base64Image: (user.data?.userAttributes as AWSSetupMfaUserAttributes).base64Image,
              }),
            );
            break;

          // happens on totpRequired and completeNewPasswordChallenge's mfaSetup
          // but not on login's mfaSetup
          case AWS_AUTHENTICATION_RESPONSE_TYPE.SOFTWARE_TOKEN_MFA:
            notification.warning({
              message: i18n.t('login.successTitle'),
              description: i18n.t('login.totpDescription'),
            });
            navigate(routes.mfa({ mfaType: user.type }));
            break;

          default:
            break;
        }
      })
      .catch((catchedError: Error) => {
        switch (catchedError.name) {
          case AWS_AUTHENTICATION_RESPONSE_TYPE.USER_NOT_FOUND_EXCEPTION:
          case AWS_AUTHENTICATION_RESPONSE_TYPE.NOT_AUTHORIZED_EXCEPTION:
          case AWS_AUTHENTICATION_RESPONSE_TYPE.USER_NOT_CONFIRMED_EXCEPTION:
          default:
            notification.error({
              message: i18n.t('login.error'),
              description: catchedError.message,
            });
            break;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        title={i18n.t('dashboardOverlay.sessionExpiredTitle')}
        footer={null}
        centered
        width={500}
        open={isTokenExpiredOverlayOpen}
        onCancel={() => setIsTokenExpiredOverlayOpen(false)}
      >
        <Text>{i18n.t('dashboardOverlay.sessionExpiredDescription')}</Text>
      </Modal>
      <Form<FC<FormProps<FormFields>>> onFinish={handleFinish} data-id="login-form">
        <CenteredFormItem>
          <WearinLogoBlack />
        </CenteredFormItem>
        <CenteredFormItem
          name="username"
          colon={false}
          required={false}
          rules={[{ required: true, message: i18n.t<string>('login.invalidUsername') }]}
        >
          <Input
            prefix={<UserOutlined style={{ color: black }} />}
            placeholder={i18n.t<string>('login.username')}
            autoComplete="username"
            style={{ border: 'none' }}
          />
        </CenteredFormItem>
        <CenteredFormItem
          name="password"
          colon={false}
          required={false}
          rules={[{ required: true, message: i18n.t<string>('login.invalidPassword') }]}
        >
          <InputPassword
            placeholder={i18n.t<string>('login.password')}
            style={{ border: 'none' }}
          />
        </CenteredFormItem>
        <CenteredFormItem>
          <AuthButton disabled={isLoading}>
            {isLoading ? <LoadingSpin /> : i18n.t('login.submit')}
          </AuthButton>
        </CenteredFormItem>
        <CenteredFormItem>
          <BlackLink to={routes.forgotPassword()}>{i18n.t('login.forgotPassword')}</BlackLink>
        </CenteredFormItem>
      </Form>
    </>
  );
}
