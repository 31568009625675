import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import Tooltip from 'antd/lib/tooltip';
import NotificationOutlined from '@ant-design/icons/lib/icons/NotificationOutlined';
import SoundFilled from '@ant-design/icons/lib/icons/SoundFilled';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import AlertsToggleButton from '~/components/atoms/button/AlertsToggleButton';
import Text from '~/components/atoms/typography/Text';
import i18n from '~/locales/i18n';
import { alertSoundMuted } from '~/services/api/reactiveVariables/alertSoundMuted';
import { muteAlarm, unmuteAlarm } from '~/utils/sounds';

const StopIcon = styled(CloseOutlined)`
  transform: scale(0.6);
  margin-left: -2px;
`;

const StyledText = styled(Text)`
  padding-right: 10px;
`;

const AlertsToggleMuteButton = styled(AlertsToggleButton)`
  padding: 0 10px;
`;

export default function MuteButton() {
  const muted = useReactiveVar(alertSoundMuted);

  const toggleAlarm = () => (muted ? unmuteAlarm() : muteAlarm());

  return (
    <Tooltip
      title={i18n.t(muted ? 'muteButton.unmuteTooltipMessage' : 'muteButton.muteTooltipMessage')}
    >
      <AlertsToggleMuteButton onClick={toggleAlarm} data-id="mute-btn">
        <StyledText>{i18n.t(muted ? 'muteButton.unmute' : 'muteButton.mute')}</StyledText>
        {muted ? (
          <>
            <NotificationOutlined />
            <StopIcon />
          </>
        ) : (
          <SoundFilled />
        )}
      </AlertsToggleMuteButton>
    </Tooltip>
  );
}
