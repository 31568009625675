export const convertMpsToKmh = (mpsValue: number) => mpsValue * 3.6;

export const numberToString = (number?: number, unit?: string): string =>
  typeof number === 'number' && // Checking if the type is a number of undefined
  number >= 0 // Checking if the number isn't NaN
    ? `${number.toString()}${unit ? ` ${unit}` : ''}`
    : 'n.a.';

export function parseJSON(variable: unknown) {
  try {
    return typeof variable === 'string' ? JSON.parse(variable) : variable;
  } catch (e) {
    // TODO: should error be logged?
    return variable;
  }
}

const parseBoolean = (text: string): boolean | string => {
  switch (text) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return text;
  }
};

export function transformAttributes<T>(
  attributes: { name: string; value: string; __typename?: string }[],
): T {
  return attributes
    ?.filter(
      // eslint-disable-next-line dot-notation
      (obj) => obj.name && obj['__typename'] === 'Attribute',
    )
    .reduce(
      (obj, item) => ({
        ...obj,
        [item.name]: parseBoolean(item.value),
      }),
      {} as T,
    );
}
