import styled from 'styled-components';
import { white } from '~/styles/colours';

const Div = styled('div')`
  max-width: 210px;
  padding: 0 16px;
`;

const H2 = styled('h2')`
  color: ${white};
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface Props {
  name: string | undefined;
}

export default function NavBarEntityName({ name }: Props) {
  if (!name) return null;

  return (
    <Div>
      <H2>{name}</H2>
    </Div>
  );
}
