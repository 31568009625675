import { ApolloError, useQuery } from '@apollo/client';
import QUERY_COMPANY_DETAILS, {
  CompanyDetailsQueryData,
  CompanyDetailsQueryVariables,
} from '~/services/api/operations/queries/QueryCompanyDetails';

export default function useQueryCompanyDetails({
  companyID,
  skip = false,
}: {
  companyID: string;
  skip?: boolean;
}): {
  companyDetails?: CompanyDetailsQueryData['company'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<CompanyDetailsQueryData, CompanyDetailsQueryVariables>(
    QUERY_COMPANY_DETAILS,
    {
      variables: { companyID },
      skip,
    },
  );

  return {
    companyDetails: data?.company,
    isLoading: loading,
    error,
  };
}
