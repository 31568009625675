import styled from 'styled-components';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import type { SpinProps } from 'antd/lib/spin';
import Spin from 'antd/lib/spin';
import { Z_INDEX } from '~/styles/layers';

const FullscreenSpin = styled(Spin)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${Z_INDEX.LOADING_SPIN};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface LoadingSpinProps extends SpinProps {
  fullscreen?: boolean;
}

function LoadingSpin({ fullscreen, ...props }: LoadingSpinProps) {
  return fullscreen ? (
    <FullscreenSpin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} {...props} />
  ) : (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} {...props} />
  );
}

export default LoadingSpin;
