import i18next from 'i18next';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { thinGrey } from '~/styles/colours';
import { getAgentStatusColor } from '~/utils/agent';
import useAgentsStatistics from '~/hooks/useAgentsStatistics';
import useAgentStatuses, { AGENT_STATUSES } from '~/hooks/useAgentStatuses';
import Text from '~/components/atoms/typography/Text';
import Title from '~/components/atoms/typography/Title';

const GUTTER = '12px';

const GridDiv = styled('div')`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, auto));
  align-items: center;
  gap: ${GUTTER};
  padding: 0 ${GUTTER};
  height: 48px;
  background: white;
  border: 1px solid ${thinGrey};
  border-radius: 8px;
`;

const StatusButton = styled('button')`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const CircleDiv = styled('div')<{ $bulletColor: string }>`
  align-items: center;
  background: ${(props) => props.$bulletColor};
  border: 1px solid ${(props) => props.$bulletColor};
  border-radius: 32px;
  color: white;
  display: flex;
  font-weight: regular;
  height: 32px;
  justify-content: center;
  width: 32px;
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function AgentStatusFilter() {
  const { agentsCount, agentsStatusCounts } = useAgentsStatistics();
  const { selectedAgentStatuses, toggleSelectedAgentStatus } = useAgentStatuses();

  return (
    <GridDiv>
      <Title spaceless level={5} data-id="agents-status-widget-agents-count">
        {`${i18next.t('general.generalAlarms.agent', { count: agentsCount })}:`}
      </Title>
      {AGENT_STATUSES.map((status) => {
        const isDefault = selectedAgentStatuses.length === 0;
        const isSelected = selectedAgentStatuses.includes(status);
        const statusTranslation = i18next.t(`general.generalAlarms.${status}`);

        return (
          <Tooltip
            key={status}
            placement="bottom"
            title={i18next.t(
              `general.generalAlarms.${isSelected ? 'hide' : 'view'}AgentsFromStatus`,
              { status: statusTranslation.toLocaleLowerCase() },
            )}
            arrow
          >
            <StatusButton
              onClick={() => toggleSelectedAgentStatus(status)}
              style={{ opacity: isDefault || isSelected ? 1 : 0.2 }}
            >
              <CircleDiv
                $bulletColor={getAgentStatusColor(status)}
                data-id={`agents-status-widget-${status}-count`}
              >
                {agentsStatusCounts[status]}
              </CircleDiv>
              <StyledText>{statusTranslation}</StyledText>
            </StatusButton>
          </Tooltip>
        );
      })}
    </GridDiv>
  );
}
