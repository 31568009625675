import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Menu } from 'antd';
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined';
import { USER_TYPES } from '~/types/user';
import i18n from '~/locales/i18n';
import routes from '~/navigation/config/routes';
import { black, midGrey } from '~/styles/colours';
import * as medias from '~/styles/medias';
import useCurrentUserContext from '~/context/CurrentUserContext';
import useQueryParams from '~/hooks/useQueryParams';
import useCompany from '~/hooks/useCompany';
import ChangePasswordForm from '~/components/form/ChangePasswordForm';
import UserProfile from '~/components/UserProfile';
import Integrations from '~/components/Integrations';
import SectionHeader from '~/components/atoms/typography/SectionHeader';
import CompanyFeatures from '~/components/CompanyFeatures';
import SubsidiaryDetails from '~/components/SubsidiaryDetails';

const GridDiv = styled('div')`
  margin: 0 16px 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  ${medias.gteSmall} {
    grid-template-columns: minmax(0, 300px) minmax(0, 1fr);
    grid-gap: 32px;
  }
`;

const StyledMenu = styled(Menu)`
  background: transparent;
  border: none !important;

  > li.ant-menu-item {
    border-bottom: solid 1px ${midGrey} !important;
    margin: 8px 0;
    padding-left: 0;
    padding-right: 0;
    color: ${black};
    background: transparent !important;
    border-radius: 0;

    &.ant-menu-item-selected {
      font-weight: bold;
    }
  }
`;

const MenuItemLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabTitle = styled('h3')`
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 8px;
`;

enum TabEnum {
  profile = 'profile',
  security = 'security',
  integrations = 'integrations',
  companyFeatures = 'companyFeatures',
  subsidiaryDetails = 'subsidiaryDetails',
}

export default function SettingsPage() {
  const { currentCompanyIdentifier } = useCompany();
  const currentUser = useCurrentUserContext();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [tab, setTab] = useState<TabEnum>((queryParams.tab as TabEnum) || TabEnum.profile);
  const tabs = [
    TabEnum.profile,
    TabEnum.security,
    ...(currentCompanyIdentifier && currentUser?.type === USER_TYPES.SUPER_ADMIN
      ? [TabEnum.integrations]
      : []),
    TabEnum.companyFeatures,
    TabEnum.subsidiaryDetails,
  ];

  const handleTabSelect = (selectedTab: TabEnum) => {
    setTab(selectedTab);
    navigate(routes.settings({ ...queryParams, tab: selectedTab }));
  };

  return (
    <>
      <SectionHeader
        title={i18n.t('settingsPage.title')}
        subtitle={i18n.t('settingsPage.subtitle')}
      />
      <GridDiv>
        <StyledMenu
          selectedKeys={[tab]}
          onClick={(event) => handleTabSelect(event.key as TabEnum)}
          mode="vertical"
          items={tabs.map((key) => ({
            key,
            label: (
              <MenuItemLabel>
                {i18n.t(`settingsPage.${key}`)}
                <RightOutlined />
              </MenuItemLabel>
            ),
          }))}
        />
        <div>
          <TabTitle>{i18n.t(`settingsPage.${tab}`)}</TabTitle>
          {tab === TabEnum.profile && <UserProfile />}
          {tab === TabEnum.security && <ChangePasswordForm />}
          {tab === TabEnum.integrations && <Integrations />}
          {tab === TabEnum.companyFeatures && <CompanyFeatures />}
          {tab === TabEnum.subsidiaryDetails && <SubsidiaryDetails />}
        </div>
      </GridDiv>
    </>
  );
}
