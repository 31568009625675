import { useEffect, useState } from 'react';
import Collapse from 'antd/lib/collapse/Collapse';
import styled from 'styled-components';
import { AGENT_STATUS } from '~/types/alarm';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import CarrierDetailsPopupMissionStartedAndDuration from '~/components/DetailsPopup/content/headContent/CarrierDetailsPopupMissionStartedAndDuration';
import CarrierDetailsPopupMidContentSpace from '~/components/atoms/space/CarrierDetailsPopupMidContentSpace';
import { INFORMATION_TYPES } from './components/CarrierDetailsPopupInfoType';
import CarrierDetailsPopupInfoTypeList from './components/CarrierDetailsPopupInfoTypeList';

const CarrierDetailsPopupMidContentSpaceCompact = styled(CarrierDetailsPopupMidContentSpace)`
  grid-template-columns: initial;
`;

const CollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    display: grid;
    gap: 16px;
  }
`;

interface Props {
  count: number;
}

export default function CarrierDetailsPopupMidContent({ count }: Props) {
  const { agent } = useCarrierDetailsPopupContext();
  const hasAlert = agent?.status === AGENT_STATUS.ALERT;
  const [isOpen, setIsOpen] = useState(hasAlert);
  const companyFeatures = useCompanyFeatures();

  useEffect(() => {
    if (hasAlert) setIsOpen(true);
  }, [hasAlert]);

  if (!agent) return null;

  const sensorList = (
    <>
      {/* protection from additional spacing because we are showing a list of lists */}
      {(companyFeatures.impactDetectionFront || companyFeatures.impactDetectionBack) && (
        <CarrierDetailsPopupInfoTypeList informationTypeList={[INFORMATION_TYPES.TRAAK]} />
      )}
      <CarrierDetailsPopupInfoTypeList informationTypeList={[INFORMATION_TYPES.VIDEO_STREAMING]} />
      <CarrierDetailsPopupInfoTypeList informationTypeList={[INFORMATION_TYPES.EQUIPMENT]} />
      {companyFeatures.biometricData &&
        (companyFeatures.heartRateSensor || companyFeatures.bodyTemperatureSensor) && (
          <CarrierDetailsPopupInfoTypeList
            informationTypeList={[INFORMATION_TYPES.PHYSIOLOGICAL_SENSORS]}
          />
        )}
      {companyFeatures.gasSensor && (
        <CarrierDetailsPopupInfoTypeList informationTypeList={[INFORMATION_TYPES.GAS_SENSOR]} />
      )}
    </>
  );

  if (count > 2) {
    return (
      <Collapse activeKey={isOpen ? agent.id : ''} onChange={() => setIsOpen(!isOpen)}>
        <CollapsePanel
          header={
            <CarrierDetailsPopupInfoTypeList
              informationTypeList={[INFORMATION_TYPES.SOS_ALERT]}
              count={count}
            />
          }
          key={agent.id}
        >
          <CarrierDetailsPopupMissionStartedAndDuration agent={agent} />
          {sensorList}
        </CollapsePanel>
      </Collapse>
    );
  }

  const Container =
    count === 2 ? CarrierDetailsPopupMidContentSpaceCompact : CarrierDetailsPopupMidContentSpace;

  return (
    <Container>
      <CarrierDetailsPopupInfoTypeList
        informationTypeList={[INFORMATION_TYPES.SOS_ALERT]}
        count={count}
      />
      {sensorList}
    </Container>
  );
}
