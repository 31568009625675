import { useEffect } from 'react';
import notification from '~/utils/notification';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import i18n from '~/locales/i18n';

export default function RefreshTokenExpirationWarning() {
  useEffect(() => {
    const loginTime = Number(browserStorage.local.get(BROWSER_STORAGE_KEY.LOGIN_TIME));

    if (!loginTime) return undefined;

    const key = Date.now().toString();
    const warningTimeout = loginTime + 23 * 60 * 60 * 1000 - Date.now();
    const warningTimeoutId = window.setTimeout(() => {
      notification.warning({
        key,
        message: i18n.t('refreshTokenExpirationWarning.title'),
        description: i18n.t('refreshTokenExpirationWarning.description'),
        duration: null,
      });
    }, warningTimeout);

    return () => {
      window.clearTimeout(warningTimeoutId);
      notification.destroy(key);
    };
  }, []);

  return null;
}
