import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, FormProps } from 'antd';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import routes from '~/navigation/config/routes';
import notification from '~/utils/notification';
import useAuthenticationContext from '~/context/AuthenticationContext';
import i18n from '~/locales/i18n';
import { black } from '~/styles/colours';
import Form from '~/components/atoms/form/Form';
import CenteredFormItem from '~/components/atoms/form/CenteredFormItem';
import AuthButton from '~/components/atoms/button/AuthButton';
import WearinLogoBlack from '~/components/atoms/logo/WearinLogoBlack';
import BlackLink from '~/components/atoms/link/BlackLink';
import Input from '~/components/atoms/input/Input';

interface FormFields {
  username: string;
}

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const { forgotPassword } = useAuthenticationContext();
  const navigate = useNavigate();

  const handleFinish = ({ username }: FormFields) => {
    setLoading(true);
    forgotPassword({ email: username })
      .then(() => {
        notification.success({
          message: i18n.t('forgotPassword.successTitle'),
          description: i18n.t('forgotPassword.successDescription'),
        });
        navigate(routes.resetPassword({ email: username }));
      })
      .catch((catchedError: Error) => {
        notification.error({
          message: i18n.t('forgotPassword.failTitle'),
          description: catchedError.message,
        });
        setLoading(false);
      });
  };

  return (
    <Form<FC<FormProps<FormFields>>> onFinish={handleFinish}>
      <CenteredFormItem>
        <WearinLogoBlack />
      </CenteredFormItem>
      <CenteredFormItem
        name="username"
        rules={[
          {
            required: true,
            message: i18n.t<string>('forgotPassword.invalidUsername'),
          },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: black }} />}
          placeholder={i18n.t<string>('forgotPassword.username')}
        />
      </CenteredFormItem>
      <CenteredFormItem>
        <AuthButton style={{ width: '100%' }}>
          {loading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            i18n.t('forgotPassword.submit')
          )}
        </AuthButton>
      </CenteredFormItem>
      <CenteredFormItem>
        <BlackLink to={routes.login()}>{i18n.t('forgotPassword.goBack')}</BlackLink>
      </CenteredFormItem>
    </Form>
  );
}
