import { TitleProps } from 'antd/lib/typography/Title';
import { white } from '~/styles/colours';
import Text from '~/components/atoms/typography/Text';

interface DashboardInitialsBulletProps extends TitleProps {
  smallSize?: boolean;
  label?: string;
  color: string;
}

function DashboardInitialsBullet({
  smallSize = false,
  label = '',
  color,
  ...props
}: DashboardInitialsBulletProps) {
  const style = {
    alignItems: 'center',
    color: white,
    display: 'flex',
    fontWeight: 'regular',
    justifyContent: 'center',
    borderRadius: smallSize ? '18px' : '32px',
    marginRight: smallSize ? '8px' : '0',
    background: color,
    fontSize: smallSize ? '12px' : '16px',
    height: smallSize ? '36px' : '48px',
    border: `1px solid ${color}`,
    width: smallSize ? '36px' : '48px',
    margin: '0',
  };

  return (
    <Text style={style} {...props}>
      {label}
    </Text>
  );
}

export default DashboardInitialsBullet;
