import styled from 'styled-components';
import { thinGrey, white } from '~/styles/colours';
import { Z_INDEX } from '~/styles/layers';
import { NAVBAR_HEIGHT } from '~/styles/dimensions';
import useAgentsContext from '~/context/AgentsContext';

const GUTTER = '8px';

const StyledContainer = styled.div<{ $hasAlert?: boolean }>`
  position: fixed;
  left: 0;
  top: ${NAVBAR_HEIGHT}px;
  width: 772px;
  border: 0.5px solid ${thinGrey};
  margin: ${GUTTER};
  height: calc(
    100% - ${NAVBAR_HEIGHT}px - ${GUTTER} - ${GUTTER} -
      ${(props) => (props.$hasAlert ? '56px' : '0px')}
  );
  z-index: ${Z_INDEX.MAIN_LEVEL};
  overflow: auto;
  background: ${white};
  user-select: none;
  border-radius: 10px;
`;

type Props = {
  'data-id'?: string;
  children: React.ReactNode;
};

export default function DetailsPopupSpace(props: Props) {
  const { hasAlert } = useAgentsContext();

  return <StyledContainer $hasAlert={hasAlert} {...props} />;
}
