import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Switch } from 'antd';
import snakeCase from 'lodash/snakeCase';
import { USER_TYPES } from '~/types';
import i18n from '~/locales/i18n';
import useMutationSetCompanyAttribute from '~/services/api/apis/useMutationSetCompanyAttribute';
import useCurrentUserContext from '~/context/CurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';

const H3 = styled('h3')`
  font-size: 12px;
  margin-bottom: 16px;
  font-weight: normal;
`;

const H4 = styled('h4')`
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
`;

const Ul = styled('ul')`
  margin: 0 0 22px;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`;

const Li = styled('li')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default function CompanyFeatures() {
  const { setCompanyAttribute, isLoading } = useMutationSetCompanyAttribute();
  const { currentCompany } = useCompany();
  const features = useCompanyFeatures();
  const currentUser = useCurrentUserContext();
  const [selectedFeatures, setSelectedFeatures] = useState<typeof features>(features);

  useEffect(() => {
    if (features) {
      setSelectedFeatures(features);
    }
  }, [features]);

  const isSuperAdmin = currentUser?.type === USER_TYPES.SUPER_ADMIN;

  const {
    emergencyButton,
    biometricData,
    heartRateSensor,
    bodyTemperatureSensor,
    gasSensor,
    impactDetectionFront,
    impactDetectionBack,
    ...remainingFeatures
  } = selectedFeatures;

  type FeatureEntries = [keyof typeof features, boolean][];

  const generalFeatures = Object.entries(remainingFeatures) as FeatureEntries;
  const sensorsFeatures = Object.entries({
    emergencyButton,
    biometricData,
    heartRateSensor,
    bodyTemperatureSensor,
    gasSensor,
    impactDetectionFront,
    impactDetectionBack,
  }) as FeatureEntries;

  const renderFeatures = (featuresEntries: FeatureEntries) => (
    <Ul>
      {featuresEntries.map(([featureKey, isEnabled]) => (
        <Li key={featureKey}>
          <Switch
            checked={isEnabled}
            disabled={!isSuperAdmin}
            loading={isLoading}
            onChange={(checked) => {
              setCompanyAttribute({
                name: `feature_${snakeCase(featureKey)}`,
                value: checked ? 'enabled' : 'disabled',
              });
            }}
          />
          <span style={{ opacity: isEnabled ? 1 : 0.5 }}>
            {i18n.t(`companyFeatures.${featureKey}`)}
          </span>
        </Li>
      ))}
    </Ul>
  );

  return (
    <div>
      <H3>{currentCompany?.name || ``}</H3>
      <H4>{i18n.t('companyFeatures.general')}</H4>
      {renderFeatures(generalFeatures)}
      <H4>{i18n.t('companyFeatures.sensors')}</H4>
      {renderFeatures(sensorsFeatures)}
    </div>
  );
}
