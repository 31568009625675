import { useMemo } from 'react';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import SosVehicleDetailsPopup from './sosVehicleDetailsPopup/SosVehicleDetailsPopup';
import { VehicleDetailsPopupContextProvider } from './VehicleDetailsPopupContext';

export interface VehicleDetailsPopupProps {
  vehicleID: string;
}

export default function VehicleDetailsPopup({ vehicleID }: VehicleDetailsPopupProps) {
  const features = useCompanyFeatures();

  const vehicleDetailsPopup = useMemo(
    () => (features.vehicles ? <SosVehicleDetailsPopup /> : null),
    [features.vehicles],
  );

  return (
    <VehicleDetailsPopupContextProvider plateNumber={vehicleID}>
      {vehicleDetailsPopup}
    </VehicleDetailsPopupContextProvider>
  );
}
