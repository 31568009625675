import styled from 'styled-components';
import type { ButtonProps } from 'antd';
import { blue } from '~/styles/colours';
import Button from './Button';

function SecondaryButton(props: ButtonProps) {
  return <Button size="large" {...props} />;
}

export const SecondarySmallButton = styled(SecondaryButton)`
  align-items: center;
  color: ${blue};
  border-radius: 2px;
  display: flex;
  border: 1px solid ${blue};
`;

// export const SecondaryLargeButton = styled(SecondaryButton)`
//   padding-left: 19px;
//   padding-right: 18px;
//   border-radius: 4px;
//   display: flex;
//   border: 0.5px solid ${darkBlue};
// `;
