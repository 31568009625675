import countBy from 'lodash/countBy';
import { Select } from 'antd';
import type { SelectProps } from 'antd';
import i18n from '~/locales/i18n';
import useAgentsContext from '~/context/AgentsContext';
import useTeams from '~/hooks/useTeams';

export default function TeamsFilter({ className }: SelectProps) {
  const { agents } = useAgentsContext();
  const { selectedTeams, setSelectedTeams } = useTeams();

  const teams = countBy(
    agents?.map(({ team }) => team)?.filter((teamName) => teamName),
    (teamName) => teamName,
  );
  const options: SelectProps['options'] = Object.entries(teams).map(([team, count]) => ({
    value: team,
    label: `${team} (${count})`,
  }));

  return (
    <Select<string[]>
      options={options}
      placeholder={i18n.t('teamsFilter.placeholder')}
      value={selectedTeams}
      onChange={setSelectedTeams}
      mode="multiple"
      allowClear
      size="large"
      className={className}
    />
  );
}
