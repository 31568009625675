import { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import QUERY_COMPANY_SUBSIDIARY_LIST, {
  CompanySubsidiaryListQueryData,
  CompanySubsidiaryListQueryVariables,
} from '~/services/api/operations/queries/QueryCompanySubsidiaryList';

export default function useQueryCompanySubsidiaryList(
  companyID: string,
  skip = false,
): {
  subsidiaryList: CompanySubsidiaryListQueryData['company']['subsidiaries']['items'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<
    CompanySubsidiaryListQueryData,
    CompanySubsidiaryListQueryVariables
  >(QUERY_COMPANY_SUBSIDIARY_LIST, {
    variables: { companyID },
    skip,
  });

  return useMemo(
    () => ({
      subsidiaryList: data?.company?.subsidiaries?.items || [],
      isLoading: loading,
      error,
    }),
    [data?.company?.subsidiaries?.items, loading, error],
  );
}
