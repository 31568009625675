import { I18nextProvider } from 'react-i18next';
import i18n from '~/locales/i18n';
import AppRouter from '~/navigation/AppRouter';
import GlobalStyles from '~/styles/global.css';
import { AppContextProvider } from '~/context/AppContext';
import { AuthenticationContextProvider } from '~/context/AuthenticationContext';
import { AppSyncApolloProvider } from '~/context/AppSyncApolloContext';
import DeveloperConsole from '~/components/DeveloperConsole';

export default function App() {
  return (
    <AppContextProvider>
      <I18nextProvider i18n={i18n}>
        <AuthenticationContextProvider>
          <AppSyncApolloProvider>
            <GlobalStyles />
            <AppRouter />
            <DeveloperConsole />
          </AppSyncApolloProvider>
        </AuthenticationContextProvider>
      </I18nextProvider>
    </AppContextProvider>
  );
}
