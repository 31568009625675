import { gql } from '@apollo/client';
import type { AlarmType, ALARM_TYPES } from '~/types';

export interface SubsidiaryAlarmSubscriptionVariables {
  subsidiaryID: string;
  type: ALARM_TYPES;
}

export interface SubsidiaryAlarmSubscriptionData {
  alarm: {
    carrier_id: string;
    subsidiary_id: string;
    type: string;
    alarm: AlarmType;
  };
}

export default gql`
  subscription SubscribeToSubsidiaryAlarmType($subsidiaryID: ID!, $type: String!) {
    alarm(subsidiary_id: $subsidiaryID, type: $type) {
      carrier_id
      subsidiary_id
      type
      alarm {
        created_at
        dismiss_reason
        dismiss_requested_at
        dismissed_at
        id
        type
        value
        administrator {
          id
          email
          ... on SubsidiaryAdmin {
            id
            email
          }
          ... on CompanyAdmin {
            id
            email
          }
        }
      }
    }
  }
`;
