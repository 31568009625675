import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { AWSLoginResponse } from '~/types';
import LoadingSpin from '~/components/atoms/LoadingSpin';
import useAWSCognitoService, { AWSCognitoService } from './useAWSCognitoService';

interface AuthenticationContextType {
  getUser: () => CognitoUser | null;
  getUserSession: () => Promise<CognitoUserSession | null>;
  login: (Username: string, Password: string) => Promise<AWSLoginResponse | undefined>;
  logout: () => Promise<boolean>;
  isAuthenticated: boolean;
  verifyMFASetup: AWSCognitoService['verifyMFASetup'];
  verifyMFA: AWSCognitoService['verifyMFA'];
  verifyConfirmationCode: AWSCognitoService['verifyConfirmationCode'];
  changePassword: AWSCognitoService['changePassword'];
  completeNewPasswordChallenge: AWSCognitoService['completeNewPasswordChallenge'];
  forgotPassword: AWSCognitoService['forgotPassword'];
  passwordReset: AWSCognitoService['passwordReset'];
  getIdToken: AWSCognitoService['getIdToken'];
  refreshSession: AWSCognitoService['refreshSession'];
}

const initialValues: AuthenticationContextType = {
  getUser: () => null,
  getUserSession: async () => null,
  login: async () => undefined,
  logout: async () => false,
  isAuthenticated: false,
  verifyMFASetup: async () => false,
  verifyMFA: async () => undefined,
  verifyConfirmationCode: async () => undefined,
  changePassword: async () => undefined,
  completeNewPasswordChallenge: async () => undefined,
  forgotPassword: async () => undefined,
  passwordReset: async () => undefined,
  getIdToken: async () => undefined,
  refreshSession: async () => null,
};

const AuthenticationContext = createContext<AuthenticationContextType>(initialValues);

const useAuthenticationContext = () => useContext(AuthenticationContext);

export function AuthenticationContextProvider({ children }: { children: ReactNode }) {
  const isComponentMounted = useRef(true);
  const [state, setState] = useState({ isLoading: true, isAuthenticated: false });
  const awsCognitoService = useAWSCognitoService();

  const getUser = useCallback(
    (): CognitoUser | null => awsCognitoService?.getUser() || null,
    [awsCognitoService],
  );

  const getUserSession = useCallback(
    (): Promise<CognitoUserSession | null> =>
      new Promise((resolve) => {
        if (awsCognitoService) {
          (async () => {
            const user = awsCognitoService?.getUser();
            if (user) {
              user.getSession((error: Error, userSession: CognitoUserSession | null) => {
                if (error) {
                  resolve(null);
                } else {
                  resolve(userSession);
                }
              });
            } else {
              resolve(null);
            }
          })();
        } else {
          resolve(null);
        }
      }),
    [awsCognitoService],
  );

  const login = useCallback(
    (Username: string, Password: string): Promise<AWSLoginResponse | undefined> =>
      new Promise((resolve, reject) => {
        if (awsCognitoService?.login) {
          const willingToWaitTimeout = setTimeout(() => {
            const timeoutError: Error = new Error();
            timeoutError.name = 'LOGIN_TIMEOUT';
            // timeoutError.code = 'TIMEOUT';
            timeoutError.message = 'Timeout while performing login';
            reject(timeoutError);
          }, 10_000);
          awsCognitoService
            .login({ Username, Password })
            .then((success) => {
              resolve(success);
            })
            .catch((error: Error) => {
              const exceptionError = new Error();
              exceptionError.name = error.name;
              // exceptionError.code = error.code;
              exceptionError.message = error.message;
              reject(exceptionError);
            })
            .finally(() => {
              clearTimeout(willingToWaitTimeout);
            });
        } else {
          reject(new Error('Auth Service has not being initialized'));
        }
      }),
    [awsCognitoService],
  );

  const logout = useCallback(
    (): Promise<boolean> =>
      new Promise((resolve, reject) => {
        if (awsCognitoService?.logout) {
          awsCognitoService.logout().then((success) => {
            resolve(success);
          });
        } else {
          reject(Error('Injected Auth Service is invalid'));
        }
      }),
    [awsCognitoService],
  );

  useEffect(
    () => () => {
      isComponentMounted.current = false;
    },
    [],
  );

  useEffect(() => {
    const getIsAuthenticated = async () => {
      const userSession = await getUserSession();

      return userSession ? userSession.isValid() : false;
    };

    (async () => {
      const isAuthenticated = await getIsAuthenticated();

      if (
        (isAuthenticated !== state.isAuthenticated || state.isLoading) &&
        isComponentMounted.current
      ) {
        setState({ isLoading: false, isAuthenticated });
      }
    })();
  }, [getUserSession, state.isAuthenticated, state.isLoading]);

  const value = useMemo(() => {
    const service = awsCognitoService || initialValues;

    return {
      getUser,
      getUserSession,
      login,
      logout,
      isAuthenticated: state.isAuthenticated,
      verifyMFASetup: service.verifyMFASetup,
      verifyMFA: service.verifyMFA,
      verifyConfirmationCode: service.verifyConfirmationCode,
      changePassword: service.changePassword,
      completeNewPasswordChallenge: service.completeNewPasswordChallenge,
      forgotPassword: service.forgotPassword,
      passwordReset: service.passwordReset,
      getIdToken: service.getIdToken,
      refreshSession: service.refreshSession,
    };
  }, [getUser, getUserSession, login, logout, state.isAuthenticated, awsCognitoService]);

  if (state.isLoading || !awsCognitoService) return <LoadingSpin fullscreen />;

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
}

export default useAuthenticationContext;
