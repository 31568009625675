import { useEffect, useMemo, useRef } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
  useReactiveVar,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { GraphQLError } from 'graphql/error/GraphQLError';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import config from '~/config/config';
import { APOLLO_AUTH_TYPE } from '~/types';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import { reconnectTimestamp } from '~/services/api/reactiveVariables/reconnectTimestamp';
import { getNewCacheInstance } from '~/services/api/cache';
import useAuthenticationContext from '~/context/AuthenticationContext';
import { AgentsContextProvider } from '~/context/AgentsContext';
import { CurrentUserContextProvider } from '~/context/CurrentUserContext';

// eslint-disable-next-line no-console
const throttledLogToken = throttle((exp?: number) => console.log('expired jwtToken', exp), 60_000);

type ApolloGraphQLError = GraphQLError & { errorType?: string };

function useAppSyncApolloClient(): ApolloClient<NormalizedCacheObject> | undefined {
  const { getIdToken, isAuthenticated, logout, refreshSession } = useAuthenticationContext();
  // changing reconnectTimestamp from any place will trigger this hook
  // this will lead to new client being created and connections being established again
  const reconnectTimestampValue = useReactiveVar(reconnectTimestamp);
  // need to have client on initial render because PrivateLayout(whose children use
  // apollo query hooks) is mounted before ApolloProvider(react child components
  // mount before parent ones and we are creating apollo client on this hook's mount).
  const clientRef = useRef<ApolloClient<NormalizedCacheObject> | undefined>(
    new ApolloClient({ cache: getNewCacheInstance() }),
  );

  const reconnect = useMemo(
    () =>
      debounce(() => {
        console.log('reconnecting'); // eslint-disable-line no-console
        reconnectTimestamp(Date.now());
      }, 100),
    [],
  );

  useEffect(
    () => () => {
      reconnect.cancel();
    },
    [reconnect],
  );

  const stopClient = () => {
    const client = clientRef.current;

    clientRef.current = undefined;

    console.log('clearing store'); // eslint-disable-line no-console
    client?.clearStore().finally(() => {
      console.log('stopping previous apollo client'); // eslint-disable-line no-console
      client?.stop();
    });
  };

  stopClient();

  if (isAuthenticated) {
    const url = config.awsConfig.aws_appsync_graphqlEndpoint;
    const region = config.awsConfig.aws_appsync_region;
    const auth = {
      type: config.awsConfig.aws_appsync_authenticationType as APOLLO_AUTH_TYPE,
      apiKey: config.awsConfig.aws_appsync_apiKey ?? '',
      jwtToken: async () => {
        const token = await getIdToken();
        const expiration = token?.getExpiration();

        if ((expiration || 0) * 1000 < Date.now()) throttledLogToken(expiration);

        return token?.jwtToken || '';
      },
    };
    const authLink = createAuthLink({ url, region, auth });
    // https://docs.aws.amazon.com/appsync/latest/devguide/aws-appsync-real-time-data.html
    // Solution for Pure WebSockets https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/628#issue-834140203
    const subscriptionLink = createSubscriptionHandshakeLink({
      url,
      region,
      auth,
      keepAliveTimeoutMs: 5 * 60 * 1000,
    });
    const errorLink = onError(({ graphQLErrors, networkError, ...rest }) => {
      // eslint-disable-next-line no-console
      console.error('errorLink onError', rest, graphQLErrors, networkError);

      if (
        graphQLErrors?.some(
          (graphQLError) =>
            (graphQLError as ApolloGraphQLError).errorType === 'UnauthorizedException',
        )
      ) {
        console.log('UnauthorizedException refreshing session'); // eslint-disable-line no-console
        refreshSession().catch(() => {
          console.log('refresh session unsuccessful, logout'); // eslint-disable-line no-console
          browserStorage.session.set(BROWSER_STORAGE_KEY.TOKEN_EXPIRED, true);
          logout();
        });

        // TODO: retry
        // return rest.forward(rest.operation);
        return;
      }

      if (networkError) {
        const networkErrorMessage: string = (networkError as any).errors?.[0]?.message || '';

        if (
          networkErrorMessage.includes('Connection closed') ||
          networkErrorMessage.includes('Timeout disconnect')
        ) {
          console.log('Connection closed/timeout'); // eslint-disable-line no-console
          reconnect();
        }
      }
    });
    // retryLink could be added before errorLink but in case of
    // MaxSubscriptionsReachedError it would multiply it several times.
    const link = ApolloLink.from([errorLink, authLink, subscriptionLink]);

    clientRef.current = new ApolloClient({ link, cache: getNewCacheInstance() });

    console.log('created new apollo client'); // eslint-disable-line no-console

    window.getApolloStoreCache = (): Record<string, unknown> => {
      try {
        return JSON.parse(JSON.stringify(clientRef.current?.cache.extract() || {}));
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        return {};
      }
    };
  }

  // eslint-disable-next-line no-console
  if (reconnectTimestampValue) console.log('reconnected at:', new Date(reconnectTimestampValue));

  return clientRef.current;
}

interface AppSyncApolloProviderProps {
  children: React.ReactNode;
}

export function AppSyncApolloProvider({ children }: AppSyncApolloProviderProps) {
  const client = useAppSyncApolloClient();

  if (!client) return <>{children}</>; // eslint-disable-line react/jsx-no-useless-fragment

  return (
    <ApolloProvider client={client}>
      <CurrentUserContextProvider>
        <AgentsContextProvider>{children}</AgentsContextProvider>
      </CurrentUserContextProvider>
    </ApolloProvider>
  );
}
