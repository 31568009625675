import { useEffect, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { USER_TYPES } from '~/types/user';
import { Company } from '~/types/company';
import browserStorage from '~/utils/browserStorage';
import { CompanyListQueryData } from '~/services/api/operations/queries/QueryCompanyList';
import { currentCompanyIdentifierVar } from '~/services/api/reactiveVariables/currentCompanyIdentifierVar';
import useQueryCompanyList from '~/services/api/apis/useQueryCompanyList';
import useQueryCompanyDetails from '~/services/api/apis/useQueryCompanyDetails';
import useCurrentUserContext from '~/context/CurrentUserContext';

const COMPANY_STORAGE_KEY = 'cachedCompany';

interface UseCompanyResponseType {
  currentCompany?: Company;
  currentCompanyIdentifier: string;
  setCurrentCompanyIdentifier: (companyId: string) => void;
  companyList: CompanyListQueryData['getAllCompanies']['items'];
}

export default function useCompany(): UseCompanyResponseType {
  const currentCompanyIdentifier = useReactiveVar(currentCompanyIdentifierVar);
  const currentUser = useCurrentUserContext();
  const { companyDetails } = useQueryCompanyDetails({
    companyID: currentCompanyIdentifier,
    skip:
      !currentCompanyIdentifier || !currentUser || currentUser.type === USER_TYPES.SUBSIDIARY_ADMIN,
  });
  const { companyList } = useQueryCompanyList(
    !currentUser || currentUser.type !== USER_TYPES.SUPER_ADMIN,
  );

  const setCurrentCompanyIdentifier = useCallback(
    (companyId: string) => {
      if (!currentUser?.id) return;

      const cachedCompanyIds = browserStorage.local.get(COMPANY_STORAGE_KEY, true) || {};

      cachedCompanyIds[currentUser.id] = companyId;
      browserStorage.local.set(COMPANY_STORAGE_KEY, cachedCompanyIds, true);
      currentCompanyIdentifierVar(companyId);
    },
    [currentUser?.id],
  );

  const currentCompany = companyDetails || currentUser?.company;

  useEffect(() => {
    if (!currentUser?.id) return;

    const cachedCompanyIds = browserStorage.local.get(COMPANY_STORAGE_KEY, true);
    const cachedCompanyId = cachedCompanyIds?.[currentUser.id];
    const preselectedCompanyId =
      currentUser.company?.id || companyList.find(({ id }) => id === cachedCompanyId)?.id;

    if (preselectedCompanyId) setCurrentCompanyIdentifier(preselectedCompanyId);
  }, [setCurrentCompanyIdentifier, currentUser?.id, currentUser?.company?.id, companyList]);

  return {
    currentCompany,
    currentCompanyIdentifier,
    setCurrentCompanyIdentifier,
    companyList,
  };
}
