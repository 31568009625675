import styled from 'styled-components';
import { white } from '~/styles/colours';
import i18n from '~/locales/i18n';
import routes from '~/navigation/config/routes';
import Link from './Link';

export interface AlertsBottomPopupAgentsLinkProps {
  size?: 'sm' | 'lg';
}

const sizes = {
  sm: { fontSize: '14px' },
  lg: { fontSize: '20px' },
};

const StyledLink = styled(Link)<AlertsBottomPopupAgentsLinkProps>`
  color: ${white};
  font-size: ${({ size }) => (size && sizes[size]?.fontSize) || 'inherit'};

  &:hover {
    color: ${white};
    text-decoration: underline;
  }
`;

export default function AlertsBottomPopupAgentsLink(props: AlertsBottomPopupAgentsLinkProps) {
  return (
    <StyledLink to={routes.agents()} data-id="view-all-agents-link" {...props}>
      {i18n.t('alertsBottomPopup.viewAllAgents')}
    </StyledLink>
  );
}
