import styled from 'styled-components';
import type { ButtonProps } from 'antd';
import { black } from '~/styles/colours';
import Button from './Button';

function AuthButton(props: ButtonProps) {
  return <Button type="primary" htmlType="submit" {...props} />;
}

const StyledAuthButton = styled(AuthButton)`
  background: ${black};
  border-radius: 60px;
  height: 50px;
  width: 100%;
`;

export default StyledAuthButton;
