import { Tooltip } from 'antd';
import { computeDurationDetailed } from '~/utils/dateTime';
import useConnectionStatus from '~/hooks/useConnectionStatus';
import ConnectionIcon from '~/components/atoms/icon/ConnectionIcon';

export default function NavBarConnectionIndicator() {
  const { navigatorOnline, lastOnlineTimestamp } = useConnectionStatus();

  let tooltipMessage = 'Loading...';
  if (lastOnlineTimestamp) {
    const lastConnectionTimeAgo = lastOnlineTimestamp
      ? computeDurationDetailed(new Date(lastOnlineTimestamp).toISOString())
      : '';
    const lastConnection = lastOnlineTimestamp
      ? new Date(lastOnlineTimestamp).toLocaleString()
      : '';
    tooltipMessage = `Last update ${lastConnectionTimeAgo} ago. (${lastConnection})`;
  }

  return (
    <Tooltip title={tooltipMessage}>
      <ConnectionIcon className={navigatorOnline ? 'connected' : 'disconnected'} />
    </Tooltip>
  );
}
