import { GpsSensorType } from '~/types';
import { convertMpsToKmh } from '~/utils/parse';
import i18n from '~/locales/i18n';
import DashboardIcon from '~/components/atoms/icon/DashboardIcon';
import Space from '~/components/atoms/space/Space';
import CarrierDetailsPopupHeadSensorText from './CarrierDetailsPopupHeadSensorText';

interface CarrierDetailsPopupSpeedProps {
  gps: GpsSensorType | undefined;
}

function CarrierDetailsPopupSpeed({ gps }: CarrierDetailsPopupSpeedProps) {
  return typeof gps?.sp === 'number' ? (
    <Space>
      <DashboardIcon />
      <CarrierDetailsPopupHeadSensorText
        labelText={i18n.t('carrierDetailsPopup.speed')}
        valueText={`${convertMpsToKmh(gps?.sp ?? 0).toFixed(2)} km/h`}
      />
    </Space>
  ) : null;
}

export default CarrierDetailsPopupSpeed;
