import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import CheckCircleTwoTone from '@ant-design/icons/lib/icons/CheckCircleTwoTone';
import { brightGreen } from '~/styles/colours';

const style = { fontSize: '18px' };

function CheckCircleIcon(props: Partial<CustomIconComponentProps>) {
  return <CheckCircleTwoTone twoToneColor={brightGreen} style={style} {...props} />;
}

export default CheckCircleIcon;
