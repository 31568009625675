import { useMemo } from 'react';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import { DEFAULT_NO_DEVICE_ASSIGNED } from '~/config/defaults';

export default function CarrierDetailsPopupInfoTypeSOSAlertTitle() {
  const { agent } = useCarrierDetailsPopupContext();
  const sosAlertTitle = useMemo(
    () => agent?.name || agent?.deviceName || DEFAULT_NO_DEVICE_ASSIGNED,
    [agent?.deviceName, agent?.name],
  );

  return <div>{sosAlertTitle}</div>;
}
