import { gql } from '@apollo/client';

export interface CreateAgentMutationVariables {
  subsidiaryId: string;
  email: string;
  name: string;
  attributes: { name: string; value: string }[];
}

export interface CreateAgentMutationData {
  createCognitoCarrier: {
    __typename: 'CreateCognitoCarrierResult';
    userId: string;
    tempPassword: string;
  };
}

export default gql`
  mutation MutationCreateAgent(
    $subsidiaryId: ID!
    $email: AWSEmail!
    $name: String!
    $attributes: [AttributeInput!]
  ) {
    createCognitoCarrier(
      subsidiary_id: $subsidiaryId
      email: $email
      name: $name
      attributes: $attributes
    ) {
      userId
      tempPassword
    }
  }
`;
