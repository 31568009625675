import styled from 'styled-components';
import { Divider } from 'antd';
import { white } from '~/styles/colours';

const StyledDivider = styled(Divider)`
  height: 65%;
  border-color: ${white};
  margin: 0;
  opacity: 0.5;
`;

export default function NavBarDivider() {
  return <StyledDivider type="vertical" />;
}
