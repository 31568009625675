import { useMemo } from 'react';
import styled from 'styled-components';
import Select from '~/components/atoms/select/Select';
import SelectOption from '~/components/atoms/select/SelectOption';
import {
  currentLanguage,
  defaultLocale,
  getLocaleList,
  LOCALE_CODES,
  setLocale,
} from '~/locales/i18n';
import { LocaleType } from '~/types/locale';

const Container = styled.div`
  display: inline-grid;
`;

interface LanguageSelectProps {
  label?: string;
  className?: string;
}

function LanguageSelect({ label, className }: LanguageSelectProps) {
  const languageList = useMemo(
    () =>
      getLocaleList().map((localeItem: LocaleType) => (
        <SelectOption key={localeItem.code} value={localeItem.code}>
          {localeItem.label}
        </SelectOption>
      )) || [],
    [],
  );

  const handleChange = (value: LOCALE_CODES) => {
    setLocale(value);
    window.location.reload();
  };

  return languageList.length ? (
    <Container className={className}>
      {label && <h4>{label}</h4>}
      <Select
        style={{ width: 100 }}
        defaultValue={currentLanguage() ?? defaultLocale.code}
        onChange={handleChange}
      >
        {languageList}
      </Select>
    </Container>
  ) : null;
}

export default LanguageSelect;
