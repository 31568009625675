import { useState } from 'react';
import styled from 'styled-components';
import { black, lightGrey, white } from '~/styles/colours';
import i18n from '~/locales/i18n';
import { Z_INDEX } from '~/styles/layers';
import useAppContext from '~/context/AppContext';
import Button from '~/components/atoms/button/Button';
import CloseButton from '~/components/atoms/button/CloseButton';
import DashboardOverlayUsername from '~/components/atoms/grid/DashboardOverlayUsername';

const DashboardOverlayContainer = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: ${Z_INDEX.DASHBOARD_OVERLAY_LEVEL};
  position: fixed;
  align-items: center;
  justify-content: center;
`;

const DashboardOverlayBackground = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.1;
  position: absolute;
  background: ${black};
`;

const DashboardOverlayPopup = styled.div`
  width: 400px;
  height: 200px;
  border: 1px solid ${lightGrey};
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  background-color: ${white};
`;

const DashboardOverlayMessage = styled.p`
  padding: 20px;
`;

function DashboardOverlay() {
  const { userInteractedWithDocument } = useAppContext();
  const [visible, setVisible] = useState(!userInteractedWithDocument);

  const handleClose = () => setVisible(false);

  return visible ? (
    <DashboardOverlayContainer>
      <DashboardOverlayBackground onClick={handleClose} />
      <DashboardOverlayPopup>
        <CloseButton onClick={handleClose}> {i18n.t(`dashboardOverlay.close`)}</CloseButton>
        <DashboardOverlayMessage>
          {i18n.t(`dashboardOverlay.message`)}
          <DashboardOverlayUsername />
        </DashboardOverlayMessage>
        <Button key="submit" onClick={handleClose} data-id="welcome-dialog-ok-btn">
          {i18n.t('dashboardOverlay.okButtonLabel')}
        </Button>
      </DashboardOverlayPopup>
    </DashboardOverlayContainer>
  ) : null;
}

export default DashboardOverlay;
