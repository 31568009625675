import i18n, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE_TEXTS, LocaleType } from '~/types';
import { en, fr } from './texts';

export enum LOCALE_CODES {
  ENGLISH = 'en',
  FRENCH = 'fr',
}

const locale = {
  english: { code: LOCALE_CODES.ENGLISH, label: LANGUAGE_TEXTS.ENGLISH },
  french: { code: LOCALE_CODES.FRENCH, label: LANGUAGE_TEXTS.FRENCH },
};

export const defaultLocale: LocaleType = locale.english;

export const getLocale = (code: LOCALE_CODES): LocaleType | undefined =>
  Object.values(locale).find((element: LocaleType) => element.code === code);

export const setLocale = (language: LOCALE_CODES): Promise<TFunction> =>
  i18n.changeLanguage(language);

export const getCurrentLocale = (): LocaleType | undefined =>
  getLocale(i18n.language as LOCALE_CODES);

export const currentLanguage = (): LOCALE_CODES => i18n.language as LOCALE_CODES;

export const getLocaleList = (): LocaleType[] => Object.values(locale);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      [locale.english.code]: { translation: en },
      [locale.french.code]: { translation: fr },
    },
    detection: {
      order: ['navigator'],
    },
    lng: localStorage.getItem('i18nextLng') ?? defaultLocale.code,
    fallbackLng: defaultLocale.code,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      nsMode: 'default',
    },
  });

export default i18n;
