import { ReactNode } from 'react';
import { Layout } from 'antd';

interface Props {
  children: ReactNode;
}

export default function PublicLayout({ children }: Props) {
  return <Layout style={{ minHeight: '100vh' }}>{children}</Layout>;
}
