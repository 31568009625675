import styled from 'styled-components';
import { AGENT_STATUS } from '~/types/alarm';
import { DEFAULT_CARRIER_NAME } from '~/config/defaults';
import Title from '~/components/atoms/typography/Title';
import Space from '~/components/atoms/space/Space';
import SpaceBetweenSpace from '~/components/atoms/space/SpaceBetweenSpace';
import DashboardInitialsBullet from '~/components/atoms/DashboardInitialsBullet';
import MuteButton from '~/components/MuteButton';
import CarrierDetailsPopupCustomizedButton from './CarrierDetailsPopupCustomizedButton';

export interface DetailsPopupHeaderProps {
  bulletLabel: string;
  bulletColor: string;
  title?: string;
  subtitle?: string;
  alarmType?: AGENT_STATUS;
}

const ColumnFlexStartNoGapSpace = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0 !important;
`;

const LongGappedSpace = styled(Space)`
  gap: 20px !important;
`;

export default function DetailsPopupHeader({
  bulletLabel,
  bulletColor,
  title = DEFAULT_CARRIER_NAME,
  subtitle,
  alarmType = AGENT_STATUS.CONNECTION_LOST,
}: DetailsPopupHeaderProps) {
  return (
    <SpaceBetweenSpace>
      <LongGappedSpace>
        <DashboardInitialsBullet
          label={bulletLabel}
          color={bulletColor}
          data-id="details-popup-bullet-label"
        />
        <ColumnFlexStartNoGapSpace>
          <Title spaceless level={3} data-id="details-popup-title">
            {title}
          </Title>
          {subtitle && (
            <Title spaceless level={5}>
              {subtitle}
            </Title>
          )}
        </ColumnFlexStartNoGapSpace>
      </LongGappedSpace>
      <LongGappedSpace>
        {alarmType === AGENT_STATUS.ALERT && (
          <ColumnFlexStartNoGapSpace>
            <MuteButton />
          </ColumnFlexStartNoGapSpace>
        )}
        <CarrierDetailsPopupCustomizedButton
          alarmType={alarmType ?? AGENT_STATUS.CONNECTION_LOST}
          data-id="details-popup-alert-btn"
        />
      </LongGappedSpace>
    </SpaceBetweenSpace>
  );
}
