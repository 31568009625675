import { BrowserRouter, Route, Routes } from 'react-router-dom';
import config from '~/config/config';
import LoginPage from '~/pages/LoginPage';
import DashboardPage from '~/pages/DashboardPage';
import AgentsPage from '~/pages/AgentsPage';
import StatusPage from '~/pages/StatusPage';
import NewPasswordChallengePage from '~/pages/NewPasswordChallengePage';
import ResetPasswordPage from '~/pages/ResetPasswordPage';
import VerifyConfirmationCodePage from '~/pages/VerifyConfirmationCodePage';
import ForgotPasswordPage from '~/pages/ForgotPasswordPage';
import SettingsPage from '~/pages/SettingsPage';
import MFAPage from '~/pages/MFAPage';
import DeveloperToolsPage from '~/pages/DeveloperToolsPage';
import PageLayout from '~/templates/PageLayout';
import useAuthenticationContext from '~/context/AuthenticationContext';
import routes from './config/routes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export default function AppRouter() {
  const { isAuthenticated } = useAuthenticationContext();

  return (
    <BrowserRouter basename={config.basename}>
      <PageLayout>
        <Routes>
          {/* Public Routes */}
          <Route path={routes.login().pathname} element={<PublicRoute element={<LoginPage />} />} />
          <Route path={routes.mfa().pathname} element={<PublicRoute element={<MFAPage />} />} />
          <Route
            path={routes.newPasswordChallenge().pathname}
            element={<PublicRoute element={<NewPasswordChallengePage />} />}
          />
          <Route
            path={routes.resetPassword().pathname}
            element={<PublicRoute element={<ResetPasswordPage />} />}
          />
          <Route
            path={routes.verifyConfirmationCode().pathname}
            element={<PublicRoute element={<VerifyConfirmationCodePage />} />}
          />
          <Route
            path={routes.forgotPassword().pathname}
            element={<PublicRoute element={<ForgotPasswordPage />} />}
          />
          {/* Private Routes */}
          <Route
            path={routes.default().pathname}
            element={
              isAuthenticated ? (
                <PrivateRoute element={<DashboardPage />} />
              ) : (
                <PublicRoute element={<LoginPage />} />
              )
            }
          />
          <Route
            path={routes.agents().pathname}
            element={<PrivateRoute element={<AgentsPage />} />}
          />
          <Route
            path={routes.status().pathname}
            element={<PrivateRoute element={<StatusPage />} />}
          />
          <Route
            path={routes.settings().pathname}
            element={<PrivateRoute element={<SettingsPage />} />}
          />
          <Route
            path={routes.developerTools().pathname}
            element={<PrivateRoute element={<DeveloperToolsPage />} />}
          />
        </Routes>
      </PageLayout>
    </BrowserRouter>
  );
}
