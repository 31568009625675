import styled from 'styled-components';
import List from 'antd/lib/list';
import i18n from '~/locales/i18n';
import useCurrentUserContext from '~/context/CurrentUserContext';
import LanguageSelect from '~/components/LanguageSelect';

const StyledLanguageSelect = styled(LanguageSelect)`
  margin-top: 16px;
`;

function UserProfile() {
  const currentUser = useCurrentUserContext();

  if (!currentUser) return null;

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={[
          ...(currentUser?.company?.name
            ? [
                {
                  title: i18n.t('userProfile.company'),
                  description: currentUser.company.name,
                },
              ]
            : []),
          ...(currentUser?.subsidiary?.name
            ? [
                {
                  title: i18n.t('userProfile.subsidiary'),
                  description: currentUser.subsidiary.name,
                },
              ]
            : []),
          { title: i18n.t('userProfile.email'), description: currentUser.email },
        ]}
        renderItem={({ title, description }) => (
          <List.Item key={title}>
            <List.Item.Meta title={title} description={description} />
          </List.Item>
        )}
      />
      <StyledLanguageSelect label={i18n.t<string>('userProfile.languageSelectLabel')} />
    </>
  );
}

export default UserProfile;
