import { ApolloError, useMutation } from '@apollo/client';
import MUTATION_CREATE_AGENT, {
  CreateAgentMutationVariables,
  CreateAgentMutationData,
} from '~/services/api/operations/mutations/MutationCreateAgent';
import MUTATION_SET_AGENT_ATTRIBUTES, {
  SetAgentAttributesMutationVariables,
  SetAgentAttributesMutationData,
} from '~/services/api/operations/mutations/MutationSetAgentAttributes';
import MUTATION_DELETE_AGENT, {
  DeleteAgentMutationVariables,
  DeleteAgentMutationData,
} from '~/services/api/operations/mutations/MutationDeleteAgent';
import QUERY_SUBSIDIARY_CARRIER_LIST, {
  SubsidiaryCarrierListQueryVariables,
  SubsidiaryCarrierListQueryData,
} from '~/services/api/operations/queries/QuerySubsidiaryCarrierList';
import useSubsidiary from '~/hooks/useSubsidiary';

export function useMutationCreateAgent(): {
  createAgent: (params: { variables: CreateAgentMutationVariables }) => Promise<unknown>;
  loading: boolean;
  error?: ApolloError;
  createdAgent?: CreateAgentMutationData['createCognitoCarrier'];
} {
  const [createAgent, { loading, error, data }] = useMutation<
    CreateAgentMutationData,
    CreateAgentMutationVariables
  >(MUTATION_CREATE_AGENT);

  return { createAgent, loading, error, createdAgent: data?.createCognitoCarrier };
}

export function useMutationSetAgentAttributes(): {
  setAgentAttributes: (params: {
    variables: SetAgentAttributesMutationVariables;
  }) => Promise<unknown>;
  loading: boolean;
  error?: ApolloError;
  updatedAgent?: SetAgentAttributesMutationData['setCarrierAttributes'];
} {
  const [setAgentAttributes, { loading, error, data }] = useMutation<
    SetAgentAttributesMutationData,
    SetAgentAttributesMutationVariables
  >(MUTATION_SET_AGENT_ATTRIBUTES);

  return { setAgentAttributes, loading, error, updatedAgent: data?.setCarrierAttributes };
}

export function useMutationDeleteAgent(): {
  deleteAgent: (params: { variables: DeleteAgentMutationVariables }) => Promise<unknown>;
  loading: boolean;
  error?: ApolloError;
  deletedAgent?: DeleteAgentMutationData['deleteCarrier'];
} {
  const { currentSubsidiaryIdentifier } = useSubsidiary();

  const [deleteAgent, { loading, error, data }] = useMutation<
    DeleteAgentMutationData,
    DeleteAgentMutationVariables
  >(MUTATION_DELETE_AGENT, {
    update(cache, result) {
      const deletedAgentId = result?.data?.deleteCarrier;
      const options = {
        query: QUERY_SUBSIDIARY_CARRIER_LIST,
        variables: {
          subsidiaryID: currentSubsidiaryIdentifier,
        },
      };
      const cached = cache.readQuery<
        SubsidiaryCarrierListQueryData,
        SubsidiaryCarrierListQueryVariables
      >(options);
      if (deletedAgentId && cached) {
        cache.writeQuery<SubsidiaryCarrierListQueryData, SubsidiaryCarrierListQueryVariables>({
          ...options,
          data: {
            subsidiary: {
              ...cached.subsidiary,
              carriers: {
                ...cached.subsidiary.carriers,
                items: cached.subsidiary.carriers.items.filter(
                  (agent) => agent.id !== deletedAgentId,
                ),
              },
            },
          },
        });
      }
    },
  });

  return {
    deleteAgent,
    loading,
    error,
    deletedAgent: data?.deleteCarrier,
  };
}
