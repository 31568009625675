import { useCallback } from 'react';
import { useMutation, useReactiveVar, ApolloError } from '@apollo/client';
import { currentCompanyIdentifierVar } from '~/services/api/reactiveVariables/currentCompanyIdentifierVar';
import { currentSubsidiaryIdentifierVar } from '~/services/api/reactiveVariables/currentSubsidiaryIdentifierVar';
import QUERY_COMPANY_DETAILS from '~/services/api/operations/queries/QueryCompanyDetails';
import MUTATION_UPDATE_SUBSIDIARY, {
  UpdateSubsidiaryMutationData,
  UpdateSubsidiaryMutationVariables,
} from '~/services/api/operations/mutations/MutationUpdateSubsidiary';

type UpdateSubsidiaryParams = Omit<UpdateSubsidiaryMutationVariables, 'subsidiaryId'>;

export default function useMutationUpdateSubsidiary(): {
  updateSubsidiary: (params: UpdateSubsidiaryParams) => Promise<void>;
  updatedSubsidiary: UpdateSubsidiaryMutationData['updateSubsidiary'] | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
} {
  const companyId = useReactiveVar(currentCompanyIdentifierVar);
  const subsidiaryId = useReactiveVar(currentSubsidiaryIdentifierVar);

  const [updateSubsidiaryMutation, { data, loading, error }] = useMutation<
    UpdateSubsidiaryMutationData,
    UpdateSubsidiaryMutationVariables
  >(MUTATION_UPDATE_SUBSIDIARY, {
    refetchQueries: [
      { query: QUERY_COMPANY_DETAILS, variables: { companyID: companyId } },
      'QueryCompanyDetails',
    ],
  });

  const updateSubsidiary = useCallback(
    async ({ name, location }: UpdateSubsidiaryParams) => {
      await updateSubsidiaryMutation({
        variables: { subsidiaryId, name, location },
      });
    },
    [subsidiaryId, updateSubsidiaryMutation],
  );

  return {
    updateSubsidiary,
    updatedSubsidiary: data?.updateSubsidiary,
    isLoading: loading,
    error,
  };
}
