import { createContext, ReactNode, useContext, useMemo } from 'react';
import { AGENT_STATUS } from '~/types/alarm';
import { VehicleType } from '~/types/vehicle';
import { computeAlarmStartingTime, defaultAlarmStaringTime } from '~/utils/alarm';
import useAgentsContext from '~/context/AgentsContext';

interface VehicleDetailsPopupContextResponseType {
  vehicle: VehicleType | undefined;
  alertTime: string | false;
  relativeAlertTime: string | false;
}

const vehicleDetailsPopupContextDefaults: VehicleDetailsPopupContextResponseType = {
  vehicle: undefined,
  alertTime: false,
  relativeAlertTime: false,
};

const VehicleDetailsPopupContext = createContext(vehicleDetailsPopupContextDefaults);

const useVehicleDetailsPopupContext = () => useContext(VehicleDetailsPopupContext);

interface Props {
  children: ReactNode;
  plateNumber: string;
}

export function VehicleDetailsPopupContextProvider({ children, plateNumber }: Props) {
  const { getVehicle } = useAgentsContext();
  const vehicle = getVehicle(plateNumber);
  const vehicleAgentList = vehicle?.agents;
  const vehicleAgentListLength = vehicleAgentList?.length;
  const { alertTime, relativeAlertTime } = useMemo(() => {
    if (!vehicleAgentListLength || vehicle?.status !== AGENT_STATUS.ALERT) {
      return defaultAlarmStaringTime;
    }

    for (let i = 0; i < vehicleAgentListLength; i += 1) {
      if (vehicleAgentList[i]?.status === AGENT_STATUS.ALERT) {
        return computeAlarmStartingTime(vehicleAgentList[i]?.alarms);
      }
    }

    return defaultAlarmStaringTime;
  }, [vehicle?.status, vehicleAgentList, vehicleAgentListLength]);

  const returnValues = useMemo(
    () => ({ vehicle, alertTime, relativeAlertTime }),
    [alertTime, relativeAlertTime, vehicle],
  );

  if (!vehicle || !vehicleAgentListLength) return null;

  return (
    <VehicleDetailsPopupContext.Provider value={returnValues}>
      {children}
    </VehicleDetailsPopupContext.Provider>
  );
}

export default useVehicleDetailsPopupContext;
