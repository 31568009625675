import type { ImageProps } from 'antd';
import { Image as AntdImage } from 'antd';
import styled from 'styled-components';

function Image({ src, preview = false, ...props }: ImageProps) {
  return <AntdImage src={src} preview={preview} {...props} />;
}

export default styled(Image)`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
`;
