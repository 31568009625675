import { ImageProps } from 'antd';
import { computeURL } from '~/utils/assets';
import Logo from '~/components/atoms/logo/Logo';

const src = computeURL(`/icons/armor_vest.svg`);

function ArmorVestIcon(props: ImageProps) {
  return <Logo src={src} preview={false} {...props} />;
}

export default ArmorVestIcon;
