import { useMemo, useCallback, ReactNode } from 'react';
import { Select } from 'antd';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import { CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE } from '~/services/api/reactiveVariables/currentSubsidiaryIdentifierVar';
import NavBarSelect from './NavBarSelect';

export default function NavBarSelectCompany() {
  const { setCurrentCompanyIdentifier, currentCompanyIdentifier, companyList } = useCompany();
  const { setCurrentSubsidiaryIdentifier } = useSubsidiary();

  const options: ReactNode[] = useMemo(
    () =>
      companyList.map((company) => (
        <Select.Option
          key={company.id}
          value={company.id}
          data-id={`company-select-option-${company.id}`}
        >
          {company.name}
        </Select.Option>
      )),
    [companyList],
  );

  const value: string = useMemo(
    () =>
      companyList.find((company) => company.id === currentCompanyIdentifier)?.name ||
      i18n.t('footer.selectCompany'),
    [companyList, currentCompanyIdentifier],
  );

  const handleChange = useCallback(
    (companyId: string) => {
      setCurrentCompanyIdentifier(companyId);
      setCurrentSubsidiaryIdentifier(CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE);
    },
    [setCurrentCompanyIdentifier, setCurrentSubsidiaryIdentifier],
  );

  return (
    <NavBarSelect value={value} onChange={handleChange} data-id="company-select">
      {options}
    </NavBarSelect>
  );
}
