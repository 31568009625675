import { LinkProps } from 'react-router-dom';
import { white } from '~/styles/colours';
import BlackLink from './BlackLink';

export enum ALERTS_POPUP_LINK_SIZES {
  SMALL = '12px',
  LARGE = '18px',
}

export interface AlertsLinkProps extends LinkProps {
  linkSize?: ALERTS_POPUP_LINK_SIZES;
}

function AlertsLink({ linkSize = ALERTS_POPUP_LINK_SIZES.SMALL, ...props }: AlertsLinkProps) {
  return (
    <BlackLink
      style={{
        color: white,
        fontSize: linkSize,
        textDecoration: 'underline',
      }}
      {...props}
    />
  );
}

export default AlertsLink;
