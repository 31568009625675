import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import VideoCameraOutlined from '@ant-design/icons/lib/icons/VideoCameraOutlined';

const style = { fontSize: '24px' };

function VideoCameraIcon(props: Partial<CustomIconComponentProps>) {
  return <VideoCameraOutlined style={style} {...props} />;
}

export default VideoCameraIcon;
