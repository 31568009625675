import { useMemo } from 'react';
import { GpsCoordinatesType, DETAILS_POPUP_TYPE } from '~/types';
import {
  CARRIER_MAP_ZOOM_LEVEL,
  DEFAULT_GPS_POSITION,
  DEFAULT_MAP_ZOOM_LEVEL,
} from '~/config/defaults';
import useAgentsContext from '~/context/AgentsContext';
import useQueryParams from '~/hooks/useQueryParams';
import useSubsidiary from '~/hooks/useSubsidiary';
import Map from '~/components/Map';
import VehicleDetailsPopup from '~/components/DetailsPopup/vehicleDetailsPopup/VehicleDetailsPopup';
import CarrierDetailsPopup from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopup';

export default function StatusPage() {
  const { id, type } = useQueryParams();
  const { getAgent, getVehicle } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();
  const agent = getAgent(id ?? '');
  const vehicle = getVehicle(id ?? '');

  const centerPosition = useMemo(
    () =>
      agent?.sensors.gps ||
      vehicle?.location ||
      currentSubsidiary?.location ||
      DEFAULT_GPS_POSITION,
    [agent?.sensors.gps, vehicle?.location, currentSubsidiary?.location],
  );
  const computedRightSidePosition: GpsCoordinatesType = useMemo(
    () => ({ lat: centerPosition.lat, lng: centerPosition.lng - 0.03 }),
    [centerPosition],
  );

  if (!id) return null;

  return (
    <>
      <Map
        center={computedRightSidePosition}
        zoom={
          centerPosition !== DEFAULT_GPS_POSITION ? CARRIER_MAP_ZOOM_LEVEL : DEFAULT_MAP_ZOOM_LEVEL
        }
      />
      {type === DETAILS_POPUP_TYPE.VEHICLE ? (
        <VehicleDetailsPopup vehicleID={id} />
      ) : (
        <CarrierDetailsPopup carrierID={id} />
      )}
    </>
  );
}
