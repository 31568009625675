import { ReactNode } from 'react';
import Title from './Title';

interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
}

export default function SectionHeader({ title, subtitle }: Props) {
  return (
    <header style={{ margin: '16px' }}>
      <Title style={{ margin: 0 }} level={3}>
        {title}
      </Title>
      <Title
        style={{
          margin: 0,
          fontWeight: 'normal',
        }}
        level={5}
      >
        {subtitle}
      </Title>
    </header>
  );
}
