import { useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import QUERY_TOKENS, {
  CompanyM2MTokensQueryData,
  CompanyM2MTokensQueryVariables,
} from '~/services/api/operations/queries/QueryCompanyM2MTokens';
import MUTATION_CREATE_TOKEN, {
  CreateCompanyM2MTokenMutationData,
  CreateCompanyM2MTokenMutationVariables,
} from '~/services/api/operations/mutations/MutationCreateCompanyM2MToken';
import MUTATION_DELETE_TOKEN, {
  DeleteCompanyM2MTokenMutationData,
  DeleteCompanyM2MTokenMutationVariables,
} from '~/services/api/operations/mutations/MutationDeleteCompanyM2MToken';
import notification from '~/utils/notification';
import i18n from '~/locales/i18n';

export function useQueryCompanyM2MTokens(companyId?: string, skip?: boolean) {
  const { fetchMore, data, loading, error } = useQuery<
    CompanyM2MTokensQueryData,
    CompanyM2MTokensQueryVariables
  >(QUERY_TOKENS, {
    variables: { companyId: companyId || '' },
    skip: skip || !companyId,
  });

  useEffect(() => {
    if (skip || loading || !companyId || !data?.company?.m2m_tokens?.nextToken) return;

    fetchMore({
      variables: { nextToken: data.company.m2m_tokens.nextToken },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        console.log('fetchMore m2m tokens', companyId); // eslint-disable-line no-console
        return {
          ...previousResult,
          company: {
            ...previousResult.company,
            m2m_tokens: {
              ...previousResult.company?.m2m_tokens,
              items: [
                ...(previousResult.company?.m2m_tokens?.items || []),
                ...(fetchMoreResult.company?.m2m_tokens?.items || []),
              ],
              nextToken: fetchMoreResult.company?.m2m_tokens?.nextToken,
            },
          },
        };
      },
    }).catch(() => {
      notification.error({
        message: i18n.t('general.notifications.fetchDataErrorTitle'),
        description: i18n.t('general.notifications.fetchDataErrorDescription'),
      });
    });
  }, [fetchMore, skip, loading, companyId, data?.company?.m2m_tokens?.nextToken]);

  return useMemo(
    () => ({ tokens: data?.company?.m2m_tokens?.items || [], loading, error }),
    [data?.company?.m2m_tokens?.items, loading, error],
  );
}

export function useMutationCreateM2MToken() {
  const [createM2MToken, { data, loading, error }] = useMutation<
    CreateCompanyM2MTokenMutationData,
    CreateCompanyM2MTokenMutationVariables
  >(MUTATION_CREATE_TOKEN);

  return { createM2MToken, result: data?.generateM2MToken, loading, error };
}

export function useMutationDeleteM2MToken() {
  const [deleteM2MToken, { loading, error }] = useMutation<
    DeleteCompanyM2MTokenMutationData,
    DeleteCompanyM2MTokenMutationVariables
  >(MUTATION_DELETE_TOKEN);

  return { deleteM2MToken, loading, error };
}
