import { useCallback } from 'react';
import { useMutation, useReactiveVar, ApolloError } from '@apollo/client';
import { currentCompanyIdentifierVar } from '~/services/api/reactiveVariables/currentCompanyIdentifierVar';
import QUERY_COMPANY_DETAILS from '~/services/api/operations/queries/QueryCompanyDetails';
import MUTATION_SET_COMPANY_ATTRIBUTE, {
  SetCompanyAttributeMutationData,
  SetCompanyAttributeMutationVariables,
} from '~/services/api/operations/mutations/MutationSetCompanyAttribute';

type SetCompanyAttributeParams = Omit<SetCompanyAttributeMutationVariables, 'companyId'>;

export default function useMutationSetCompanyAttribute(): {
  setCompanyAttribute: (params: SetCompanyAttributeParams) => Promise<void>;
  updatedCompany: SetCompanyAttributeMutationData['setCompanyAttribute'] | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
} {
  const currentCompanyId = useReactiveVar(currentCompanyIdentifierVar);

  const [setCompanyAttributeMutation, { data, loading, error }] = useMutation<
    SetCompanyAttributeMutationData,
    SetCompanyAttributeMutationVariables
  >(MUTATION_SET_COMPANY_ATTRIBUTE, {
    refetchQueries: [
      { query: QUERY_COMPANY_DETAILS, variables: { companyID: currentCompanyId } },
      'QueryCompanyDetails',
    ],
  });

  const setCompanyAttribute = useCallback(
    async ({ name, value }: SetCompanyAttributeParams) => {
      await setCompanyAttributeMutation({
        variables: { companyId: currentCompanyId, name, value },
      });
    },
    [currentCompanyId, setCompanyAttributeMutation],
  );

  return {
    setCompanyAttribute,
    updatedCompany: data?.setCompanyAttribute,
    isLoading: loading,
    error,
  };
}
