import routes from '~/navigation/config/routes';
import i18n from '~/locales/i18n';
import { DETAILS_POPUP_TYPE } from '~/types/popup';
import SpaceBetweenSpace from '~/components/atoms/space/SpaceBetweenSpace';
import useVehicleDetailsPopupContext from '~/components/DetailsPopup/vehicleDetailsPopup/VehicleDetailsPopupContext';
import CarrierDetailsPopupFooterButton from '~/components/DetailsPopup/content/footer/CarrierDetailsPopupFooterButton';

export default function SosVehicleDetailsPopupFooter() {
  const { vehicle } = useVehicleDetailsPopupContext();
  const id = vehicle?.id;

  return (
    <SpaceBetweenSpace>
      <CarrierDetailsPopupFooterButton
        textLabel={i18n.t('carrierDetailsPopup.viewOnDashboard')}
        to={routes.default({ id, type: DETAILS_POPUP_TYPE.VEHICLE, zoom: '14' })}
        data-id="view-on-dashboard-btn"
      />
    </SpaceBetweenSpace>
  );
}
