import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Badge } from 'antd';
import i18n from '~/locales/i18n';
import { AGENT_STATUS } from '~/types';
import routes from '~/navigation/config/routes';
import useAgentsStatistics from '~/hooks/useAgentsStatistics';
import { white } from '~/styles/colours';
import Link from '~/components/atoms/link/Link';

const Ul = styled('ul')`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Span = styled('span')<{ $active?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  color: ${white};
  opacity: ${(props) => (props.$active ? 1 : 0.6)};

  &:hover {
    opacity: ${(props) => (props.$active ? 1 : 0.8)};
  }
`;

export default function NavBarMenu() {
  const { pathname } = useLocation();
  const { agentsStatusCounts } = useAgentsStatistics();

  return (
    <Ul>
      <li>
        <Link to={routes.default()} data-id="main-nav-dashboard-link">
          <Span $active={routes.default().pathname === pathname}>
            {i18n.t('header.menu.dashboard')}
          </Span>
        </Link>
      </li>
      <li>
        <Link to={routes.agents()} data-id="main-nav-agents-link">
          <Badge
            count={agentsStatusCounts[AGENT_STATUS.ALERT]}
            offset={[5, -5]}
            style={{ boxShadow: 'none' }}
          >
            <Span $active={routes.agents().pathname === pathname}>
              {i18n.t('header.menu.agents')}
            </Span>
          </Badge>
        </Link>
      </li>
    </Ul>
  );
}
