import { ApolloError, useMutation } from '@apollo/client';
import MUTATION_REQUEST_DISMISS_ALARM, {
  RequestDismissAlarmMutationData,
  RequestDismissAlarmMutationVariables,
} from '~/services/api/operations/mutations/MutationRequestDismissAlarm';

export default function useMutationRequestDismissAlarm(): {
  requestDismissAlarm: (params: {
    variables: RequestDismissAlarmMutationVariables;
  }) => Promise<unknown>;
  dismissedAlarm?: RequestDismissAlarmMutationData | null;
  isLoading: boolean;
  error?: ApolloError;
} {
  const [requestDismissAlarm, { data, loading, error }] = useMutation<
    RequestDismissAlarmMutationData,
    RequestDismissAlarmMutationVariables
  >(MUTATION_REQUEST_DISMISS_ALARM);

  return {
    requestDismissAlarm,
    dismissedAlarm: data,
    isLoading: loading,
    error,
  };
}
