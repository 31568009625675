import styled from 'styled-components';
import Link from '~/components/atoms/link/Link';
import { computeURL } from '~/utils/assets';
import routes from '~/navigation/config/routes';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const Img = styled('img')`
  width: 90px;
`;

export default function NavBarLogo() {
  return (
    <StyledLink to={routes.default()}>
      <Img src={computeURL('/images/logo-wearin-white.png')} />
    </StyledLink>
  );
}
