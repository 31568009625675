type Params = Record<string, string | undefined>;

function computeSearchParams(params: Params | undefined): string | undefined {
  if (!params) return undefined;
  const searchParamsQuery = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => value && searchParamsQuery.set(key, value));
  return `?${searchParamsQuery}`;
}

const routes = {
  // Private Routes
  default: (params?: Params) => ({
    pathname: '/',
    search: computeSearchParams(params),
  }),
  agents: (params?: Params) => ({
    pathname: '/agents',
    search: computeSearchParams(params),
  }),
  settings: (params?: Params) => ({
    pathname: '/settings',
    search: computeSearchParams(params),
  }),
  developerTools: (params?: Params) => ({
    pathname: '/developer-tools',
    search: computeSearchParams(params),
  }),
  status: (params?: Params) => ({
    pathname: '/status',
    search: computeSearchParams(params),
  }),
  // Public Routes
  login: (params?: Params) => ({
    pathname: '/login',
    search: computeSearchParams(params),
  }),
  newPasswordChallenge: (params?: Params) => ({
    pathname: '/new-password-challenge',
    search: computeSearchParams(params),
  }),
  resetPassword: (params?: Params) => ({
    pathname: '/reset-password',
    search: computeSearchParams(params),
  }),
  verifyConfirmationCode: (params?: Params) => ({
    pathname: '/verify-confirmation-code',
    search: computeSearchParams(params),
  }),
  forgotPassword: (params?: Params) => ({
    pathname: '/forgot-password',
    search: computeSearchParams(params),
  }),
  mfa: (params?: Params) => ({
    pathname: '/mfa',
    search: computeSearchParams(params),
  }),
} satisfies Record<
  string,
  (params?: Params) => {
    pathname: string;
    search: string | undefined;
  }
>;

export default routes;
