export enum BROWSER_STORAGE_KEY {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  LOGIN_TIME = 'LOGIN_TIME',
}

const generateBrowserStorage = (type: 'sessionStorage' | 'localStorage') => ({
  get(key: string, parsed?: true) {
    const value = window[type].getItem(key);

    if (!parsed || value === null) return value;

    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  },
  set(key: string, value: unknown, parsed?: true) {
    try {
      const parsedValue = parsed ? JSON.stringify(value) : `${value}`;

      window[type].setItem(key, parsedValue);
    } catch (e) {
      console.log('browserStorage.set error', e); // eslint-disable-line no-console
    }
  },
  remove(key: string) {
    window[type].removeItem(key);
  },
});

const browserStorage = {
  local: generateBrowserStorage('localStorage'),
  session: generateBrowserStorage('sessionStorage'),
};

export default browserStorage;
