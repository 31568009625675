export enum SENSOR_TYPES {
  CONNECTED = 'connected',
  CONNECTED_HISTORY = 'connected_history',
  BATTERY = 'battery',
  GPS = 'gps',
  TEMPERATURE = 'temperature',
  BODY_TEMPERATURE = 'bodyTemperature',
  HEART_RATE = 'heartRate',
  SYSTEM = 'system',
  GAS = 'gas',
  GAS_ALARM = 'gasAlarm',
  DRIVING_TYPE = 'drivingType',
  STRESS = 'stress',
  PROXIMITY_TAG = 'proximityTag',
  BRAIN_STOP = 'brainStop',
}

export enum SENSOR_TYPE_STATUSES {
  CONNECTED = 'connectedStatus',
  BATTERY = 'batteryStatus',
  GPS = 'gpsStatus',
  TEMPERATURE = 'temperatureStatus',
  BODY_TEMPERATURE = 'bodyTemperatureStatus',
  HEART_RATE = 'heartRateStatus',
  SYSTEM = 'systemStatus',
  GAS = 'gasStatus',
  DRIVING_TYPE = 'drivingTypeStatus',
  STRESS = 'stressStatus',
  PROXIMITY_TAG = 'proximityTagStatus',
  EMERGENCY = 'emergencyStatus',
  TRAAK_FRONT = 'traakFrontStatus',
  TRAAK_BACK = 'traakBackStatus',
}

export enum SENSOR_QUERY_TYPES {
  CONNECTED = 'connected',
  BATTERY = 'battery',
  GPS = 'gps',
  TEMPERATURE = 'temperature',
  BODY_TEMPERATURE = 'body_temperature',
  HEART_RATE = 'heart_rate',
  SYSTEM = 'system',
  GAS = 'gas',
  DRIVING_TYPE = 'driving_type',
  STRESS = 'stress',
  PROXIMITY_TAG = 'proximity_tag',
  BRAIN_STOP = 'brain_stop',
}

export enum SENSOR_STATUS_QUERY_TYPES {
  CONNECTED = 'connected#status',
  BATTERY = 'battery#status',
  GPS = 'gps#status',
  TEMPERATURE = 'temperature#status',
  BODY_TEMPERATURE = 'body_temperature#status',
  HEART_RATE = 'heart_rate#status',
  SYSTEM = 'system#status',
  GAS = 'gas#status',
  DRIVING_TYPE = 'driving_type#status',
  STRESS = 'stress#status',
  PROXIMITY_TAG = 'proximity_tag#status',
  EMERGENCY = 'emergency#status',
  TRAAK_FRONT = 'traak_front#status',
  TRAAK_BACK = 'traak_back#status',
}

export interface SensorType {
  QUERY_NAME: SENSOR_QUERY_TYPES;
  NAME: SENSOR_TYPES;
  QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES;
  STATUS_NAME: SENSOR_TYPE_STATUSES;
}

export const SENSOR_TYPE_KEYS = {
  SYSTEM: {
    QUERY_NAME: SENSOR_QUERY_TYPES.SYSTEM,
    NAME: SENSOR_TYPES.SYSTEM,
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.SYSTEM,
    STATUS_NAME: SENSOR_TYPE_STATUSES.SYSTEM,
  },
  BATTERY: {
    QUERY_NAME: SENSOR_QUERY_TYPES.BATTERY,
    NAME: SENSOR_TYPES.BATTERY,
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.BATTERY,
    STATUS_NAME: SENSOR_TYPE_STATUSES.BATTERY,
  },
  CONNECTED: {
    QUERY_NAME: SENSOR_QUERY_TYPES.CONNECTED,
    NAME: SENSOR_TYPES.CONNECTED,
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.CONNECTED,
    STATUS_NAME: SENSOR_TYPE_STATUSES.CONNECTED,
  },
  GPS: {
    QUERY_NAME: SENSOR_QUERY_TYPES.GPS,
    NAME: SENSOR_TYPES.GPS,
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.GPS,
    STATUS_NAME: SENSOR_TYPE_STATUSES.GPS,
  },
  TEMPERATURE: {
    QUERY_NAME: SENSOR_QUERY_TYPES.TEMPERATURE,
    NAME: SENSOR_TYPES.TEMPERATURE,
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.TEMPERATURE,
    STATUS_NAME: SENSOR_TYPE_STATUSES.TEMPERATURE,
  },
  BODY_TEMPERATURE: {
    QUERY_NAME: SENSOR_QUERY_TYPES.BODY_TEMPERATURE,
    NAME: SENSOR_TYPES.BODY_TEMPERATURE,
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.BODY_TEMPERATURE,
    STATUS_NAME: SENSOR_TYPE_STATUSES.BODY_TEMPERATURE,
  },
  HEART_RATE: {
    QUERY_NAME: SENSOR_QUERY_TYPES.HEART_RATE,
    NAME: SENSOR_TYPES.HEART_RATE,
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.HEART_RATE,
    STATUS_NAME: SENSOR_TYPE_STATUSES.HEART_RATE,
  },
  GAS: {
    QUERY_NAME: SENSOR_QUERY_TYPES.GAS,
    NAME: SENSOR_TYPES.GAS,
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.GAS,
    STATUS_NAME: SENSOR_TYPE_STATUSES.GAS,
  },
  EMERGENCY: {
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.EMERGENCY,
    STATUS_NAME: SENSOR_TYPE_STATUSES.EMERGENCY,
  },
  CONNECTION_HISTORY: {
    QUERY_NAME: SENSOR_QUERY_TYPES.CONNECTED,
    NAME: SENSOR_TYPES.CONNECTED_HISTORY,
  },
  BRAIN_STOP: {
    QUERY_NAME: SENSOR_QUERY_TYPES.BRAIN_STOP,
    NAME: SENSOR_TYPES.BRAIN_STOP,
  },
  TRAAK_FRONT: {
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.TRAAK_FRONT,
    STATUS_NAME: SENSOR_TYPE_STATUSES.TRAAK_FRONT,
  },
  TRAAK_BACK: {
    QUERY_STATUS_NAME: SENSOR_STATUS_QUERY_TYPES.TRAAK_BACK,
    STATUS_NAME: SENSOR_TYPE_STATUSES.TRAAK_BACK,
  },
};

export enum EQUIPMENT_STATUSES {
  NO_ERROR = 'no_error',
  SENSOR_UNPAIRED = 'sensor_unpaired',
  PHONE_BLE_DISABLED = 'phone_ble_disabled',
  BLE_DISABLED = 'ble_disabled',
  SENSOR_DISCONNECTED = 'sensor_disconnected',
  SENSOR_CONNECTING = 'sensor_connecting',
  NO_SENSOR = 'no_sensor',
  SENSOR_ERROR = 'sensor_error',
  SENSOR_INACTIVE = 'sensor_inactive',
}

export const SENSOR_ITEMS_QUERY_BODY = `{
  items {
    timestamp
    value
  }
}`;

export interface GpsCoordinatesType {
  lat: number;
  lng: number;
}

export interface GpsSensorType extends GpsCoordinatesType {
  sp?: number;
  br?: number;
  timestamp?: string;
}

export interface BrainStopSensorType {
  stop?: boolean;
  ta?: number;
}

export enum GAS_SENSOR_TYPES {
  CH4_HC = 'ch4/hc',
  CO = 'co',
  CO2 = 'co2',
  H2S = 'h2s',
  O2 = 'o2',
}

export enum GAS_SENSOR_ALARM_TYPES {
  CH4_HC = 'ch4/hc alarm',
  CO = 'co alarm',
  CO2 = 'co2 alarm',
  H2S = 'h2s alarm',
  O2 = 'o2 alarm',
}

export interface GasSensorType {
  [GAS_SENSOR_TYPES.CH4_HC]: number;
  [GAS_SENSOR_TYPES.CO]: number;
  [GAS_SENSOR_TYPES.CO2]: number;
  [GAS_SENSOR_TYPES.H2S]: number;
  [GAS_SENSOR_TYPES.O2]: number;
}
