import { AgentType } from '~/types/agent';
import { computeDuration } from '~/utils/dateTime';
import { computeMissionTime } from '~/utils/dateTime';
import i18n from '~/locales/i18n';
import ClockCircleIcon from '~/components/atoms/icon/ClockCircleIcon';
import Space from '~/components/atoms/space/Space';
import CarrierDetailsPopupHeadSensorText from './CarrierDetailsPopupHeadSensorText';

export interface CarrierDetailsPopupMissionStartedAndDurationProps {
  agent: AgentType | undefined;
}

function CarrierDetailsPopupMissionStartedAndDuration(
  props: CarrierDetailsPopupMissionStartedAndDurationProps,
) {
  const { agent } = props;

  if (agent && !agent.connectionLost && agent.missionStartTimeISO) {
    const timestamp = agent.missionStartTimeISO;
    const missionStarted = computeMissionTime(timestamp);
    const missionStartedDuration = computeDuration(timestamp);

    return (
      <Space>
        <ClockCircleIcon />
        <CarrierDetailsPopupHeadSensorText
          labelText={i18n.t('carrierDetailsPopup.missionStarted')}
          valueText={missionStarted}
        />
        <CarrierDetailsPopupHeadSensorText
          labelText={i18n.t('carrierDetailsPopup.duration')}
          valueText={missionStartedDuration}
        />
      </Space>
    );
  }

  return null;
}

export default CarrierDetailsPopupMissionStartedAndDuration;
