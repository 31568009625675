import { createContext, useContext, useEffect, useState } from 'react';

const AppContext = createContext({
  userInteractedWithDocument: false,
});

const useAppContext = () => useContext(AppContext);

interface AppContextProviderProps {
  children: React.ReactNode;
}

export function AppContextProvider({ children }: AppContextProviderProps) {
  const [state, setState] = useState({ userInteractedWithDocument: false });

  useEffect(() => {
    const handleUserInteraction = () =>
      setState((prevState) => ({ ...prevState, userInteractedWithDocument: true }));

    document.addEventListener('keydown', handleUserInteraction);
    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);
    document.addEventListener('scroll', handleUserInteraction);
  }, []);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
}

export default useAppContext;
