import { useState, MouseEvent } from 'react';
import styled from 'styled-components';
import { thinGrey, midGrey, white, darkGrey } from '~/styles/colours';
import Space from '~/components/atoms/space/Space';
import Text from '~/components/atoms/typography/Text';

export interface PopupWithTextProps {
  text: string;
  popUpText: string;
}

const PositionRelativeSpace = styled(Space)`
  position: relative;
  display: block;
`;

const VideoPopupSpace = styled(Space)`
  top: 16px;
  left: 16px;
  color: ${darkGrey};
  width: calc(100% - 32px);
  border: 2px solid ${thinGrey};
  padding: 8px;
  position: absolute;
  border-radius: 8px;
  pointer-events: none;
  background-color: ${white};
`;

const StyledText = styled(Text)`
  color: ${white};
  height: 140px;
  border: 3px solid ${white};
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  background-color: ${midGrey};
`;

export default function PopupWithText({ text, popUpText, ...props }: PopupWithTextProps) {
  const [visible, setVisible] = useState(false);

  const handleMouseOut = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setVisible(false);
  };

  const handleMouseOver = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setVisible(true);
  };

  return (
    <PositionRelativeSpace {...props}>
      {visible && <VideoPopupSpace>{popUpText}</VideoPopupSpace>}
      <StyledText onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {text}
      </StyledText>
    </PositionRelativeSpace>
  );
}
