import Popover from 'antd/lib/popover';
import i18n from '~/locales/i18n';
import { computeStatusLabel } from '~/utils/equipment';
import useCarrierDetailsPopupContext from '~/components/DetailsPopup/carrierDetailsPopup/CarrierDetailsPopupContext';
import CarrierDetailsPopupInfoTypeItemSpace from '~/components/atoms/space/CarrierDetailsPopupInfoTypeItemSpace';
import FullWidth from '~/components/atoms/FullWidth';
import Text from '~/components/atoms/typography/Text';
import CarrierDetailsPopupInfoTypeItemLabelSpace from '~/components/atoms/space/CarrierDetailsPopupInfoTypeItemLabelSpace';
import CheckCircleIcon from '~/components/atoms/icon/CheckCircleIcon';
import CloseCircleIcon from '~/components/atoms/icon/CloseCircleIcon';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';

export interface ListType {
  label: string;
  healthy: boolean;
  info?: string;
}

export default function CarrierDetailsPopupInfoTypeSosEquipmentItemList() {
  const companyFeatures = useCompanyFeatures();
  const { agent } = useCarrierDetailsPopupContext();

  if (!agent) return null;

  const {
    connectionLost,
    emergencyButton,
    heartRate,
    bodyTemperature,
    gas,
    traakFront,
    traakBack,
  } = agent.equipmentStatus;

  const list: ListType[] = [
    {
      label: i18n.t('carrierDetailsPopup.equipment.brain'),
      healthy: !connectionLost.healthy,
    },
    ...(companyFeatures.emergencyButton
      ? [
          {
            label: i18n.t('carrierDetailsPopup.equipment.sosButton'),
            healthy: emergencyButton.healthy,
            info: computeStatusLabel(emergencyButton),
          },
        ]
      : []),
    ...(companyFeatures.heartRateSensor
      ? [
          {
            label: i18n.t('carrierDetailsPopup.equipment.heartRate'),
            healthy: heartRate.healthy,
            info: computeStatusLabel(heartRate),
          },
        ]
      : []),
    ...(companyFeatures.bodyTemperatureSensor
      ? [
          {
            label: i18n.t('carrierDetailsPopup.equipment.bodyTemperature'),
            healthy: bodyTemperature.healthy,
            info: computeStatusLabel(bodyTemperature),
          },
        ]
      : []),
    ...(companyFeatures.gasSensor
      ? [
          {
            label: i18n.t('carrierDetailsPopup.equipment.gas'),
            healthy: gas.healthy,
            info: computeStatusLabel(gas),
          },
        ]
      : []),
    ...(companyFeatures.impactDetectionFront
      ? [
          {
            label: i18n.t('carrierDetailsPopup.equipment.traakFront'),
            healthy: traakFront.healthy,
            info: computeStatusLabel(traakFront),
          },
        ]
      : []),
    ...(companyFeatures.impactDetectionBack
      ? [
          {
            label: i18n.t('carrierDetailsPopup.equipment.traakBack'),
            healthy: traakBack.healthy,
            info: computeStatusLabel(traakBack),
          },
        ]
      : []),
  ];

  return (
    <CarrierDetailsPopupInfoTypeItemSpace data-id="sos-equipment-list">
      {list.map(({ label, healthy, info }) => {
        const icon = healthy ? <CheckCircleIcon /> : <CloseCircleIcon />;

        return (
          <FullWidth key={label}>
            <CarrierDetailsPopupInfoTypeItemLabelSpace>
              <Text weight="light">{label}</Text>
            </CarrierDetailsPopupInfoTypeItemLabelSpace>
            {info ? <Popover content={info}>{icon}</Popover> : icon}
          </FullWidth>
        );
      })}
    </CarrierDetailsPopupInfoTypeItemSpace>
  );
}
