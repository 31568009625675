import styled from 'styled-components';
import { black } from '~/styles/colours';
import Link from './Link';

const BlackLink = styled(Link)<{ $forced?: boolean }>`
  color: ${black};

  &:hover {
    color: ${props => (props.$forced ? black : undefined)};
  }
`;

export default BlackLink;
