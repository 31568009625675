import { gql } from '@apollo/client';
import type { Company, Location, USER_TYPES } from '~/types';

export interface CurrentUserQueryData {
  me: {
    __typename: USER_TYPES;
    id: string;
    email: string;
    company?: Company;
    subsidiary?: {
      __typename: 'Subsidiary';
      id: string;
      name: string;
      location: Location;
      company: Company;
    };
  };
}

export default gql`
  query QueryCurrentUser {
    me {
      __typename
      ... on SubsidiaryAdmin {
        id
        email
        subsidiary {
          id
          name
          location {
            lat
            lng
          }
          company {
            id
            name
            attributes {
              name
              value
            }
          }
        }
      }
      ... on CompanyAdmin {
        id
        email
        company {
          id
          name
          attributes {
            name
            value
          }
        }
      }
      ... on SuperAdmin {
        id
        email
      }
    }
  }
`;
