import { ReactNode } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { grey } from '~/styles/colours';
import { NAVBAR_HEIGHT } from '~/styles/dimensions';
import NavBar from '~/components/NavBar';
import RefreshTokenExpirationWarning from '~/components/RefreshTokenExpirationWarning';

const StyledLayout = styled(Layout)`
  background: ${grey};
  position: relative;
  width: 100%;
  height: 100%;
`;

interface Props {
  children: ReactNode;
}

export default function PrivateLayout({ children }: Props) {
  return (
    <>
      <StyledLayout>
        <NavBar />
        <Layout.Content style={{ minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)` }}>
          {children}
        </Layout.Content>
      </StyledLayout>
      <RefreshTokenExpirationWarning />
    </>
  );
}
