import { useRef, useEffect, EffectCallback } from 'react';
import { Renderer } from '@googlemaps/markerclusterer';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual } from 'fast-equals';
import { AGENT_STATUS, MARKER_TYPE } from '~/types';
import { computeURL } from '~/utils/assets';
import { getAgentStatusColor } from '~/utils/agent';

const markerZIndexMap = {
  [`${AGENT_STATUS.IN_SAFE_ZONE}_vehicle`]: 0,
  [`${AGENT_STATUS.IN_SAFE_ZONE}_agent`]: 1000,
  [`${AGENT_STATUS.IN_SAFE_ZONE}_cluster`]: 2000,
  [`${AGENT_STATUS.IN_MISSION}_vehicle`]: 2500,
  [`${AGENT_STATUS.IN_MISSION}_agent`]: 3500,
  [`${AGENT_STATUS.IN_MISSION}_cluster`]: 4500,
  [`${AGENT_STATUS.WARNING}_vehicle`]: 5000,
  [`${AGENT_STATUS.WARNING}_agent`]: 6000,
  [`${AGENT_STATUS.WARNING}_cluster`]: 7000,
  [`${AGENT_STATUS.CONNECTION_LOST}_vehicle`]: 7500,
  [`${AGENT_STATUS.CONNECTION_LOST}_agent`]: 8500,
  [`${AGENT_STATUS.CONNECTION_LOST}_cluster`]: 9500,
  [`${AGENT_STATUS.ALERT}_vehicle`]: 10000,
  [`${AGENT_STATUS.ALERT}_agent`]: 11000,
  [`${AGENT_STATUS.ALERT}_cluster`]: 12000,
};

export const getMarkerZIndex = (status: AGENT_STATUS, type: 'agent' | 'vehicle' | 'cluster') =>
  markerZIndexMap[`${status}_${type}`] || 0;

export const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: number | google.maps.LatLng, b: number | google.maps.LatLng) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  },
);

export function useDeepCompareMemoize(value: unknown) {
  const ref = useRef<unknown>();

  if (!deepCompareEqualsForMaps(value, ref.current)) ref.current = value;

  return ref.current;
}

export function useDeepCompareEffectForMaps(callback: EffectCallback, dependencies: unknown[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const colourMap = {
  [AGENT_STATUS.ALERT]: 'red',
  [AGENT_STATUS.WARNING]: 'yellow',
  [AGENT_STATUS.IN_MISSION]: 'blue',
  [AGENT_STATUS.IN_SAFE_ZONE]: 'green',
  [AGENT_STATUS.CONNECTION_LOST]: 'midGrey',
};

function computePath(markerType: string, status: AGENT_STATUS) {
  return `/icons/markers/${colourMap[status]}_ellipse${
    markerType === MARKER_TYPE.VEHICLE ? '_vehicle' : ''
  }_icon.svg`;
}

function computeScaledSize(isHighlighted?: boolean) {
  const size = isHighlighted ? 100 : 80;

  return new google.maps.Size(size, size);
}

function computeLabelOrigin(status: AGENT_STATUS, isHighlighted?: boolean) {
  const alert = status === AGENT_STATUS.ALERT;
  let labelOriginX;
  let labelOriginY;

  if (isHighlighted) {
    labelOriginX = 50;
    labelOriginY = alert ? 50 : 68;
  } else {
    labelOriginX = 40;
    labelOriginY = alert ? 40 : 52;
  }

  return new google.maps.Point(labelOriginX, labelOriginY);
}

export function computeIcon(markerType: string, status: AGENT_STATUS, isHighlighted?: boolean) {
  const path = computePath(markerType, status);
  const scaledSize = computeScaledSize(isHighlighted);
  const labelOrigin = computeLabelOrigin(status, isHighlighted);
  const url = computeURL(path);
  const icon: google.maps.Icon = { url, scaledSize, labelOrigin };

  return icon;
}

export function computeAnchorPoint(
  markerType: string,
  status: AGENT_STATUS,
  isHighlighted?: boolean,
) {
  const alert = status === AGENT_STATUS.ALERT;
  const vehicle = markerType === MARKER_TYPE.VEHICLE;
  let anchorPointX;
  let anchorPointY;

  if (isHighlighted) {
    anchorPointX = 0;

    if (vehicle) {
      anchorPointY = -98;
    } else if (alert) {
      anchorPointY = -102;
    } else {
      anchorPointY = -66;
    }
  } else {
    anchorPointX = 0;

    if (vehicle) {
      anchorPointY = -80;
    } else if (alert) {
      anchorPointY = -86;
    } else {
      anchorPointY = -56;
    }
  }

  return new google.maps.Point(anchorPointX, anchorPointY);
}

export const getMarkerClusterRender =
  (status: AGENT_STATUS) =>
  ({ count, position }: Renderer['render']['arguments']) => {
    const svg = window.btoa(`
      <svg fill="${getAgentStatusColor(
        status,
      )}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity="1" r="70" />
        <circle cx="120" cy="120" opacity=".5" r="90" />
        <circle cx="120" cy="120" opacity=".25" r="110" />
      </svg>
    `);

    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(64, 64),
      },
      label: { text: `${count}`, color: 'white', fontSize: '16px', fontWeight: 'bold' },
      zIndex: getMarkerZIndex(status, 'cluster') + count,
    });
  };
