import { useEffect, useCallback, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { USER_TYPES } from '~/types/user';
import { Subsidiary } from '~/types/subsidiary';
import browserStorage from '~/utils/browserStorage';
import { currentCompanyIdentifierVar } from '~/services/api/reactiveVariables/currentCompanyIdentifierVar';
import { currentSubsidiaryIdentifierVar } from '~/services/api/reactiveVariables/currentSubsidiaryIdentifierVar';
import useQueryCompanySubsidiaryList from '~/services/api/apis/useQueryCompanySubsidiaryList';
import { SELECTED_TEAMS_DEFAULT_VALUE } from '~/services/api/reactiveVariables/selectedTeamsVar';
import useCurrentUserContext from '~/context/CurrentUserContext';
import useTeams from '~/hooks/useTeams';

const SUBSIDIARY_STORAGE_KEY = 'cachedSubsidiary';

interface UseSubsidiaryResponseType {
  currentSubsidiary?: Subsidiary;
  currentSubsidiaryIdentifier: string;
  setCurrentSubsidiaryIdentifier: (subsidiaryId: string) => void;
  subsidiaryList: Subsidiary[];
}

export default function useSubsidiary(): UseSubsidiaryResponseType {
  const currentCompanyIdentifier = useReactiveVar(currentCompanyIdentifierVar);
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar);
  const { setSelectedTeams } = useTeams();
  const currentUser = useCurrentUserContext();
  const { subsidiaryList } = useQueryCompanySubsidiaryList(
    currentCompanyIdentifier,
    !currentCompanyIdentifier || !currentUser || currentUser.type === USER_TYPES.SUBSIDIARY_ADMIN,
  );

  const setCurrentSubsidiaryIdentifier = useCallback(
    (subsidiaryId: string) => {
      if (!currentUser?.id) return;

      const cachedSubsidiaryIds = browserStorage.local.get(SUBSIDIARY_STORAGE_KEY, true) || {};

      if (
        cachedSubsidiaryIds[currentUser.id] &&
        cachedSubsidiaryIds[currentUser.id] !== subsidiaryId
      ) {
        setSelectedTeams(SELECTED_TEAMS_DEFAULT_VALUE);
      }

      cachedSubsidiaryIds[currentUser.id] = subsidiaryId;

      browserStorage.local.set(SUBSIDIARY_STORAGE_KEY, cachedSubsidiaryIds, true);
      currentSubsidiaryIdentifierVar(subsidiaryId);
    },
    [currentUser?.id, setSelectedTeams],
  );

  const currentSubsidiary = useMemo(
    () => subsidiaryList.find(({ id }) => id === currentSubsidiaryIdentifier),
    [subsidiaryList, currentSubsidiaryIdentifier],
  );

  useEffect(() => {
    if (!currentUser?.id) return;

    const cachedSubsidiaryIds = browserStorage.local.get(SUBSIDIARY_STORAGE_KEY, true);
    const cachedSubsidiaryId = cachedSubsidiaryIds?.[currentUser.id];
    const preselectedSubsidiaryId =
      currentUser.subsidiary?.id || subsidiaryList?.find(({ id }) => id === cachedSubsidiaryId)?.id;

    if (preselectedSubsidiaryId || subsidiaryList?.length === 1) {
      setCurrentSubsidiaryIdentifier(preselectedSubsidiaryId || subsidiaryList[0].id);
    }
  }, [
    setCurrentSubsidiaryIdentifier,
    currentUser?.id,
    currentUser?.subsidiary?.id,
    subsidiaryList,
  ]);

  return {
    currentSubsidiary: currentSubsidiary || currentUser?.subsidiary,
    currentSubsidiaryIdentifier,
    setCurrentSubsidiaryIdentifier,
    subsidiaryList,
  };
}
