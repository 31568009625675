import { gql } from '@apollo/client';

export interface CompanyDetailsQueryVariables {
  companyID: string;
}

export interface CompanyDetailsQueryData {
  company: {
    __typename: 'Company';
    id: string;
    name: string;
    attributes: {
      __typename: 'Attribute';
      name: string;
      value: string;
    }[];
  };
}

export default gql`
  query QueryCompanyDetails($companyID: ID!) {
    company(id: $companyID) {
      id
      name
      attributes {
        name
        value
      }
    }
  }
`;
