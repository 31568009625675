export const DEFAULT_CONFIG = {
  /** Basics */
  APP_BASENAME: '/',
  // TODO - Eliminate the GOOGLE_MAPS_API_KEY after it it is provided by the server
  GOOGLE_MAPS_API_KEY: 'AIzaSyDB_0Xk0rbaTldJi3pAiuRI_NPSwnZo1Ac&libraries=&v=weekly',

  /** Sandbox environment (eu-west-1) */
  REGION: 'eu-west-1',
  API_URL: 'https://7zarskstknffpmwrzl3mp4juqe.appsync-api.eu-west-1.amazonaws.com/graphql',
  USER_POOL_ID: 'eu-west-1_guer5nJL7',
  APP_CLIENT_ID: '7j11srlq0fbnc5nlsnsciaui1k',
};

const config = {
  code_name: 'hypervest-dashboard',
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  version: process.env.REACT_APP_VERSION,
  commitHash: process.env.REACT_APP_COMMIT_HASH,
  commitDate: process.env.REACT_APP_COMMIT_DATE,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? DEFAULT_CONFIG.GOOGLE_MAPS_API_KEY,
  basename: process.env.REACT_APP_BASENAME ?? DEFAULT_CONFIG.APP_BASENAME,
  awsConfig: {
    aws_project_region: process.env.REACT_APP_AWS_REGION ?? DEFAULT_CONFIG.REGION,
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_API_URL ?? DEFAULT_CONFIG.API_URL,
    aws_appsync_region: process.env.REACT_APP_AWS_REGION ?? DEFAULT_CONFIG.REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'null',
    Auth: {
      region: process.env.REACT_APP_AWS_REGION ?? DEFAULT_CONFIG.REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID ?? DEFAULT_CONFIG.USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_CLIENT_ID ?? DEFAULT_CONFIG.APP_CLIENT_ID,
      mandatorySignIn: false,
    },
  },
} as const;

window.wearin_dashboard_version = {
  version: config.version,
  commitHash: config.commitHash,
  commitDate: config.commitDate,
};

export default config;
