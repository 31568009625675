export const black = '#000000';
export const darkGrey = '#404040';
export const midGrey = '#9c9c9c';
export const lightGrey = '#f6f6f6';
export const thinGrey = '#d9d9d9';
export const grey = '#ebedf0';
export const white = '#FFFFFF';
export const red = '#FF2F56';
export const green = '#52D176';
export const brightGreen = '#52c41a';
export const darkBlue = '#133273';
export const blue = '#096DD9';
export const purple = '#625FF4';
export const yellow = '#FFA843';
export const orange = '#FF8c00';
