import { useCallback, useEffect, useRef, useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import {
  AlarmType,
  ALARM_TYPES,
  SENSOR_TYPES,
  SENSOR_QUERY_TYPES,
  SENSOR_TYPE_STATUSES,
  SENSOR_STATUS_QUERY_TYPES,
  GAS_SENSOR_ALARM_TYPES,
  GAS_SENSOR_TYPES,
} from '~/types';
import SUBSCRIBE_TO_CARRIER, {
  CarrierSubscriptionData,
  CarrierSubscriptionVariables,
} from '~/services/api/operations/subscriptions/SubscribeToCarrier';
import SUBSCRIBE_TO_SUBSIDIARY_ALARM, {
  SubsidiaryAlarmSubscriptionData,
  SubsidiaryAlarmSubscriptionVariables,
} from '~/services/api/operations/subscriptions/SubscribeToSubsidiaryAlarm';
import SUBSCRIBE_TO_SUBSIDIARY_MEASUREMENT, {
  SubsidiaryMeasurementSubscriptionData,
  SubsidiaryMeasurementSubscriptionVariables,
} from '~/services/api/operations/subscriptions/SubscribeToSubsidiaryMeasurement';
import QUERY_SUBSIDIARY_CARRIER_LIST, {
  SubsidiaryCarrierListQueryData,
  SubsidiaryCarrierListQueryVariables,
  CarrierItem,
} from '~/services/api/operations/queries/QuerySubsidiaryCarrierList';
import { newAlert } from '~/services/api/reactiveVariables/newAlert';
import { parseJSON } from '~/utils/parse';
import notification from '~/utils/notification';
import useCompanyFeatures, { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';

export default function useQueryWithSubscriptionSubsidiaryCarrierList({
  subsidiaryID,
  skip = false,
}: {
  subsidiaryID: string;
  skip: boolean;
}): {
  subsidiaryCarrierList: CarrierItem[];
  isLoading: boolean;
  error?: ApolloError;
} {
  const features = useCompanyFeatures();
  const { subscribeToMore, fetchMore, client, loading, error, data } = useQuery<
    SubsidiaryCarrierListQueryData,
    SubsidiaryCarrierListQueryVariables
  >(QUERY_SUBSIDIARY_CARRIER_LIST, {
    variables: { subsidiaryID },
    fetchPolicy: 'network-only',
    skip,
  });
  const subscriptionsRef = useRef<(() => void)[]>([]);
  const subscribedFeaturesRef = useRef<Set<keyof CompanyFeatures>>(new Set());

  const subscribeToMoreForCarrier = useCallback(
    (subsidiaryId: string) =>
      subscribeToMore<CarrierSubscriptionData, CarrierSubscriptionVariables>({
        document: SUBSCRIBE_TO_CARRIER,
        variables: { subsidiaryID: subsidiaryId },
        updateQuery: (previousResult, { subscriptionData }) => {
          const updatedItem = subscriptionData?.data?.carrier?.carrier;

          if (!updatedItem) return previousResult;

          const { id, name, attributes, videoStream } = updatedItem;
          let itemList: CarrierItem[] = previousResult?.subsidiary?.carriers?.items || [];
          const isExistingItem = itemList?.some((item) => item.id === id);

          console.log('carrier update', id, subsidiaryId); // eslint-disable-line no-console

          if (isExistingItem) {
            itemList = itemList.map((item) =>
              item.id === id ? { ...item, name, attributes, videoStream } : item,
            );
          } else {
            itemList = [updatedItem, ...itemList];
          }

          return {
            ...previousResult,
            subsidiary: {
              ...previousResult.subsidiary,
              carriers: {
                ...previousResult.subsidiary?.carriers,
                items: itemList,
              },
            },
          };
        },
      }),
    [subscribeToMore],
  );

  const subscribeToMoreForSensorType = useCallback(
    (
      subsidiaryId: string,
      sensorQueryName: SENSOR_QUERY_TYPES | SENSOR_STATUS_QUERY_TYPES,
      sensorName: SENSOR_TYPES | SENSOR_TYPE_STATUSES,
    ) =>
      subscribeToMore<
        SubsidiaryMeasurementSubscriptionData,
        SubsidiaryMeasurementSubscriptionVariables
      >({
        document: SUBSCRIBE_TO_SUBSIDIARY_MEASUREMENT,
        variables: { subsidiaryID: subsidiaryId, type: sensorQueryName },
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) return previousResult;

          console.log('sensor update', sensorName, subsidiaryId); // eslint-disable-line no-console

          const carrierId = subscriptionData.data.measurements?.carrier_id;
          let measurements = orderBy(
            subscriptionData.data.measurements?.measurements || [],
            'timestamp',
            'desc',
          );
          let gasItem: (typeof measurements)[number] | undefined;
          let gasAlarmItem: (typeof measurements)[number] | undefined;

          if (sensorName === SENSOR_TYPES.GAS) {
            gasItem = measurements.find(
              (measurement) =>
                typeof parseJSON(measurement?.value)?.[GAS_SENSOR_TYPES.O2] === 'number',
            );
            gasAlarmItem = measurements.find(
              (measurement) =>
                typeof parseJSON(measurement?.value)?.[GAS_SENSOR_ALARM_TYPES.O2] === 'number',
            );

            const gasAlarmValue: Record<GAS_SENSOR_ALARM_TYPES, number> = parseJSON(
              gasAlarmItem?.value,
            );

            if (
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPES.CH4_HC] > 0 ||
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPES.CO] > 0 ||
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPES.CO2] > 0 ||
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPES.H2S] > 0 ||
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPES.O2] > 0
            ) {
              newAlert({ carrierId });
            }
          }

          const newItemList = previousResult?.subsidiary?.carriers?.items?.map((item) => {
            if (item.id === carrierId) {
              if (sensorName === SENSOR_TYPES.GAS) {
                const items = item.device?.[sensorName]?.items || [];
                const defaultGasItem = { __typename: 'Measurement', timestamp: '', value: '' };

                measurements = [
                  gasItem || items[0] || defaultGasItem,
                  gasAlarmItem || items[1] || defaultGasItem,
                ];
              }

              const newItem = {
                ...item,
                device: {
                  ...item.device,
                  [sensorName]: {
                    ...item.device[sensorName],
                    items: measurements,
                  },
                },
              };

              if (sensorName === SENSOR_TYPES.CONNECTED) {
                newItem.device[SENSOR_TYPES.CONNECTED_HISTORY] = {
                  items: [
                    ...(item.device[SENSOR_TYPES.CONNECTED_HISTORY]?.items || []),
                    ...measurements,
                  ],
                };
              }

              return newItem;
            }

            return item;
          });

          return {
            ...previousResult,
            subsidiary: {
              ...previousResult.subsidiary,
              carriers: {
                ...previousResult.subsidiary?.carriers,
                items: newItemList || [],
              },
            },
          };
        },
      }),
    [subscribeToMore],
  );

  const subscribeToMoreForAlarmType = useCallback(
    (subsidiaryId: string, alarmType: ALARM_TYPES) =>
      subscribeToMore<SubsidiaryAlarmSubscriptionData, SubsidiaryAlarmSubscriptionVariables>({
        document: SUBSCRIBE_TO_SUBSIDIARY_ALARM,
        variables: { subsidiaryID: subsidiaryId, type: alarmType },
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) return previousResult;

          const previousItemList: CarrierItem[] = previousResult?.subsidiary?.carriers?.items || [];

          console.log('alarm update', alarmType, subsidiaryId); // eslint-disable-line no-console

          if (!previousItemList.length) console.error('alarm update for empty store'); // eslint-disable-line no-console

          const alarm: AlarmType = subscriptionData.data.alarm?.alarm;
          const carrierId: string = subscriptionData.data.alarm?.carrier_id;
          const newItemList = previousItemList.map((item) => {
            if (item.id === carrierId) {
              console.log('updating carrier alarms'); // eslint-disable-line no-console

              return {
                ...item,
                device: {
                  ...item.device,
                  [alarmType]: {
                    items: [alarm],
                  },
                },
              };
            }

            return item;
          });

          if (!alarm.dismissed_at && !alarm.dismiss_requested_at) {
            newAlert({ carrierId });
          }

          return {
            ...previousResult,
            subsidiary: {
              ...previousResult.subsidiary,
              carriers: {
                ...previousResult.subsidiary?.carriers,
                items: newItemList,
              },
            },
          };
        },
      }),
    [subscribeToMore],
  );

  useEffect(() => {
    if (skip || loading || !subsidiaryID || !data?.subsidiary?.carriers?.nextToken) return;

    fetchMore({
      variables: { nextToken: data.subsidiary.carriers.nextToken },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        console.log('fetchMore subsidiary carriers', subsidiaryID); // eslint-disable-line no-console
        return {
          ...previousResult,
          subsidiary: {
            ...previousResult.subsidiary,
            carriers: {
              ...previousResult.subsidiary?.carriers,
              items: [
                ...(previousResult.subsidiary?.carriers?.items || []),
                ...(fetchMoreResult.subsidiary?.carriers?.items || []),
              ],
              nextToken: fetchMoreResult.subsidiary?.carriers?.nextToken,
            },
          },
        };
      },
    }).catch(() => {
      notification.error({
        message: i18n.t('general.notifications.fetchDataErrorTitle'),
        description: i18n.t('general.notifications.fetchDataErrorDescription'),
      });
    });
  }, [fetchMore, skip, loading, subsidiaryID, data?.subsidiary?.carriers?.nextToken]);

  useEffect(() => {
    if (subsidiaryID) {
      // eslint-disable-next-line no-console
      console.log(
        'subscribing',
        subsidiaryID,
        new Date().toISOString(),
        subscriptionsRef.current?.length,
      );

      subscriptionsRef.current = [
        subscribeToMoreForCarrier(subsidiaryID),
        subscribeToMoreForAlarmType(subsidiaryID, ALARM_TYPES.FALL),
        subscribeToMoreForAlarmType(subsidiaryID, ALARM_TYPES.ATTACK),
        subscribeToMoreForSensorType(subsidiaryID, SENSOR_QUERY_TYPES.GPS, SENSOR_TYPES.GPS),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_QUERY_TYPES.BRAIN_STOP,
          SENSOR_TYPES.BRAIN_STOP,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_QUERY_TYPES.CONNECTED,
          SENSOR_TYPES.CONNECTED,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_QUERY_TYPES.TEMPERATURE,
          SENSOR_TYPES.TEMPERATURE,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_STATUS_QUERY_TYPES.TEMPERATURE,
          SENSOR_TYPE_STATUSES.TEMPERATURE,
        ),
      ];
    }

    return () => {
      // eslint-disable-next-line no-console
      console.log(
        'unsubscribing',
        subsidiaryID,
        new Date().toISOString(),
        subscriptionsRef.current?.length,
      );

      subscriptionsRef.current.forEach((fn) => fn());
      subscriptionsRef.current = [];
      subscribedFeaturesRef.current = new Set();
    };
  }, [
    subsidiaryID,
    client,
    subscribeToMoreForCarrier,
    subscribeToMoreForAlarmType,
    subscribeToMoreForSensorType,
  ]);

  // needs to be separate from non-feature subscriptions in order to not cause
  // fast successive subscribing and unsubscribing (because features get loaded
  // at different time than other dependencies) which produces appsync error
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/509

  useEffect(() => {
    if (subsidiaryID) {
      // eslint-disable-next-line no-console
      console.log(
        'subscribing to features',
        subsidiaryID,
        new Date().toISOString(),
        subscriptionsRef.current?.length,
        subscribedFeaturesRef.current?.size,
      );

      if (features.emergencyButton && !subscribedFeaturesRef.current.has('emergencyButton')) {
        subscribedFeaturesRef.current.add('emergencyButton');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForAlarmType(subsidiaryID, ALARM_TYPES.EMERGENCY),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY_TYPES.EMERGENCY,
            SENSOR_TYPE_STATUSES.EMERGENCY,
          ),
        ];
      }
      if (features.heartRateSensor && !subscribedFeaturesRef.current.has('heartRateSensor')) {
        subscribedFeaturesRef.current.add('heartRateSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_QUERY_TYPES.HEART_RATE,
            SENSOR_TYPES.HEART_RATE,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY_TYPES.HEART_RATE,
            SENSOR_TYPE_STATUSES.HEART_RATE,
          ),
        ];
      }
      if (
        features.bodyTemperatureSensor &&
        !subscribedFeaturesRef.current.has('bodyTemperatureSensor')
      ) {
        subscribedFeaturesRef.current.add('bodyTemperatureSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_QUERY_TYPES.BODY_TEMPERATURE,
            SENSOR_TYPES.BODY_TEMPERATURE,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY_TYPES.BODY_TEMPERATURE,
            SENSOR_TYPE_STATUSES.BODY_TEMPERATURE,
          ),
        ];
      }
      if (features.gasSensor && !subscribedFeaturesRef.current.has('gasSensor')) {
        subscribedFeaturesRef.current.add('gasSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(subsidiaryID, SENSOR_QUERY_TYPES.GAS, SENSOR_TYPES.GAS),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY_TYPES.GAS,
            SENSOR_TYPE_STATUSES.GAS,
          ),
        ];
      }
      if (
        features.impactDetectionFront &&
        !subscribedFeaturesRef.current.has('impactDetectionFront')
      ) {
        subscribedFeaturesRef.current.add('impactDetectionFront');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForAlarmType(subsidiaryID, ALARM_TYPES.TRAAK_FRONT),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY_TYPES.TRAAK_FRONT,
            SENSOR_TYPE_STATUSES.TRAAK_FRONT,
          ),
        ];
      }
      if (
        features.impactDetectionBack &&
        !subscribedFeaturesRef.current.has('impactDetectionBack')
      ) {
        subscribedFeaturesRef.current.add('impactDetectionBack');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForAlarmType(subsidiaryID, ALARM_TYPES.TRAAK_BACK),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY_TYPES.TRAAK_BACK,
            SENSOR_TYPE_STATUSES.TRAAK_BACK,
          ),
        ];
      }
    }
  }, [
    subsidiaryID,
    features.emergencyButton,
    features.heartRateSensor,
    features.bodyTemperatureSensor,
    features.gasSensor,
    features.impactDetectionFront,
    features.impactDetectionBack,
    subscribeToMoreForAlarmType,
    subscribeToMoreForSensorType,
  ]);

  return useMemo(
    () => ({
      subsidiaryCarrierList: data?.subsidiary?.carriers?.items || [],
      isLoading: loading,
      error,
    }),
    [data?.subsidiary?.carriers?.items, loading, error],
  );
}
